import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../../../components/ThinkingSection/ThinkingSection.component';

@Component({
  selector: 'app-video-annotation',
  standalone: true,
  templateUrl: './video-annotation.component.html',
  styleUrl: './video-annotation.component.css',
  imports: [
    FaqComponent,
    KnowBestComponent,
    CdnUrlDirective,
    ourAssetsComponent,
    ThinkingSection,
  ],
})
export class VideoAnnotation implements OnInit {
  constructor(
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Video Annotation Services for AI & Object Tracking | FBSPL',
      description:
        'Get frame-by-frame video annotation for activity recognition, object tracking, and scene analysis. FBSPL ensures accurate, AI-ready data for dynamic insights.',
      keywords: 'video annotation outsourcing',
      ogImage: '/Sub-service-Pages/policy 1 1.png',
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": " Do you offer customized video annotation services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absolutely! We offer tailor-made video annotation services that match your specific needs and requirements. Be it annotating a complex dataset or a niche application, we can do it all. Share your project requirements with us and let our data annotation experts handle the rest!"
      }
    },
    {
      "@type": "Question",
      "name": "Can you annotate video objects with irregular shapes and movements?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we can offer all types of video annotation services. Our solutions include semantic segmentation and polygon annotation that helps accurately label objects with irregular shapes and complex movements."
      }
    },
    {
      "@type": "Question",
      "name": "How do you ensure the accuracy of video annotations?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our experts follow a rigorous quality assurance process to accurately annotate every element of the video. We combine expert manual reviews with AI-assisted checks to deliver video annotation solutions with over 99% accuracy."
      }
    },
    {
      "@type": "Question",
      "name": "What are the different types of video annotation services you offer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our services aren’t specific to a particular type of video annotation. From 2D/3D bounding boxes annotation, semantic segmentation, object detection, pose estimation, and polygon mapping, get access to different video annotation solutions with FBSPL. Drop us your project requirements or book a free consultation now."
      }
    },
    {
      "@type": "Question",
      "name": "What choose FBSPL as your data annotation partner?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": " At FBSPL, we have expertise in handling complex datasets, adhering to the latest industry standards, and offering customized annotation services best-suited for all-scale businesses. With us, you don’t have to worry about data security and can remain assured of quality video annotation and other annotation services. "
      }
    }
  ]
}`;
        this.renderer.appendChild(this.document.head, script);
      }
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.fbspl.com/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Services",
      "item": "https://www.fbspl.com/bpm-and-bpo-services"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Data Annotation",
      "item": "https://www.fbspl.com/services/data-annotation-services"
    },
    {
      "@type": "ListItem",
      "position": 4,
      "name": "Video Annotation",
      "item": "https://www.fbspl.com/services/video-annotation-outsourcing-services"
    }
  ]
}`;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }
}
