import { Component, OnInit, Inject, Renderer2, PLATFORM_ID } from '@angular/core';

import { RouterLink, RouterOutlet } from '@angular/router';

import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-ourleader',
  standalone: true,
  imports: [RouterLink, RouterOutlet, CdnUrlDirective],
  templateUrl: './our-leader.component.html',
  styleUrl: './our-leader.component.css',
})
export class OurLeaderComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object,) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'FBSPL Leadership | Meet Our Leaders',
      description:
        'Meet the visionary leaders at FBSPL. Our team is committed to innovation, excellence, and guiding our company towards success in business process outsourcing and management.',
      keywords: 'our team, meet our leaders',
      ogImage: '/leaders/Banner/LeadershipBanner.png'
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    {  

  

  "@context": "https://schema.org",  

  

  "@type": "BreadcrumbList",  

  

  "itemListElement": [  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 1,  

  

      "name": "Home",  

  

      "item": "https://www.fbspl.com/"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 2,  

  

      "name": "About FBSPL",  

  

      "item": "https://www.fbspl.com/about-who-we-are"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 3,  

  

      "name": "Our Leadership",  

  

      "item": "https://www.fbspl.com/our-leadership"  

  

    }  

  

  ]  

  

}`;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  }
}
}
