<section class="bg-secondary-20">
  <div class="container py-6">
    <h4 class="text-primary font-48-30">Thought leadership</h4>
    <p class="text-black font-20-16">
      Explore our insights to know what sets us apart
    </p>
    <div class="mx-auto" style="max-width: 1050px"></div>
    @if (!loading && ApiData) {
    <div class="row">
      <!-- case-studies -->
      <div class="col-lg-4 my-4 my-lg-0">
        <a [routerLink]="['/case-studies', ApiData?.casestudy[0]?.slug]">
          <div class="card blog-cards d-flex h-100">
            <img
              [src]="ApiData.casestudy[0].feature_image"
              class="blog-cards-image flex-fill"
            />
            <div class="description">
              <h4
                class="font-34-19 text-primary"
                [title]="ApiData.casestudy[0].title"
              >
                Case studies
              </h4>
              <p class="fw-bold fw-500 font-20-16">
                {{ ApiData.casestudy[0].title }}
              </p>
              <span class="mt-auto anchorCTA text-end">
                <h6
                  class="font-20 text-primary text-md-end mt-3 clickable-heading"
                >
                  Read more
                  <div class="headingCTA">
                    <button class="blueCTA podcast-card-cta"></button>
                  </div>
                </h6>
              </span>
            </div>
          </div>
        </a>
      </div>
      <!-- Blogs -->
      <div class="col-lg-4 my-4 my-lg-0">
        <a [routerLink]="['/blogs', ApiData?.blogs[0]?.slug]">
          <div class="card blog-cards d-flex h-100">
            <img
              [src]="ApiData.blogs[0].feature_image"
              class="blog-cards-image flex-fill"
            />
            <div class="description">
              <h4
                class="font-34-19 text-primary"
                [title]="ApiData.blogs[0].title"
              >
                Blog
              </h4>
              <p class="fw-bold fw-500 font-20-16">
                {{ ApiData.blogs[0].title }}
              </p>
              <span class="mt-auto anchorCTA text-end">
                <h6
                  class="font-20 text-primary text-md-end mt-3 clickable-heading"
                >
                  Read more
                  <div class="headingCTA">
                    <button class="blueCTA podcast-card-cta"></button>
                  </div>
                </h6>
              </span>
            </div>
          </div>
        </a>
      </div>
      <!-- podcast -->

      <div class="col-lg-4 my-4 my-lg-0">
        <a [routerLink]="['/podcast', ApiData?.podcasts[0]?.slug]">
          <div class="card blog-cards d-flex h-100">
            <img
              [src]="ApiData.podcasts[0].megamenu_image"
              class="blog-cards-image flex-fill"
            />
            <div class="description">
              <h4
                class="font-34-19 text-primary"
                [title]="ApiData.podcasts[0].title"
              >
                Podcast
              </h4>
              <p class="fw-bold fw-500 font-20-16">
                {{ ApiData.podcasts[0].title }}
              </p>
              <span class="mt-auto anchorCTA text-end">
                <h6
                  class="font-20 text-primary text-md-end mt-3 clickable-heading"
                >
                  Read more
                  <div class="headingCTA">
                    <button class="blueCTA podcast-card-cta"></button>
                  </div>
                </h6>
              </span>
            </div>
          </div>
        </a>
      </div>
    </div>
    }
  </div>
</section>
