import { Component, Input, OnInit } from '@angular/core';
import { RouterLink, Router } from '@angular/router';

import { NoNumbersDirective } from '../../directives/noNumbers.directive';

import { HomeApiService } from '../../services/homeApi/homeApi.service';

@Component({
  selector: 'ThinkingSection',
  standalone: true,
  templateUrl: './ThinkingSection.component.html',
  imports: [RouterLink, NoNumbersDirective],
})
export class ThinkingSection implements OnInit {
  @Input() category: string = '';
  constructor(private router: Router, private HomeApiService: HomeApiService) {}

  ApiData: any = {};
  loading: boolean = true;

  ngOnInit(): void {
    this.HomeApiService.getOurThinkingData(this.category).subscribe(
      (data) => {
        this.ApiData = data;
        this.loading = false;
      },
      (error) => {
        console.error('Error fetching blog data', error);
      }
    );
  }
}
