<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Sub-service-Pages/policy 1 1.png"
      class="card-img-desktop desktop-banner"
      alt="FBSPL Claim Management Services"
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/data-annotation-services">Data Annotation</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Video Annotation
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Video Annotation Outsourcing Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Detailed frame-by-frame video annotation services for comprehensive
            activity recognition, object tracking, and scene analysis, ensuring
            reliable data for visual AI applications through advanced tagging
            and classification. Now accurately interpret motion and objects in
            dynamic visual data.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Annotate Video Now
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      alt="FBSPL Claim Management Services"
      appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
      class="card-img-mob"
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/data-annotation-services">Data Annotation</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Video Annotation
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Video Annotation Outsourcing Services
        </h1>
        <p class="card-text font-20-16">
          Detailed frame-by-frame video annotation services for comprehensive
          activity recognition, object tracking, and scene analysis, ensuring
          reliable data for visual AI applications through advanced tagging and
          classification. Now accurately interpret motion and objects in dynamic
          visual data.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Annotate Video Now
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid pt-5">
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      High-quality video annotation services
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#TextCategorization">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  2D/3D Bounding Boxes Annotation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                To create high-performing AI models, accurate object labeling in
                motion is critical. Hence, through 2D/3D bounding box
                annotation...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#FullPartialAccountReconciliation"
          >
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Polygon Annotation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Precision is important when dealing with irregularly shaped
                objects. With our comprehensive polygon annotation services, we
                deliver detailed boundary....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#VendorInvoiceReconciliation"
          >
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Semantic Segmentation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Pixel-level precision is crucial for AI systems to comprehend
                every aspect of a video scene. By categorizing each pixel in a
                video, our semantic segmentation services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#BankStatementReconciliation"
          >
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Object Detection
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurate object detection is the foundation of AI’s capability
                to analyze video content with precision. With our object
                detection services, we carefully identify...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PettyCashReconciliation">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Event Tagging & Classification
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurately capture every moment in your video with timely event
                tagging and classification services. We carefully label and
                identify crucial actions, events, and sequences...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <div
              class="card bordered serviceCards Policy_Endorsements"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Pose Estimation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Human motion is complex but pose estimation services simplify
                the process by mapping key points like joints and angles. This
                enables AI systems....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#TextCategorization"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              2D/3D Bounding Boxes Annotation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#FullPartialAccountReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Polygon Annotation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#VendorInvoiceReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Semantic Segmentation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#BankStatementReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Object Detection</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PettyCashReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Event Tagging & Classification
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <h3 class="CTAs text-black fs-24 me-3">Pose Estimation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="TextCategorization"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="TextCategorizationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">
              2D/3D Bounding Boxes Annotation
            </h3>
            <p class="text-dark font-20-16">
              To create high-performing AI models, accurate object labeling in
              motion is critical. Hence, through 2D/3D bounding box annotation,
              we offer precise object tracking and detection across video
              frames. This makes it possible to perform in-depth spatial and
              depth analysis, which is ideal for applications like robotics,
              surveillance, and driverless cars. You can rely on our bounding
              box annotation solutions to provide accurate and useful data.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Frame-by-frame object tracking
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Accurate object classification
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Depth and spatial relationship analysis
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Applications for robotics and AI vision
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 198.webp"
              alt="2D/3D Bounding Boxes Annotation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="FullPartialAccountReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="FullPartialAccountReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Polygon Annotation</h3>
            <p class="text-dark font-20-16">
              Precision is important when dealing with irregularly shaped
              objects. With our comprehensive polygon annotation services, we
              deliver detailed boundary mapping, ensuring your AI models
              identify intricate objects with previously unprecedented
              precision. Our video annotation experts offer pixel-perfect
              annotations for environmental analysis and satellite pictures so
              that AI systems recognize and distinguish things in even the most
              difficult situations.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Annotation of irregular object shapes
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Accurate boundary mapping for detailed recognition
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Applications in satellite imagery and environmental studies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                High precision for AI training datasets
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 199.webp"
              alt="Budgeting & Forecasting Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="VendorInvoiceReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="VendorInvoiceReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Semantic Segmentation</h3>
            <p class="text-dark font-20-16">
              Pixel-level precision is crucial for AI systems to comprehend
              every aspect of a video scene. By categorizing each pixel in a
              video, our semantic segmentation services make it easy to
              distinguish between backgrounds and objects. Our data annotation
              experts assist your models in developing a comprehensive
              contextual understanding of their surroundings. This results in
              improved decision-making, making them perfect for applications
              such as medical imaging, AR/VR, and robotics.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Pixel-level annotation for scene understanding
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Classification of objects and backgrounds
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Enhanced training for robotics and AR/VR applications
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Multi-class labeling for complex environments
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 200.webp"
              alt="Semantic Segmentation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="BankStatementReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="BankStatementReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Object Detection</h3>
            <p class="text-dark font-20-16">
              Accurate object detection is the foundation of AI’s capability to
              analyze video content with precision. With our object detection
              services, we carefully identify and annotate objects in motion,
              allowing AI models to easily recognize patterns, behaviors, and
              actions efficiently. Our object detection annotation solutions
              produce dependable results, whether tracking cars in autonomous
              driving applications, identifying client movements in retail
              analytics, or improving security through surveillance film.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Real-time object identification
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Action and behavior recognition
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Applications in security, sports, and autonomous vehicles
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Accurate data for AI training
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 201.webp"
              alt="Object Detection Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="PettyCashReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PettyCashReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Event Tagging & Classification</h3>
            <p class="text-dark font-20-16">
              Accurately capture every moment in your video with timely event
              tagging and classification services. We carefully label and
              identify crucial actions, events, and sequences, helping you
              extract important data from dynamic events. Our video annotation
              services providers follow a step-by-step process that ensures
              accuracy and easy data retrieval. With our expert-driven data
              annotation services, your AI models can better understand and
              respond to real-world situations well.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Tagging dynamic actions and events
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Sequence classification for activity recognition
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Video indexing for search and analysis
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Enhanced video AI understanding
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 202.webp"
              alt="Event Tagging & Classification"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="AccountingSystemIntegration"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="AccountingSystemIntegrationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Pose Estimation</h3>
            <p class="text-dark font-20-16">
              Human motion is complex but pose estimation services simplify the
              process by mapping key points like joints and angles. This enables
              AI systems to analyze gestures, postures, and activities with
              precision. Well-suited for multiple applications including
              healthcare, sports analytics, fitness tracking, and more, our
              video annotation solutions ensure your AI models gain actionable
              insights into human behavior.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Body landmarks and joints annotation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Applications in fitness, healthcare, and gaming
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Precision for human activity analysis
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Tracking movements for gesture recognition
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 203.webp"
              alt="Pose Estimation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<section class="container py-5">
  <h3 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven text annotation solutions
  </h3>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="SuperAnnotate "
          appCdnUrl="assets/Tools/superAnnotate.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Adobe Acrobate  "
          appCdnUrl="assets/Tools/adobeacrobat.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="nitro" appCdnUrl="assets/Tools/Nitro.svg" />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="labelme"
          appCdnUrl="assets/Tools/Labelme.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="SuperAnnotate  "
            appCdnUrl="assets/Tools/superAnnotate.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt="Adobe Acrobate   "
            appCdnUrl="assets/Tools/adobeacrobat.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt="nitro"
            appCdnUrl="assets/Tools/Nitro.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="labelme"
            appCdnUrl="assets/Tools/Labelme.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="SuperAnnotate  "
            appCdnUrl="assets/Tools/superAnnotate.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt="Adobe Acrobate   "
            appCdnUrl="assets/Tools/adobeacrobat.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt="nitro"
            appCdnUrl="assets/Tools/Nitro.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="labelme"
            appCdnUrl="assets/Tools/Labelme.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<ThinkingSection category="dataannotation"></ThinkingSection>

<section class="py-6">
  <div class="container">
    <h4 class="text-primary font-48-30">Frequently asked questions</h4>

    <div class="mt-3 mt-md-0">
      <div data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                Do you offer customized video annotation services?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Absolutely! We offer tailor-made video annotation services that
                match your specific needs and requirements. Be it annotating a
                complex dataset or a niche application, we can do it all. Share
                your project requirements with us and let our data annotation
                experts handle the rest!
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                Can you annotate video objects with irregular shapes and
                movements?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Yes, we can offer all types of video annotation services. Our
                solutions include semantic segmentation and polygon annotation
                that helps accurately label objects with irregular shapes and
                complex movements.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                How do you ensure the accuracy of video annotations?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our experts follow a rigorous quality assurance process to
                accurately annotate every element of the video. We combine
                expert manual reviews with AI-assisted checks to deliver video
                annotation solutions with over 99% accuracy.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                What are the different types of video annotation services you
                offer?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our services aren’t specific to a particular type of video
                annotation. From 2D/3D bounding boxes annotation, semantic
                segmentation, object detection, pose estimation, and polygon
                mapping, get access to different video annotation solutions with
                FBSPL. Drop us your project requirements or book a free
                consultation now.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingfive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Process"
                aria-expanded="false"
                aria-controls="Process"
              >
                What choose FBSPL as your data annotation partner?
              </button>
            </h2>
            <div
              id="Process"
              class="accordion-collapse collapse"
              aria-labelledby="headingfive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                At FBSPL, we have expertise in handling complex datasets,
                adhering to the latest industry standards, and offering
                customized annotation services best-suited for all-scale
                businesses. With us, you don’t have to worry about data security
                and can remain assured of quality video annotation and other
                annotation services.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
