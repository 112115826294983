<div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Privacy-Policy/Privacy Banner.jpg"
      class="desktop-banner"
      alt="Privacy Policy at FBSPL "
    />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Privacy Policy
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Privacy Policy</h1>
    </div>
  </div>
</div>
<div class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img
      appCdnUrl="assets/Privacy-Policy/Privacy Mobile Banner.jpg"
      class="card-img-mob"
      alt="Privacy Policy at FBSPL "
    />
    <div class="bannerTextMob" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Privacy Policy
          </li>
        </ol>
      </nav>
      <h1 class="font-48-30 text-primary fw-500 pt-3">Privacy Policy</h1>
    </div>
  </div>
</div>

<section class="container py-6">
  <p class="font-24-16 text-light fw-normal mb-1">
    The privacy policy talks about the way FBSPL uses and protects the
    information that comes through the website.
  </p>
  <p class="font-24-16 text-light fw-normal">
    We are committed to protecting your privacy. The information you provide on
    the website helps us understand who visitors are and when they visit. We do
    not sell your personal information, and your information shall always align
    with the website's privacy statement.
  </p>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">What Information Do We Collect?</h3>
    <ul class="font-24-16 text-light fw-normal">
      <li>Name</li>
      <li>Education/Employment status</li>
      <li>Name of the college/organization</li>
      <li>Email address, mailing address, and phone number</li>
      <li>Pages that you have visited</li>
      <li>Industry/service requirement</li>
    </ul>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">Why Do We Collect Information?</h3>
    <p class="font-24-16 text-light fw-normal">
      We collect your information only for legitimate reasons. If you appear to
      be a potential customer, have expressed interest in our service, or have
      become our customer, we require your information to give you better
      service. We also collect information about job applicants via our careers
      page.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">How We Use Collected Information?</h3>
    <p class="font-24-16 text-light fw-normal">
      We only take your information to learn more about your needs and provide
      better service.
    </p>
    <ul class="font-24-16 text-light fw-normal">
      <li>The records are kept for future use and reference.</li>
      <li>
        The information is taken continuously to improve the website's products
        and services.
      </li>
      <li>
        Not regularly, but at some intervals, we may send periodic emails to
        help you learn about upcoming offers, price changes, or additional
        features related to our services.
      </li>
      <li>Your information helps in serving the purpose of market research.</li>
      <li>
        The information will also improve customer service by responding to
        customer service queries.
      </li>
      <li>
        The information collected will help customize the website to your
        interests and requirements.
      </li>
    </ul>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">How Do We Use Cookies?</h3>
    <p class="font-24-16 text-light fw-normal mb-1">
      We use cookies on our website. This helps assess user experience on our
      website. If you don't know what they are, they are text files that come
      into your browser and keep a note of your preferences.
    </p>
    <p class="font-24-16 text-light fw-normal mb-1">
      The cookies inform us about your computer and visits to this website, such
      as IP address, geographical location, browser type, referral source,
      length of visit, and number of page views.
    </p>
    <p class="font-24-16 text-light fw-normal">
      We may also use anonymous cookies to record non-personal information such
      as website activity, date and time of visit, and domain type. This
      information can be used for retargeting purposes.
    </p>
  </div>

  <div class="mt-5">
    <h3 class="text-primary font-48-30">
      Don't Worry, Your Data Is Secured with Us
    </h3>
    <p class="font-24-16 text-light fw-normal">
      We want to ensure that your information is secure. To avoid unauthorized
      access or disclosure, we secure and safeguard the information as we follow
      the best cyber security practices. Thus, you can rest assured that your
      data will always be safe with us.
    </p>
  </div>
  <div class="mt-5">
    <h3 class="text-primary font-48-30">
      We Don't Share Your Data with Third Parties
    </h3>
    <p class="font-24-16 text-light fw-normal">
      We do not share your information with any third-party vendor or website
      for business purposes. The information we collect from you remains with us
      for the above reasons and the improvement of your experience as a
      customer.
    </p>
  </div>
  <div class="mt-5">
    <h3 class="text-primary font-48-30">
      You Can Unsubscribe Anytime You Wish
    </h3>
    <p class="font-24-16 text-light fw-normal mb-1">
      If you change your mind and no longer want to receive FBSPL's news and
      emails, you can unsubscribe by writing to us at
      <a
        href="mailto:info@fbspl.com"
        class="text-primary text-decoration-underline"
        >info&#64;fbspl.com</a
      >
    </p>
  </div>
  <div class="mt-5">
    <h3 class="text-primary font-48-30">Other Disclosures</h3>
    <p class="font-24-16 text-light fw-normal">
      Remember! By using the website, you have full consent over the privacy
      policy. FBSPL can change the policy at any point in the future. You can
      check the page occasionally to learn about the updated aspects.
    </p>
  </div>
  <div class="mt-5">
    <p class="font-24-16 text-light fw-normal">
      All the above categories exclude text messaging originator opt-in data and
      consent; this information will not be shared with any third parties. We
      will not share your opt-in to an SMS campaign with any third party for
      purposes unrelated to providing you with the services of that campaign. We
      may share your Personal Data, including your SMS opt-in or consent status,
      with third parties that help us provide our messaging services, including
      but not limited to platform providers, phone companies, and any other
      vendors who assist us in the delivery of text messages.
    </p>
  </div>
</section>
