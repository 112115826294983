@if (showComponent) {
<section class="bg-secondary position-relative" id="TalkToExpert">
  <img appCdnUrl="assets/Icon/rocket.png" class="rocket d-none d-md-block" />
  <div class="container py-6 text-center">
    <h4 class="text-primary font-48-30">Talk to our experts</h4>
    <p class="font-24-16 mb-md-5 mx-auto" style="max-width: 850px">
      Need immediate assistance? Talk to us. Our team is ready to help. Fill out
      the form below to connect.
    </p>
    <div class="mx-auto" style="max-width: 1050px">
      <form
        autocomplete="off"
        [formGroup]="talkToExpertForm"
        (ngSubmit)="validateForm(talkToExpertForm)"
      >
        <div class="row">
          <div class="col-md-6 mt-3">
            <input
              type="text"
              class="lets-form"
              formControlName="firstname"
              appNoNumbers
              autocomplete="off"
              placeholder="Name"
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['firstname'].errors
              }"
            />
            @if (submitted && talkToExpertFormf['firstname'].errors) {
            <div class="invalid-feedback">
              @if
              (talkToExpertFormf['firstname'].errors['requiredButNotAllowBlank'])
              {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 mt-3">
            <input
              type="email"
              class="lets-form"
              formControlName="email"
              placeholder="Email Address"
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['email'].errors
              }"
            />
            @if (submitted && talkToExpertFormf['email'].errors) {
            <div class="invalid-feedback">
              @if
              (talkToExpertFormf['email'].errors['requiredButNotAllowBlank']) {
              <div>*This Field is Required</div>
              } @if (talkToExpertFormf['email'].errors['email']) {
              <div>Invalid email address</div>
              }
            </div>
            }
          </div>
          <div class="col-md-6 mt-3">
            <input
              type="text"
              class="lets-form"
              formControlName="company"
              autocomplete="off"
              placeholder="Company Name"
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['company'].errors
              }"
            />
            @if (submitted && talkToExpertFormf['company'].errors) {
            <div class="invalid-feedback">
              @if
              (talkToExpertFormf['company'].errors['requiredButNotAllowBlank'])
              {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <!-- <div class="col-md-6 mt-3">
            <select
              class="form-select lets-form"
              formControlName="services"
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['services'].errors
              }"
            >
              <option value="" selected hidden>Services</option>
              <option value="insurance">Insurance</option>
              <option value="recruitment">Recruitment</option>
              <option value="accounting bookkeeping">
                Accounting & Bookkeeping
              </option>
              <option value="e commerce">E-commerce</option>
              <option value="insurance agency optimization">
                Insurance Agency Optimization
              </option>
              <option value="data annotation">Data Annotation</option>
              <option value="customer support">Customer Support</option>
              <option value="digital marketing">Digital Marketing</option>
            </select>
            <div
              *ngIf="submitted && talkToExpertFormf['services'].errors"
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  talkToExpertFormf['services'].errors[
                    'requiredButNotAllowBlank'
                  ]
                "
              >
                *This Field is Required
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-6 mt-3">
            <input
              type="text"
              class="lets-form"
              formControlName="source"
              autocomplete="off"
              placeholder="Source"
              appNoNumbers
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['source'].errors
              }"
            />
            <div
              *ngIf="submitted && talkToExpertFormf['source'].errors"
              class="invalid-feedback"
            >
              <div
                *ngIf="
                  talkToExpertFormf['source'].errors['requiredButNotAllowBlank']
                "
              >
                *This Field is Required
              </div>
            </div>
          </div> -->
          <div class="col-md-6 mt-3">
            <input
              type="text"
              class="lets-form"
              formControlName="phone"
              autocomplete="off"
              placeholder="Phone"
              required
              appOnlyNumbers
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['phone'].errors
              }"
            />
            @if (submitted && talkToExpertFormf['phone'].errors) {
            <div class="invalid-feedback">
              @if(talkToExpertFormf['phone'].errors['requiredButNotAllowBlank'])
              {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-12 mt-3">
            <input
              type="text"
              class="lets-form"
              formControlName="website"
              autocomplete="off"
              placeholder="Website Url"
              appNoNumbers
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['website'].errors
              }"
            />
            @if (submitted && talkToExpertFormf['website'].errors) {
            <div class="invalid-feedback">
              @if
              (talkToExpertFormf['website'].errors['requiredButNotAllowBlank' ])
              {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>

          <div class="col-md-12 p-2 mt-3">
            <textarea
              class="lets-form"
              formControlName="write_us"
              placeholder="Write your message here..."
              [ngClass]="{
                'is-invalid': submitted && talkToExpertFormf['write_us'].errors
              }"
              rows="4"
            ></textarea>
            @if (submitted && talkToExpertFormf['write_us'].errors) {
            <div class="invalid-feedback">
              @if
              (talkToExpertFormf['write_us'].errors['requiredButNotAllowBlank'])
              {
              <div>*This Field is Required</div>
              }
            </div>
            }
          </div>
          <div class="col-md-12 mt-3 p-2">
            <SubmitBtn text="Submit now"></SubmitBtn>
          </div>
          <!-- <div class="col-md-12 mt-3 p-2">
            <div class="headingCTA">
              <button class="FormCTA">
                <h6 class="font-20 CTAs">
                  Submit your query
                  <div class="headingCTA">
                    <div class="blueCTA podcast-card-cta"></div>
                  </div>
                </h6>
              </button>
            </div>
          </div> -->
        </div>
      </form>
    </div>
  </div>
  <img
    appCdnUrl="assets/Icon/blue-square.svg"
    class="top-right-float vector mt-4"
  />
</section>
}
