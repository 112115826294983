import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterLink, Router, NavigationEnd } from '@angular/router';

import { OnlyNumbersDirective } from '../../directives/onlyNumbers.directive';
import { NoNumbersDirective } from '../../directives/noNumbers.directive';
import {
  requiredButNotAllowBlank,
  customEmailValidator,
} from '../../utils/Validator';
import { HubspotService } from '../../services/hubspot/hubspot.service';
import { CommonModule } from '@angular/common';
import { SubmitBtn } from '../SubmitBtn/SubmitBtn.component';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';

@Component({
  selector: 'TalkToExpertForm',
  standalone: true,
  templateUrl: './TalkToExpertForm.component.html',
  styleUrls: ['./TalkToExpertForm.component.css'],
  imports: [
    CommonModule,
    RouterLink,
    NoNumbersDirective,
    OnlyNumbersDirective,
    ReactiveFormsModule,
    SubmitBtn,
    CdnUrlDirective,
  ],
})
export class TalkToExpertForm implements OnInit {
  talkToExpertForm!: FormGroup;
  submitted = false;
  cdnUrl = environment.cdnUrl;
  showComponent: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private hubspotService: HubspotService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // services: ['', requiredButNotAllowBlank()],
    // source: ['', requiredButNotAllowBlank()],
    this.talkToExpertForm = this.formBuilder.group({
      firstname: ['', requiredButNotAllowBlank()],
      email: ['', [requiredButNotAllowBlank(), customEmailValidator]],
      company: ['', requiredButNotAllowBlank()],
      phone: ['', Validators.pattern(/^\d+$/)],
      website: ['', requiredButNotAllowBlank()],
      write_us: ['', requiredButNotAllowBlank()],
    });

    const excludedUrls = [
      '/book-free-consultation-call',
      '/careers',
      '/careers/current-openings',
      '/jd',
      '/apply-now',
    ];

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Remove any fragments (#...) from the URL
        const baseUrl = event.url.split('#')[0];

        // Check against the base URL for exclusion
        this.showComponent = !excludedUrls.includes(baseUrl);
      }
    });
  }
  get talkToExpertFormf() {
    return this.talkToExpertForm.controls;
  }
  validateForm(form: FormGroup): void {
    this.submitted = true;
    if (this.talkToExpertForm.invalid) {
      return;
    }
    const formId = '9752f77d-ad36-4a60-ac4d-e531fdf855a7';
    const formData = this.talkToExpertForm.value;
    this.hubspotService.submitForm(formId, formData).subscribe(
      (response) => {
        console.log('Form submitted successfully:', response);
        this.router.navigate(['/book-free-consultation-call/thankyou']);
        // Send an email after successful submission
        // this.emailService.sendEmail(formData).subscribe(
        //   (emailResponse) => {
        //     console.log('Email sent successfully:', emailResponse);
        //   },
        //   (error) => {
        //     console.error('Error sending email:', error);
        //   }
        // );
      },
      (error) => {
        console.error('Error submitting form:', error);
        alert('Error submitting form. Please try again.');
      }
    );
  }
}
