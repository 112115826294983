<div class="container text-dark d-none d-lg-block pb-4">
  <div class="gradientBanner">
    <img
      alt="Business Process Outsourcing & Management Services "
      appCdnUrl="assets/Main-Service-Page/main-banner.jpg"
      class="card-img-desktop desktop-banner lazyload"
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3 ms-2" data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              <a aria-current="page">BPM & BPO Services</a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary pt-3">
          Our Business Process Outsourcing & Management Services
        </h1>
        <p class="card-text font-20-16 pt-4">
          Get the experts who work exclusively for your business goals. Join us
          today for real-time insights, 24/7 availability, tool expertise, data
          protection, backup staff at no extra cost, and more.
        </p>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none pb-3">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      alt="Business Process Outsourcing & Management Services "
      appCdnUrl="assets/Main-Service-Page/Main-service-mob-banner.jpg"
      class="card-img-mob"
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            <a aria-current="page">BPM & BPO Services</a>
          </li>
        </ol>
      </nav>
      <h1 class="card-text font-34-19 text-primary">
        Our Business Process Outsourcing & Management Services
      </h1>
      <p class="card-text font-20-16">
        Get the experts who work exclusively for your business goals. Join us
        today for real-time insights, 24/7 availability, tool expertise, data
        protection, backup staff at no extra cost, and more.
      </p>
    </div>
  </div>
</div>

<section class="bg-primary text-white py-6">
  <div class="container px-4">
    <h4 class="font-48-30 mb-3 text-white" data-aos="fade-up">
      Biggest Challenges Faced by Insurers
    </h4>
    <div class="row">
      <div class="row text-center d-none d-md-flex">
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="0">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">82%</p>
            <p class="">
              Small businesses in Accounting and Bookkeeping see failure due to
              cash flow problems.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="200">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">61%</p>
            <p class="">
              Marketers in Insurance Agency Optimization identify lead
              generation as their primary challenge.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="400">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">70%</p>
            <p class="">
              Customers' journeys are dictated by how they feel they are being
              treated, marking the relevancy of customer support.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="600">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">90%</p>
            <p class="">
              Consumers say they trust online reviews (Google), highlighting the
              significance of digital marketing.
            </p>
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div
          class="text-white text-start"
          data-aos="fade-right"
          data-aos-delay="0"
        >
          <p class="font-48-30 fw-bolder mb-1">82%</p>
          <p class="">
            Small businesses in Accounting and Bookkeeping see failure due to
            cash flow problems.
          </p>
        </div>
        <div
          class="text-white text-start"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          <p class="font-48-30 fw-bolder mb-1">61%</p>
          <p class="">
            Marketers in Insurance Agency Optimization identify lead generation
            as their primary challenge.
          </p>
        </div>
        <div
          class="text-white text-start"
          data-aos="fade-right"
          data-aos-delay="400"
        >
          <p class="font-48-30 fw-bolder mb-1">70%</p>
          <p class="">
            Customers' journeys are dictated by how they feel they are being
            treated, marking the relevancy of customer support.
          </p>
        </div>
        <div
          class="text-white text-start"
          data-aos="fade-right"
          data-aos-delay="600"
        >
          <p class="font-48-30 fw-bolder mb-1">90%</p>
          <p class="">
            Consumers say they trust online reviews (Google), highlighting the
            significance of digital marketing.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="container py-2 pt-5">
  <h2 class="text-primary font-48-30 mb-4 pt-4" data-aos="fade-up">
    How Are We Driving Change with our BPM Services?
  </h2>
  <div class="d-none d-lg-block py-5">
    <div class="row">
      <div class="col-md-6 col-lg-4 py-3">
        <a routerLink="/services/insurance-bpo-outsourcing">
          <div class="card bordered serviceCards Insurance" data-aos="zoom-in">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Insurance
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              We have more than 17+ years of experience with end-to-end policy
              administration, claim management, endorsements, and other
              insurance process outsourcing services...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-md-6 col-lg-4 py-3">
        <a routerLink="/services/accounting-bookkeeping-services">
          <div
            class="card bordered serviceCards Account-bookkeeping"
            data-aos="zoom-in"
          >
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Accounting & Bookkeeping
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              If increasing sales aren't translating to profits, if you're
              overspending and missing tax benefits, consider outsourcing
              accounting and bookkeeping services to us...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-md-6 col-lg-4 py-3">
        <a routerLink="/services/data-annotation-services">
          <div class="card bordered serviceCards Annotation" data-aos="zoom-in">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Data Annotation
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              As veterans of the data annotation domain for nearly a decade, we
              understand the significance of consistency, quality, and precision
              in data annotation outsourcing services...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
          </div>
        </a>
      </div>
      <div class="col-md-6 col-lg-4 py-3">
        <a routerLink="/services/data-visualization-services">
          <div class="card bordered serviceCards Visualization" data-aos="zoom-in">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Data Visualization 
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Your data holds the answers to your biggest questions. At FBSPL,
              we offer professional data visualization services powered by the
              latest tools and technologies...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
          </div>
        </a>
      </div>

      <!--<div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div class="card insurance-card policy-process-card p-3 Insurance">
          <a
            routerLink="/services/insurance-bpo-outsourcing"
            class="CTA-Zindex anchorCTA mb-3 p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              Insurance
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            We have more than 17+ years of experience with end-to-end policy
            administration, claim management, endorsements, and other insurance
            process outsourcing services.
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div
          class="card insurance-card policy-process-card p-3 Account-bookkeeping"
        >
          <a
            routerLink="/services/accounting-bookkeeping-services"
            class="CTA-Zindex anchorCTA p-2"
          >
            <h3 class="text-black font-28-20 clickable-heading">
              Accounting & Bookkeeping
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <div class="card-body">
            <p class="card-body-policy text-black font-20-16 ms-2">
              If increasing sales aren't translating to profits, if you're
              overspending and missing tax benefits, consider outsourcing
              accounting and bookkeeping services to us.
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div class="card insurance-card policy-process-card p-3 Annotation">
          <a
            routerLink="/services/data-annotation-services"
            class="CTA-Zindex anchorCTA mb-3 p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              Data Annotation
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            As veterans of the data annotation domain for nearly a decade, we
            understand the significance of consistency, quality, and precision
            in data annotation outsourcing services.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div class="card insurance-card policy-process-card p-3 Recruitment">
          <a
            routerLink="/services/recruitment-process-outsourcing-services"
            class="CTA-Zindex anchorCTA mb-3 p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              Recruitment Process Outsourcing
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            We align hiring with your business strategy. From talent consulting
            to onboarding support, our recruitment support services bring a
            fresh perspective to workforce planning.
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div
          class="card insurance-card policy-process-card p-3 Customer_Support"
        >
          <a
            routerLink="/services/customer-support-outsourcing"
            class="CTA-Zindex anchorCTA mb-3 p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              Customer Support
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            With a proven track record of 4.9 CSAT and 2.60 AHT, we work to turn
            customers into loyal brand ambassadors with our customer support
            management services.
          </p>
        </div>
      </div>
      <div class="col-sm-12 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div class="card insurance-card policy-process-card p-3 Marketing">
          <a
            routerLink="/services/digital-marketing"
            class="CTA-Zindex anchorCTA p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              Digital Marketing
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            Engaging content and campaigns, enhanced conversion rates, and
            elevated brand presence are what we have achieved for our clients
            with our exceptional digital marketing solutions.
          </p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div class="card insurance-card policy-process-card p-3 Optimization">
          <a
            routerLink="/services/insurance-agency-optimization-services"
            class="CTA-Zindex anchorCTA mb-3 p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              Insurance Agency Optimization
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            To keep your agency aligned with the continuously evolving
            innovation trends, we offer insurance agency consulting services
            that ensure scalability and empower P&C's success.
          </p>
        </div>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <div class="card insurance-card policy-process-card p-3 E-commerce">
          <a
            routerLink="/services/e-commerce-outsourcing-services"
            class="CTA-Zindex anchorCTA mb-3 p-2"
          >
            <h3 class="text-black font-28-20 my-auto clickable-heading">
              E-commerce
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          <p class="card-body-policy text-black font-20-16 ms-2">
            Whether it is about getting 360-degree stock visibility, managing
            logistics, or writing compelling product descriptions, we execute
            your e-commerce goals with unparalleled expertise and eagle-eye
            precision.
          </p>
        </div>
      </div> -->
    </div>
  </div>
</section>
<!-- Mobile View -->
<div class="container d-block d-lg-none py-4">
  <div class="row">
    <div class="col-12 mt-0 mt-sm-4">
      <div class="accordion" id="ServicePage">
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Claims_Management"
            aria-expanded="true"
            aria-controls="Claims_Management"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Insurance</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/insurance-bpo-outsourcing"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Claims_Management"
            class="accordion-collapse collapse"
            aria-labelledby="headingtwo"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                We have more than 17+ years of experience with end-to-end policy
                administration, claim management, endorsements, and other
                insurance process outsourcing services.
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Policy_Processing"
            aria-expanded="true"
            aria-controls="Policy_Processing"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Accounting & Bookkeeping</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/accounting-bookkeeping-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Policy_Processing"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                If increasing sales aren't translating to profits, if you're
                overspending and missing tax benefits, consider outsourcing
                accounting and bookkeeping services to us.
              </p>
            </div>
          </div>
        </div>

        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About3"
            aria-expanded="true"
            aria-controls="About3"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Data Annotation</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/data-annotation-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About3"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                As veterans of the data annotation domain for nearly a decade,
                we understand the significance of consistency, quality, and
                precision in data annotation outsourcing services.
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About4"
            aria-expanded="true"
            aria-controls="About4"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Data Visualization </h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/data-visualization-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About4"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                Your data holds the answers to your biggest questions. At FBSPL,
                we offer professional data visualization services powered by the
                latest tools and technologies.
              </p>
            </div>
          </div>
        </div>
        <!--<div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About6"
            aria-expanded="true"
            aria-controls="About6"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Recruitment Process Outsourcing
            </h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/recruitment-process-outsourcing-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About6"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                We align hiring with your business strategy. From talent
                consulting to onboarding support, our recruitment support
                services bring a fresh perspective to workforce planning.
              </p>
            </div>
          </div>
        </div>
         <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About4"
            aria-expanded="true"
            aria-controls="About4"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Customer Support</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/customer-support-outsourcing"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About4"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                With a proven track record of 4.9 CSAT and 2.60 AHT, we work to
                turn customers into loyal brand ambassadors with our customer
                support management services.
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About5"
            aria-expanded="true"
            aria-controls="About5"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Digital Marketing </h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/digital-marketing"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About5"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                Engaging content and campaigns, enhanced conversion rates, and
                elevated brand presence are what we have achieved for our
                clients with our exceptional digital marketing solutions.
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About2"
            aria-expanded="true"
            aria-controls="About2"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Insurance Agency Optimization
            </h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/insurance-agency-optimization-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About2"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                To keep your agency aligned with the continuously evolving
                innovation trends, we offer insurance agency consulting services
                that ensure scalability and empower P&C's success.
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#About1"
            aria-expanded="true"
            aria-controls="About1"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">E-commerce</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/e-commerce-outsourcing-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="About1"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#ServicePage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black font-20-16">
                Whether it is about getting 360-degree stock visibility,
                managing logistics, or writing compelling product descriptions,
                we execute your e-commerce goals with unparalleled expertise and
                eagle-eye precision.
              </p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<ThinkingSection category="insurance"></ThinkingSection>

<!-- FAQ -->
<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>
    <div class="mt-3 mt-md-0" data-aos="fade-left">
      <div class="accordion" id="accordionFAQ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button font-28-20"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Industries"
              aria-expanded="true"
              aria-controls="Industries"
            >
              Why should I outsource my business operations?
            </button>
          </h2>
          <div
            id="Industries"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              We recommend you outsource your business operations as it gives
              you:
              <ul class="pt-3">
                <li>
                  <a
                    class="anchor"
                    target="_blank"
                    href="/services/recruitment-process-outsourcing-services"
                    >Access to global talent and expertise</a
                  >
                </li>
                <li>More time to focus on the core aspects of your business</li>
                <li>Enhanced productivity and efficiency</li>
                <li>Risk mitigation and compliance</li>
                <li>
                  Cost savings on infrastructure, technology, salaries, and
                  other business expenses
                </li>
                <li>Competitive edge and flexibility</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Services"
              aria-expanded="false"
              aria-controls="Services"
            >
              What are the steps that I need to follow if I want to outsource to
              FBSPL?
            </button>
          </h2>
          <div
            id="Services"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              To partner with us, you can give us a call at:
              <ul class="pt-3">
                <li>
                  UNITED STATES:
                  <a href="tel:+1-240-979-0061">+1-240-979-0061</a><br />
                </li>
                <li>
                  CANADA: <a href="tel:+1-240-979-0061">+1-240-979-0061</a>
                </li>
              </ul>
              Or e-mail us at
              <a href="mailto:support@fbspl.com">support&#64;fbspl.com</a>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#About"
              aria-expanded="false"
              aria-controls="About"
            >
              How about data security at FBSPL?
            </button>
          </h2>
          <div
            id="About"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              We consider cybersecurity to be the most critical aspect of our
              operations. With ISO certification, all our systems are securely
              encrypted and comply with globally recognized data security
              standards. In addition, we understand the significance of having
              comprehensive insurance coverage, giving our clients confidence in
              the reliability and excellence of our services.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Resources"
              aria-expanded="false"
              aria-controls="Resources"
            >
              How can I communicate with my offshore team if I give the contract
              to FBSPL?
            </button>
          </h2>
          <div
            id="Resources"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              When you outsource with FBSPL, our team works exclusively for you.
              You can call, e-mail, or chat with them anytime through any
              channel you prefer. You can discuss the project status, ask
              questions, and provide feedback. Furthermore, you'll be assigned
              an account manager who serves as your primary contact and keeps
              you informed about your project's progress. In addition, you can
              conduct training on your specific processes/requirements and tools
              to ensure everyone is on the same page.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Differentiators"
              aria-expanded="false"
              aria-controls="Differentiators"
            >
              What are the main benefits of outsourcing to FBSPL?
            </button>
          </h2>
          <div
            id="Differentiators"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Partnering with us means:
              <ul class="pt-3">
                <li>24/7 availability</li>
                <li>Data protection</li>
                <li>
                  360-degree assistance that meets your dynamic business needs
                  round the clock
                </li>
                <li>Professionally trained employees</li>
                <li>Buck-up services at zero additional cost</li>
                <li>
                  Dedicated customer support, vendor management, and much more
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
