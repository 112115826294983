import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { TalkToExpertForm } from '../TalkToExpertForm/TalkToExpertForm.component';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  // standalone: true,
  // imports: [TalkToExpertForm],
})
export class FooterComponent {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  cdnUrl = environment.cdnUrl;
  ISO2700 = `${this.cdnUrl}assets/home/FBSPL-ISO 27001.pdf`;
  ISO9001 = `${this.cdnUrl}assets/home/FBSPL-QMS.pdf`;
  Csr_Event = `${this.cdnUrl}assets/home/Corporate-Social-Responsibility-Policy.pdf`;

  getcdnUrl(url: String) {
    const CDNurl = `${this.cdnUrl}${url}`;
    return CDNurl;
  }
  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }
}
