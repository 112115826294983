import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SeoService } from '../../../services/seo/seo.service';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SeoData } from '../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../directives/cdn-url.directive';
import { environment } from '../../../../environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../components/ThinkingSection/ThinkingSection.component';
import { dataVisualStepsComponent } from '../../../components/dataVisualSteps/dataVisualSteps.component';

@Component({
  selector: 'app-data-visualization',
  standalone: true,
  templateUrl: './data-visualization.component.html',
  styleUrl: './data-visualization.component.css',
  imports: [
    RouterLink,
    RouterOutlet,
    SlickCarouselModule,
    FaqComponent,
    KnowBestComponent,
    CdnUrlDirective,
    ThinkingSection,
    dataVisualStepsComponent
  ],
})
export class DataVisualizationComponent implements OnInit {
  cdnUrl = environment.cdnUrl;
  constructor(
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Data Visualization & Reporting for Business Insights',
      description:
        'Outsource data visualization services for interactive dashboards, reports, and analytics. Use tools like Power BI & Tableau to drive insights and data-backed business decisions.',
      keywords: 'data visualization services',
      ogImage: '/Customer Support/Banner/Banner Customer Support 1.jpg',
    };
    this.seoService.setMetaTags(seoData);
  }

  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = ``;
        this.renderer.appendChild(this.document.head, script);
      }
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = ``;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }

  data = [
    {
      title: 'Power BI',
      image: 'assets/data-visualization/Power%20BI.svg',
      descriptions: [
        'Create interactive reports with functionalities like drill-down.',
        'Implement row-level security.',
        'Extract data from various sources.',
        'Utilize inbuilt and custom visuals like Power KPI.',
      ],
    },
    {
      title: 'Tableau',
      image: 'assets/data-visualization/Tableau.svg',
      descriptions: [
        'Connect & extract data from various sources.',
        'Perform field actions like renaming, removing, or modifying.',
        'Create advanced visualizations with map data.',
        'Refresh ETL processes for updated dashboards.',
      ],
    },
    {
      title: 'Looker Studio',
      image: 'assets/data-visualization/Looker BI.svg',
      descriptions: [
        'Simplify reporting with customizable dashboards.',
        'Combine data for a comprehensive view.',
        'Share insights across teams with collaborative visualizations.',
        'Create cross-platform dashboards from multiple data sources',
      ],
    },
    {
      title: 'Sigma BI',
      image: 'assets/data-visualization/sigmna.svg',
      descriptions: [
        'Build real-time dashboards directly on your data.',
        'Empower teams with a no-code interface.',
        'Seamlessly collaborate on data analysis across departments.',
        'Integrate data from multiple sources for a unified view.',
      ],
    },
  ];

  slideConfig = {
    slidesToShow: 2,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    arrows: false,
    prevArrow: false,
    nextArrow: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 990, // Tablet and below
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024, // Desktop
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
}
