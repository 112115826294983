<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/accoutning Financial Reporting/Main-banner.jpg"
      class="card-img-desktop desktop-banner"
      alt="Financial Reporting Services "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/accounting-bookkeeping-services"
                  >Accounting & Bookkeeping</a
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a aria-current="page">Financial Reporting</a>
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Outsource Financial Reporting Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Are discrepancies in your financial reports affecting your
            decision-making? Inconsistent data and mounting compliance demands
            can disrupt financial clarity. Financial reporting outsourcing can
            help you achieve accurate and compliant reports. Let's eliminate the
            guesswork and ensure 100% precision.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Improve your financial reporting
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/accoutning Financial Reporting/Mobile-banner.jpg"
      class="card-img-mob"
      alt="Outsource Financial Reporting Services "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping</a
              >
            </li>
            <li class="breadcrumb-item active">
              <a aria-current="page">Financial Reporting</a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Outsource Financial Reporting Services
        </h1>
        <p class="card-text font-20-16">
          Are discrepancies in your financial reports affecting your
          decision-making? Inconsistent data and mounting compliance demands can
          disrupt financial clarity. Financial reporting outsourcing can help
          you achieve accurate and compliant reports. Let's eliminate the
          guesswork and ensure 100% precision.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Improve your financial reporting
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid pt-5" id="financial reporting services">
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Get accurate financial reporting outsourcing services
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#CostAccounting">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Cost Accounting
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Improve your financial management process with apt cost
                accounting services. Our team provides a detailed breakdown...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#InventoryAccounting">
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Inventory Accounting
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Don’t let the inefficient inventory accounting process affect
                your business performance and operational efficiency...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#ReconciliationReporting">
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Reconciliation Reporting
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurate reconciliation reporting is crucial to maintain
                financial integrity. Hence, our reconciliation reporting
                services....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#FinancialPlanning&Analysis"
          >
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Financial Planning & Analysis
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Business success begins with effective financial planning and
                analysis. Our team utilizes predictive forecasting...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#CashflowAnalysis">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Cashflow Analysis
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Get detailed insights into your cash inflows and outflows,
                ensuring complete transparency. By forecasting future cash needs...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#BalanceSheetPreparation">
            <div
              class="card bordered serviceCards Policy_Endorsements"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Balance Sheet Preparation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Are you struggling with maintaining balance sheets, income
                statements, and cash flow reports? Not anymore...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#CostAccounting"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Cost Accounting</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#InventoryAccounting"
          >
            <h3 class="CTAs text-black fs-24 me-3">Inventory Accounting</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#ReconciliationReporting"
          >
            <h3 class="CTAs text-black fs-24 me-3">Reconciliation Reporting</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#FinancialPlanning&Analysis"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Financial Planning & Analysis
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#CashflowAnalysis"
          >
            <h3 class="CTAs text-black fs-24 me-3">Cashflow Analysis</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#BalanceSheetPreparation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Balance Sheet Preparation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="CostAccounting"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="CostAccountingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Cost Accounting</h3>
            <p class="text-dark font-20-16">
              Improve your financial management process with apt cost accounting
              services. Our team provides a detailed breakdown of your business
              expenses, allowing you to identify errors, inefficiencies and
              optimize operations. Get detailed insights into your
              organization’s cost structure with our professional accounting and
              bookkeeping solutions and improve your decision-making.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Identifying cost objectives
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Analyzing direct and indirect costs
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Allocating direct and indirect costing
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Calculating the final costing
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Preparing the final financial report
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 222.webp"
              alt="Cost Accounting Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="InventoryAccounting"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="InventoryAccountingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Inventory Accounting</h3>
            <p class="text-dark font-20-16">
              Don’t let the inefficient inventory accounting process affect your
              business performance and operational efficiency. Our accounting
              experts specialized in detailed tracking and reporting,
              integrating advanced systems to monitor stock levels in real time.
              With our inventory accounting solutions, keep all your stock
              levels accurate, reducing the risks of overstocking or shortages.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Keeping track of inventory counts
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Assigning monetary value to each item
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Choosing the right inventory accounting method
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Recording inventory on the balance sheet
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 223.webp"
              alt="Inventory Accounting Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="ReconciliationReporting"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="ReconciliationReportingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Reconciliation Reporting</h3>
            <p class="text-dark font-20-16">
              Accurate reconciliation reporting is crucial to maintain financial
              integrity. Hence, our reconciliation reporting services ensure
              that all the financial records, including the claims settlement
              and policy transactions align with ledgers and bank statements.
              With simplified audits and enhanced data reliability, we can help
              your business identify risks, prevent fraud, and make informed
              decisions.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Gathering essential data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Comparing and matching gathered data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Identifying internal and external discrepancies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Investigating and resolving discrepancies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Preparing detailed reconciliation report
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 224.webp"
              alt="Reconciliation Reporting   Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="FinancialPlanning&Analysis"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="FinancialPlanning&AnalysisLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Financial Planning & Analysis</h3>
            <p class="text-dark font-20-16">
              Business success begins with effective financial planning and
              analysis. Our team utilizes predictive forecasting, scenario
              modeling, delivering in-depth reports that track and compare your
              financial growth. From uncovering growth opportunities to
              mitigating risks, we offer precise insights and financial planning
              strategies tailored to align with your business goals.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Gathering and validating financial data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Using analytical tools to understand the data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Communicating insights to stakeholders
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Creating a financial plan based on the analysis
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Implementing and monitoring the financial plan
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 225.webp"
              alt="Financial Planning & Analysis"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="CashflowAnalysis"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="CashflowAnalysisLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Cashflow Analysis</h3>
            <p class="text-dark font-20-16">
              Get detailed insights into your cash inflows and outflows,
              ensuring complete transparency. By forecasting future cash needs
              and highlighting areas of improvement, we can help you maintain a
              healthy cash balance. Our solutions are designed to improve
              expense management and optimize resource allocation. Attain 100%
              financial clarity and control with expert-driven cash flow
              analysis solutions.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Gathering all the financial data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Reviewing financial records to understand cashflow
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Predicting future cash inflows and outflows
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Creating cashflow management strategies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Preparing cashflow analysis reports
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 226.webp"
              alt="Cashflow Analysis  Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="BalanceSheetPreparation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="BalanceSheetPreparationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Balance Sheet Preparation</h3>
            <p class="text-dark font-20-16">
              Are you struggling with maintaining balance sheets, income
              statements, and cash flow reports? Not anymore! Our balance sheet
              preparation services ensure accurate and detailed financial
              statements that clearly reflect your business’s financial health.
              Get accurate reports aligning with accounting standards like GAAP
              or IFRS and offer valuable insights to drive your business
              forward.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Organizing financial data into spreadsheets
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Totaling up all the business assets
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Adding short-term and long-term business liabilities
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Calculating the owner’s equity
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Preparing the balance sheet
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 227.webp"
              alt="Balance Sheet Preparation Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven financial reporting solutions
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool Zoho  "
          appCdnUrl="assets/Tools/zoho.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool QuickBooks "
          appCdnUrl="assets/Tools/quickbooks.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool NetSuite "
          appCdnUrl="assets/Tools/netsuite.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool odoo "
          appCdnUrl="assets/Tools/odoo.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool xero "
          appCdnUrl="assets/Tools/xero.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<app-ourAssets></app-ourAssets>
<!-- Know best -->
<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
      alt: ' Listen to our client '
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt: 'Our Success Stories'
    }
  ]"
>
</app-know-best>
<!-- Thinking -->
<ThinkingSection category="accountingandbookkeeping"></ThinkingSection>

<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>
    <div class="mt-3 mt-md-0" data-aos="fade-left">
      <div class="accordion" id="accordionFAQ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button font-28-20"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Industries"
              aria-expanded="true"
              aria-controls="Industries"
            >
              What is the month-end close process?
            </button>
          </h2>
          <div
            id="Industries"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              The month-end close process is a crucial process that is done at
              the end of each month to ensure accurate and timely financial
              reporting. It involves several steps, including reconciling
              accounts, reviewing transactions, adjusting entries, preparing
              financial statements, and analyzing performance.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Services"
              aria-expanded="false"
              aria-controls="Services"
            >
              What types of tools do you use for accounting and bookkeeping
              processes including financial reporting?
            </button>
          </h2>
          <div
            id="Services"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              We hold expertise in leveraging multiple types of tools for
              accounting and bookkeeping including financial reporting. Here are
              the major tools we use.
              <ul class="pt-3">
                <li>QuickBooks</li>
                <li>Zoho</li>
                <li>Oddo</li>
                <li>Sage</li>
                <li>NetSuite</li>
                <li>Xero</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#About"
              aria-expanded="false"
              aria-controls="About"
            >
              Why should I choose you for financial reporting services?
            </button>
          </h2>
          <div
            id="About"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              At
              <a href="./" class="anchor" target="_blank">FBSPL</a>, we make
              sure to maintain data accuracy and precision while offering
              financial reporting services. If you are still wondering why we
              are the best fit for you, here is what set us apart.
              <ul class="pt-3">
                <li>All-in-one expertise</li>
                <li>98% data accuracy</li>
                <li>Cost-effective solutions</li>
                <li>Contractual services</li>
                <li>Availability as per client’s time zone</li>
                <li>Tailor-made solutions</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Resources"
              aria-expanded="false"
              aria-controls="Resources"
            >
              Can you describe the process of reviewing and preparing financial
              statements?
            </button>
          </h2>
          <div
            id="Resources"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Here is a checklist for financial statement review and
              preparation.
              <ul class="pt-3">
                <li>Verify the accuracy of financial documents</li>
                <li>
                  Scrutinize the balance sheet & review the income statement
                </li>
                <li>Evaluate the cash flow statement</li>
                <li>
                  Compare current statements with previous period statements
                </li>
                <li>
                  Assess the company's financial performance against industry
                  benchmarks
                </li>
                <li>Understand the financial ratios and indicators</li>
                <li>Formulate preliminary conclusion and recommendations</li>
                <li>Prepare financial statement review report</li>
                <li>Submit review report to the management</li>
                <li>
                  Review updated financials for compliance with recommendations
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#differentiators"
              aria-expanded="false"
              aria-controls="differentiators"
            >
              Can a non-CPA prepare financial statements using the FRF
              framework?
            </button>
          </h2>
          <div
            id="differentiators"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Non-CPAs may prepare financial statements using available
              financial frameworks including the FRF framework, cash, tax, and
              even GAAP bases of accounting.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSix">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#GAAP"
              aria-expanded="false"
              aria-controls="GAAP"
            >
              Why is GAAP so important?
            </button>
          </h2>
          <div
            id="GAAP"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              With GAAP, companies and any entity in the finance sector would
              have their functioning continued. GAAP allows its stakeholders to
              quickly evaluate companies by assessing their financial
              statements. When an investor is in two minds about companies in
              the same sector, he can compare those companies' financial
              statements to make an investment decision. GAAP also helps
              companies gain key insights into their practices and performances.
              Moreover, it helps minimize the risk of errors in financial
              reporting by having checks and safeguards at multiple levels while
              preparing financial statements.
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
