import { Component , OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../../../components/ThinkingSection/ThinkingSection.component';

@Component({
    selector: 'app-new-business',
    standalone: true,
    templateUrl: './new-business.component.html',
    styleUrl: './new-business.component.css',
    imports: [
    FaqComponent, KnowBestComponent,
    ThinkingSection, CdnUrlDirective,
    ourAssetsComponent
]
})
export class newBusinessComponent implements OnInit{
  constructor(private meta: Meta, private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsource Insurance New Business Setup Services - FBSPL | ',
      description:
        "Onboard clients with FBSPL's new business setup services. Our team of insurance BPO experts handles efficient setup for insurance carriers and agencies.",
      keywords: "insurance back office, insurance new business",
      ogImage: '/Insurance new business/Insurance new business copy.jpg'
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "What is the insurance new business setup process you follow?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "As a leading insurance outsourcing services provider, we provide the best solutions to new businesses with a quick turnaround time. Here is a streamlined process that we follow. 

<ul> 

<li>Identifying the objects to be insured</li> 

<li>Preparing the new insurance policies</li> 

<li>Reviewing the policies for accuracy</li> 

<li>Creating policies with the terms and conditions</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why choose us for insurance new business servicing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "If you are wondering why our new business service solutions are the best, here is what makes us stand out from the rest. 

<ul> 

<li>All-in-one expertise</li> 

<li>Availability as per your time zone</li> 

<li>Quick turnaround time</li> 

<li>Tailor-made solutions</li> 

<li>99% accuracy</li> 

<li>Scalable solutions</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How will you ensure the accuracy of the Proposal or Quote?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our insurance veterans cross-check the quote against the quote request to ensure the coverage, demographic, and premium information, and then prepare a proposal to ensure accuracy." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How will you collect the information for quoting?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our team exchanges the intake or questionnaire form with the prospect or account manager to collect the information to initiate the quoting." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the preferred method to get the document signed for binding?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Upon the Account Manager's approval, we create a packet of proposal + Acord forms/quote application or supplemental apps + TRIA or surplus lines forms (depending on the product and carrier) and send the documents to the customer for signature through DocuSign." 

      } 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Insurance", 

      "item": "https://www.fbspl.com/services/insurance-bpo-outsourcing" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "New Business", 

      "item": "https://www.fbspl.com/services/insurance-new-business-servicing" 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
  }
}
}
