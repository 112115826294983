import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { currentOpeningService } from '../../services/currentOpening/currentOpening.service';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { JdGlobalDataService } from '../../services/JdGlobalData/JdGlobalDataService.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-currentOpening',
  standalone: true,
  imports: [RouterLink, CdnUrlDirective],
  templateUrl: './currentOpening.component.html',
  styleUrl: './currentOpening.component.css',
})
export class CurrentOpeningComponent implements OnInit {
  openings: any = [];
  queryString!: string | '';

  constructor(
    private seoService: SeoService,
    private currentOpeningService: currentOpeningService,
    private dataService: JdGlobalDataService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Career Opportunities and Job Openings | Join the FBSPL Team',
      description:
        'Check out our current job openings and apply for the suitable position for your career growth. Join our dynamic workplace -FBSPL team.',
      keywords: 'job openings',
      ogImage: '/Current-oppning/CurrentOpp.Banner.jpg',
    };
    this.seoService.setMetaTags(seoData);
    // this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/career/current-openings' });
    this.savecampaignDetail();
    this.loadOpenings();
  }

  savecampaignDetail(): void {
    if (isPlatformBrowser(this.platformId)) {
      const urlParams = window.location.search;
      if (urlParams.length > 5) {
        this.dataService.setcampaignDetail(urlParams);
        console.log('Query String:', urlParams);
      }
    }
  }

  loadOpenings(): void {
    this.currentOpeningService.getcurrentOpeningData().subscribe(
      (data) => {
        this.openings = data.data;
      },
      (error) => {
        console.error('Error fetching current-opening data', error);
      }
    );
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    {     
    "@context": "https://schema.org",    
   "@type": "BreadcrumbList",    
   "itemListElement": [    
      {    
       "@type": "ListItem",    
       "position": 1,    
       "name": "Home",    
   "item": "https://www.fbspl.com/"    
    },    
      {    
       "@type": "ListItem",    
       "position": 2,    
       "name": "Career",    
       "item": "https://www.fbspl.com/careers"    
     },    
     {    
       "@type": "ListItem",    
       "position": 3,    
     "name": "Current Openings",    
       "item": "https://www.fbspl.com/careers/current-openings"    
     }    
   ]    
}   `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  }
}
}
