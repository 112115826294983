<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/accoutning Receivable Management/main-banner.jpg"
      class="card-img-desktop desktop-banner"
      alt="Accounts Receivable Services "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/accounting-bookkeeping-services"
                  >Accounting & Bookkeeping</a
                >
              </li>
              <li class="breadcrumb-item active">
                <a aria-current="page">Accounts Recievable Management</a>
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Accounts Receivable (AR) Outsourcing Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Are you overwhelmed by unpaid invoices, delayed payments, and cash
            flow gaps? Let us take the weight off your shoulders. With our
            comprehensive accounts receivable solutions, free up working
            capital, accelerate your collections, reduce disputes, and maintain
            healthier financials.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Manage your receivables now
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/accoutning Receivable Management/Mobile-banner.jpg"
      class="card-img-mob"
      alt="Accounts Receivable (AR) Outsourcing Services "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping</a
              >
            </li>
            <li class="breadcrumb-item active">
              <a aria-current="page">Accounts Recievable Management</a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Accounts Receivable (AR) Outsourcing Services
        </h1>
        <p class="card-text font-20-16">
          Are you overwhelmed by unpaid invoices, delayed payments, and cash
          flow gaps? Let us take the weight off your shoulders. With our
          comprehensive accounts receivable solutions, free up working capital,
          accelerate your collections, reduce disputes, and maintain healthier
          financials.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Manage your receivables now
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section
  class="container-fluid pt-5"
  id="Accounts Receivable Outsourcing Services"
>
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Accounts receivable services we offer
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#CustomerInvoices">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Customer Invoices
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Tired of chasing down payments or dealing with errors in
                invoicing? We understand that inaccurate invoices can disrupt
                cash flow...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#CustomerStatementReconciliation"
          >
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Customer Statement Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Mismatched statements and account discrepancies slow down
                financial operations. Our accounts receivable management...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#RevenueRecognition">
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Revenue Recognition
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                When revenue recognition gets complex, we bring clarity. Our AR
                accounting team ensures every income stream is categorized and
                accurately....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#DisputeHandling">
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Dispute Handling
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                What if payment disputes could be resolved without disrupting
                your cash flow? That’s what we deliver. Our team identifies and
                resolves issues...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PaymentReceiptAllocation">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Payment Receipt & Allocation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Efficient payment handling is crucial for maintaining accurate
                financial records. Our payment receipt and allocation
                services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#ReceivableReporting">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Receivable Reporting
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurate and timely reports empower smarter financial decisions.
                Get access to comprehensive receivable reporting services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#CustomerInvoices"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Customer Invoices</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#CustomerStatementReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Customer Statement Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#RevenueRecognition"
          >
            <h3 class="CTAs text-black fs-24 me-3">Revenue Recognition</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#DisputeHandling"
          >
            <h3 class="CTAs text-black fs-24 me-3">Dispute Handling</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PaymentReceiptAllocation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Payment Receipt & Allocation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#ReceivableReporting"
          >
            <h3 class="CTAs text-black fs-24 me-3">Receivable Reporting</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="CustomerInvoices"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="CustomerInvoicesLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Customer Invoices</h3>
            <p class="text-dark font-20-16">
              Tired of chasing down payments or dealing with errors in
              invoicing? We understand that inaccurate invoices can disrupt cash
              flow and affect client relationships. Our accounting experts take
              charge of your invoicing process, ensuring error-free invoices and
              on-time invoice delivery. With our support, you can remain assured
              of timely invoice payments while keeping your customers informed.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Assessing the customer's creditworthiness
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Generating an invoice
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Sending an invoice to the customer
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Tracking the invoice to ensure payment received
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Reconciling the received payment
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 222.webp"
              alt="Customer Invoices Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="CustomerStatementReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="CustomerStatementReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">
              Customer Statement Reconciliation
            </h3>
            <p class="text-dark font-20-16">
              Mismatched statements and account discrepancies slow down
              financial operations. Our accounts receivable management resources
              guarantee every transaction aligns perfectly with your records and
              customer statements. With precise reconciliation, we eliminate
              accounting errors, and help you build trust with your customers.
              Let's identify account discrepancies and get started with
              maintaining accurate financial records.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting invoices, receipts, and other data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Comparing account entries with corresponding invoices
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Investigating unmatched items or entries to resolve
                discrepancies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Making necessary adjustments to the accounts
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Conducting regular reconciliations on a monthly or quarterly
                basis
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 223.webp"
              alt="Customer Statement Reconciliation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="RevenueRecognition"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="RevenueRecognitionLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Revenue Recognition</h3>
            <p class="text-dark font-20-16">
              When revenue recognition gets complex, we bring clarity. Our AR
              accounting team ensures every income stream is categorized and
              accurately recorded in line with accounting standards. Never
              second-guess your reporting with accurate revenue streams that
              ensure precise financial reporting. Let us transform complex
              revenue cycles into actionable insights that fuel decision-making.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Evaluating the contract with the customers
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Analyzing the performance obligations within the contract
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Determining the amount of revenue considerations
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Additional transactional pricing to each performance
                obligation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Recognizing revenue after performance obligation
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 224.webp"
              alt="Revenue Recognition Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="DisputeHandling"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="DisputeHandlingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Dispute Handling</h3>
            <p class="text-dark font-20-16">
              What if payment disputes could be resolved without disrupting your
              cash flow? That’s what we deliver. Our team identifies and
              resolves issues efficiently to safeguard client relationships and
              maintain trust. With our proactive accounting and bookkeeping
              solutions, we handle disputes before they escalate. When you
              outsource accounts receivable to us, you make room for smoother
              transactions.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Receiving all the dispute cases from the clients
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Tracking and prioritizing the dispute cases
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Collecting necessary data and resolving disputes
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Submitting the dispute resolution to the team
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Informing the customers after dispute resolution
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 225.webp"
              alt="Dispute Handling"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="PaymentReceiptAllocation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PaymentReceiptAllocationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Payment Receipt & Allocation</h3>
            <p class="text-dark font-20-16">
              Efficient payment handling is crucial for maintaining accurate
              financial records. Our payment receipt and allocation services
              ensure every payment is tracked, recorded, and allocated for
              precise account creation. We help you maintain clear financial
              records and strengthen your cash flow visibility. Our team
              oversees the process to ensure there are no misplaced payments or
              incorrect allocations.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting payments from the customers
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Recording the incoming payment receipts
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Allocating the payments to the customers' accounts
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Resolving payment discrepancies if any
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Updating cash receipts and doing accounts reconciliation
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 226.webp"
              alt="Payment Receipt & Allocation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="ReceivableReporting"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="ReceivableReportingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Receivable Reporting</h3>
            <p class="text-dark font-20-16">
              Accurate and timely reports empower smarter financial decisions.
              Get access to comprehensive receivable reporting services to gain
              detailed insights into your cash flow management. With intuitive
              dashboards and detailed analytics, monitor outstanding invoices
              and make informed strategic decisions. Turn your data into a
              strategic advantage while choosing management solutions for your
              accounts receivable.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Establishing credit policy for the customer
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Generating invoices and recording the sale
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting payment information from the customer
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Reconciling customers’ accounts to ensure timely payments
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Managing all the bad debts of the customers
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 227.webp"
              alt="Receivable Reporting Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<!-- Know best -->
<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
      alt: 'Listen to our client '
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt: 'Our Success Stories'
    }
  ]"
>
</app-know-best>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven accounts receivable management services (tools)
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool Zoho  "
          appCdnUrl="assets/Tools/zoho.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool QuickBooks "
          appCdnUrl="assets/Tools/quickbooks.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool NetSuite "
          appCdnUrl="assets/Tools/netsuite.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool odoo "
          appCdnUrl="assets/Tools/odoo.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool xero "
          appCdnUrl="assets/Tools/xero.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<ThinkingSection category="accountingandbookkeeping"></ThinkingSection>
<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>
    <div class="mt-3 mt-md-0" data-aos="fade-left">
      <div class="accordion" id="accordionFAQ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button font-28-20"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Industries"
              aria-expanded="true"
              aria-controls="Industries"
            >
              Why should I choose you?
            </button>
          </h2>
          <div
            id="Industries"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              You should partner with us if you are looking for:
              <ul class="pt-3">
                <li>
                  360-degree assistance that meets your dynamic business needs
                  round the clock
                </li>
                <li>Professionally trained employees</li>
                <li>Buck-up services at zero additional cost</li>
                <li>
                  Dedicated
                  <a
                    class="anchor"
                    target="_blank"
                    href="/services/customer-support-outsourcing"
                    >customer support</a
                  >, vendor management, and much more 
                </li>
              </ul>
              Partnering with us means aligning with the industry excellence.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Services"
              aria-expanded="false"
              aria-controls="Services"
            >
              Is there any benefit to outsourcing accounts receivable
              management?
            </button>
          </h2>
          <div
            id="Services"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Outsourcing account receivable management can free up your time
              and resources, allowing you to focus on other business aspects.
              You can ensure cost savings, increased efficiency, and get access
              to the latest tools and technologies to ensure smooth account
              receivables.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#About"
              aria-expanded="false"
              aria-controls="About"
            >
              How does your outsourcing company handle Accounts Receivable
              management?
            </button>
          </h2>
          <div
            id="About"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Our outsourcing company follows a comprehensive process for
              managing accounts receivable. Here is a quick glimpse of the
              process we follow -
              <ul class="pt-3">
                <li>Processing the invoices</li>
                <li>Tracking customers payments</li>
                <li>Tracking and resolving customer queries</li>
                <li>Ensuring debt recovery</li>
                <li>Conducting final reporting</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Resources"
              aria-expanded="false"
              aria-controls="Resources"
            >
              What is your tool's expertise?
            </button>
          </h2>
          <div
            id="Resources"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Our team is well-versed with using and integrating all the latest
              tools and technologies required to ensure smooth accounting and
              bookkeeping. Here are some of the major tools we have hands-on
              experience of.
              <ul class="pt-3">
                <li>QuickBooks</li>
                <li>Xero</li>
                <li>Zoho</li>
                <li>Oddo</li>
                <li>Sage</li>
                <li>NetSuite</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#differentiators"
              aria-expanded="false"
              aria-controls="differentiators"
            >
              How do you expedite the account receivable procedures?
            </button>
          </h2>
          <div
            id="differentiators"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              For expediting the account receivable, we do cash collections for
              the amount overdue, due and will be due in the near future.
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
