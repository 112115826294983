import { Component, OnInit,Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-data-management',
    standalone: true,
    templateUrl: './data-management.component.html',
    styleUrl: './data-management.component.css',
    imports: [
        FaqComponent,
        ServiceCaseStudyComponent,KnowBestComponent,RouterLink, RouterOutlet,CdnUrlDirective,ourAssetsComponent
    ]
})
export class DataManagement implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService,private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Ecommerce Data Management Solutions | FBSPL',
      description:
        "Our data management team handles the details and complexities of your ecommerce business's product data, customer data, images, and bookkeeping. Consult with our team today.",
      keywords: 'ecommerce data management, data management services',
      ogImage: '/data-management/main-banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
         { 

      "@type": "Question", 

      "name": "How do your data entry services enhance my brand visibility?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our data entry services go beyond populating fields. From optimizing product titles and descriptions with relevant keywords to running targeted e-mail campaigns and sending personalized messages, our data processing solutions are your key to driving higher customer engagement and conversion rates." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What approach does your e-commerce data management team take to handle data overload?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "In case of data overload, our team brings in the following approach: 

<ul><li>Align data inputs and analysis efforts with current business objectives.</li> 

<li>Focus on metrics that directly affect customer satisfaction and conversion rates.</li> 

<li>Identify and remove duplicate and inaccurate data.</li> 

<li>Reduce manual efforts and improve data consistency.</li> 

<li>Present data clearly and concisely to facilitate informed decision-making.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure the security and protection of my data?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "To safeguard your data's security and integrity, we: 

<ul><li>Employ strict access control</li> 

<li>Comply with industry standards and regulations</li> 

<li>Encrypt all your sensitive data during transmission and storage</li> 

<li>Conduct regular data backups to prevent data loss</li> 

<li>Continuously monitor and audit your system to identify and promptly address security risks </li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "In what ways can your data management solutions help me improve customer satisfaction?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our data management solutions help you with: 

<ul><li> Improved search functionality, enabling customers to easily find products they are looking for 

<li>A personalized experience for your consumers through targeted recommendations and promotions</li> 

<li>Efficient order processing and fulfillment</li> 

<li>A system that provides prompt and practical assistance to your customers' queries</li> 

<li>Real-time insights into your customers' challenges and whether your services expertly address them</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for: 

<ul><li>360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> 

Partnering with us means aligning with the industry excellence. " 

      } 

    } 
        ]
      }`;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      {  

  

  "@context": "https://schema.org",  

   "@type": "BreadcrumbList",  

   "itemListElement": [  

     {  

       "@type": "ListItem",  

       "position": 1,  

       "name": "Home",  

       "item": "https://www.fbspl.com/"  

     },  

     {  

       "@type": "ListItem",  

       "position": 2,  

       "name": "Services",  

       "item": "https://www.fbspl.com/bpm-and-bpo-services"  

     },  

     {  

  

      "@type": "ListItem",  

       "position": 3,  

       "name": "eCommerce",  

       "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services"  

     },  

     {  

  

      "@type": "ListItem",  

  

      "position": 4,  

       "name": "Data Management Solutions",  

       "item": "https://www.fbspl.com/services/e-commerce-data-management"  

     }  

  

  ]  

  

}  `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
  }
}
}
