import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';

@Component({
  selector: 'app-Infographic',
  standalone: true,
  imports: [],
  templateUrl: './Infographic.component.html',
  styleUrl: './Infographic.component.css',
})
export class InfographicComponent implements OnInit {
  @Input() countersp: number[] = [6, 1082, 18, 517]; // Array to hold counter values
  @Input() labels: string[] = [
    'Countries served',
    'Employees',
    'Years of experience',
    'Served clients',
  ];
  counters = [
    { target: 18, current: 0, label: 'Years of experience' },
    { target: 550, current: 0, label: 'Served clients' },
    { target: 1050, current: 0, label: 'Employees' },
    { target: 6, current: 0, label: 'Countries served' },
  ];
  @Input() unit: string = '+';

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.startCounters();
    }
  }

  startCounters(): void {
    this.counters.forEach((counter) => {
      this.animateCounter(counter.target, counter);
    });
  }

  animateCounter(targetValue: number, counter: any): void {
    const duration = targetValue > 100 ? 3000 : 2000; // Fast for large numbers, slower for small numbers
    const steps = Math.round(duration / 16); // Approx. 60 FPS (16ms per frame)
    const increment = Math.ceil(targetValue / steps); // Increment per step

    let currentValue = 0;
    const interval = setInterval(() => {
      currentValue += increment;
      counter.current = Math.min(currentValue, targetValue); // Ensure it doesn't exceed the target value

      if (counter.current >= targetValue) {
        clearInterval(interval); // Stop when target value is reached
      }
    }, duration / steps);
  }
}
