<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Insurance new business/Insurance new business copy.jpg"
      class="card-img-desktop desktop-banner"
      alt="FBSPL Insurance New Business Servicing "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/insurance-bpo-outsourcing">Insurance</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                New Business
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Insurance New Business Setup Services
          </h1>
          <p class="card-text font-20-16">
            Setting up a new insurance business is no small task – it requires
            expertise and hands-on experience. Our insurance new business
            services providers cover every business setup step with care – from
            quote management to prospect setup and policy issuance.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Set Up New Business
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/Insurance new business/Insurance new business mobile copy.jpg"
      class="card-img-mob"
      alt="FBSPL Insurance New Business Setup Services  "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item" aria-current="page">
              <a href="./">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/insurance-bpo-outsourcing">Insurance</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              New Business
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Insurance New Business Setup Services
        </h1>
        <p class="card-text font-20-16">
          Setting up a new insurance business is no small task – it requires
          expertise and hands-on experience. Our insurance new business services
          providers cover every business setup step with care – from quote
          management to prospect setup and policy issuance.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Set Up New Business
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid pt-5" id="Insurance New Business Services">
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Insurance new business setup services we offer
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#QuotesManagement">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Quotes Management
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Get all your policy quotes and documents managed in no time. Our
                expert insurance VA team carefully verifies all details...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PolicyChecking">
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Policy Checking
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Our policy checking services ensure a rigorous, multi-tiered
                verification process to maintain precise alignment with...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#Pre-underwritingSupport ">
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Pre-underwriting Support
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Prepare for seamless underwriting with our comprehensive
                pre-underwriting support. Our professionals manage everything....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#InsuranceDataEntry">
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Insurance Data Entry
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Capture and digitize your insurance new business data entry
                accurately with our insurance new business servicing...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#InsuranceAdministration">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Insurance Administration
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Effective insurance administration drives seamless insurance
                operations. To deliver a smooth experience...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PolicyIssuance">
            <div
              class="card bordered serviceCards Policy_Endorsements"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Policy Issuance
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Smooth policy issuance is critical for a smooth onboarding
                experience. Hence, our policy issuance services ensure...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#QuotesManagement"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Quotes Management</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PolicyChecking"
          >
            <h3 class="CTAs text-black fs-24 me-3">Policy Checking</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#Pre-underwritingSupport "
          >
            <h3 class="CTAs text-black fs-24 me-3">Pre-underwriting Support</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#InsuranceDataEntry"
          >
            <h3 class="CTAs text-black fs-24 me-3">Insurance Data Entry</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#InsuranceAdministration"
          >
            <h3 class="CTAs text-black fs-24 me-3">Insurance Administration</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PolicyIssuance"
          >
            <h3 class="CTAs text-black fs-24 me-3">Policy Issuance</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="QuotesManagement"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="QuotesManagementLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Quotes Management</h3>
            <p class="text-dark font-20-16">
              Get all your policy quotes and documents managed in no time. Our
              expert insurance VA team carefully verifies all details, ensuring
              data accuracy and consistency with quoted terms. We handle
              everything from quote comparison to compliance checks, so you can
              be confident every policy is issued timely and error-free. With
              our support, your agency can provide clients with reliable
              experience, building trust and setting the stage for long-term
              relationships.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Submitting the request for quotes
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Conducting risk assessment
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Calculating premiums for policyholders
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Delivering quotes to policyholders
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Following up with policyholders and addressing queries
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/Insurance new business/Quotes Management.webp"
              alt="FBSPL Quotes Management Service "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="PolicyChecking"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PolicyCheckingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Policy Checking</h3>
            <p class="text-dark font-20-16">
              Our policy checking services ensure a rigorous, multi-tiered
              verification process to maintain precise alignment with industry
              standards. Each policy undergoes a thorough assessment using
              automated discrepancy detection, data validation, and
              cross-referencing to flag inconsistencies. We integrate real-time
              error detection tools and compliance algorithms to reduce human
              error and enhance processing speed. With us, you can remain
              assured of delivering a seamless client experience, strengthening
              your agency’s reliability.
            </p>

            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Submitting documents for policy checking
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Verifying document authenticity
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Accessing risks associated with the policy
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Reviewing policy, coverage, and terms & conditions
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Ensuring policy approval and rejection
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/insurane policy processing/Policy Checking.png"
              alt="FBSPL Policy Checking Service "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="Pre-underwritingSupport"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="Pre-underwritingSupportLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Pre-underwriting Support</h3>
            <p class="text-dark font-20-16">
              Prepare for seamless underwriting with our comprehensive
              pre-underwriting support. Our professionals manage everything from
              application receipts to gathering essential documents and profile
              risk evaluation. Every application is carefully examined to
              identify potential issues, enabling underwriters to make quicker,
              well-informed decisions. Strengthen your underwriting process from
              the outset and ensure complete accuracy.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Receiving application receipts
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Conducting preliminary risk assessment
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting documents from the applicants
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Performing detailed evaluation of risk profile
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Making decisions based on the evaluation
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/Insurance new business/Policy Issuance_.png"
              alt="FBSPL Pre-underwriting Support Service "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="InsuranceDataEntry"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="InsuranceDataEntryLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Insurance Data Entry</h3>
            <p class="text-dark font-20-16">
              Capture and digitize your insurance new business data entry
              accurately with our insurance new business servicing. Our
              solutions aid in accurate policy creation, timely claim
              processing, and better customer service. By entrusting us with
              your insurance back-office needs, you can reduce errors by 99%,
              increase accessibility, and ensure a solid data backbone for all
              your insurance operation's needs. Let our insurance virtual
              assistance team manage your data entry.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Receiving an application for insurance
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Verifying the data authenticity
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Entering the data into the systems
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Adding data for validation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Ensuring data storage and retrieval
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
              alt="FBSPL Insurance Data Entry Service "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="InsuranceAdministration"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="InsuranceAdministrationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Insurance Administration</h3>
            <p class="text-dark font-20-16">
              Effective insurance administration drives seamless insurance
              operations. To deliver a smooth experience, we offer end-to-end
              insurance administration services, from policy renewals and
              endorsements to claims support and client communication. With us,
              you can experience streamlined workflows and compliance. We can
              support with your entire administrative load so your team can
              deliver a smooth, timely, and client-focused experience.
              <a
                class="anchor"
                target="_blank"
                href="/book-free-consultation-call"
                >Connect with us to get your new business administration</a
              >
              on track.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Issuing the policy documents
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting the premiums from policyholders
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Filing and verifying the claims
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Sending policy renewal reminders
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Providing ongoing support to policyholders
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/Insurance new business/Insurance Administration_.png"
              alt="FBSPL Insurance Administration - new insurance business service"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="PolicyIssuance"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PolicyIssuanceLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row h-100">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Policy Issuance</h3>
            <p class="text-dark font-20-16">
              Smooth policy issuance is critical for a smooth onboarding
              experience. Hence, our policy issuance services ensure that every
              policy is accurately prepared, verified, and issued according to
              regulatory standards. From data validation to document
              preparation, we handle each step meticulously to guarantee
              accuracy and reduce processing time. Get ready to establish trust
              and build a strong foundation for lasting client relationships for
              efficient insurance new business setup.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Validating insurance policy paperwork
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Generating new insurance policies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Creating policy setup documents
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Verifying customer's policy details
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Generating final insurance policies
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/Insurance new business/Policy Issuance_.png"
              alt="FBSPL Policy Endorsements Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven insurance new business setup services
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row g-5">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="Insurance tool Applied System  "
          appCdnUrl="assets/Tools/applied.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Insurance tool Hawksoft   "
          appCdnUrl="assets/Tools/hawksoft.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid"
          alt="Insurance tool PL Rating "
          appCdnUrl="assets/Tools/pl rating.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="Insurance tool QQCatalyst "
          appCdnUrl="assets/Tools/catalyst.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="400">
        <img
          class="img-fluid"
          alt="Insurance tool AMS 360 "
          appCdnUrl="assets/Tools/ams360.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="500">
        <img
          class="img-fluid"
          alt="Insurance tool EZLynx "
          appCdnUrl="assets/Tools/ezlynx.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="600">
        <img
          class="img-fluid"
          alt="Insurance tool CSR24"
          appCdnUrl="assets/Tools/csr27.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="700">
        <img
          class="img-fluid"
          alt="Insurance tool NowCerts"
          appCdnUrl="assets/Tools/nowcerts.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img
          alt="Insurance tool Power Broker "
          class="img-fluid"
          appCdnUrl="assets/Tools/powerbroker.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img
          alt="Insurance tool AgencyMatrix "
          class="img-fluid"
          appCdnUrl="assets/Tools/agencymatrix.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="900">
        <img
          alt="Insurance tool employeeNavigator "
          class="img-fluid"
          appCdnUrl="assets/Tools/employeeNavigator.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="1000">
        <img
          class="img-fluid"
          alt=" Insurance tool indio "
          appCdnUrl="assets/Tools/indio.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool Applied System  "
            appCdnUrl="assets/Tools/applied.svg"
            data-aos="fade-right"
            data-aos-delay="0"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="100"
            class="img-fluid"
            alt="Insurance tool Hawksoft  "
            appCdnUrl="assets/Tools/hawksoft.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="200"
            class="img-fluid"
            alt="Insurance tool PL Rating "
            appCdnUrl="assets/Tools/pl rating.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool QQCatalyst "
            data-aos="fade-right"
            data-aos-delay="300"
            appCdnUrl="assets/Tools/catalyst.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool AMS 360 "
            appCdnUrl="assets/Tools/ams360.svg"
            data-aos="fade-right"
            data-aos-delay="400"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool EZLynx "
            appCdnUrl="assets/Tools/ezlynx.svg"
            data-aos="fade-right"
            data-aos-delay="500"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool CSR24  "
            appCdnUrl="assets/Tools/csr27.svg"
            data-aos="fade-right"
            data-aos-delay="600"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool NowCerts "
            appCdnUrl="assets/Tools/nowcerts.svg"
            data-aos="fade-right"
            data-aos-delay="700"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool Power Broker"
            data-aos="fade-right"
            data-aos-delay="800"
            appCdnUrl="assets/Tools/powerbroker.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="900"
            class="img-fluid"
            alt="Insurance tool AgencyMatrix "
            appCdnUrl="assets/Tools/agencymatrix.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="1000"
            class="img-fluid"
            aria-multiline=" Insurance tool employeeNavigator "
            appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            aria-multiline=" Insurance tool indio "
            data-aos="fade-right"
            data-aos-delay="1100"
            appCdnUrl="assets/Tools/indio.svg"
            alt="Indio"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool Applied System"
            appCdnUrl="assets/Tools/applied.svg"
            data-aos="fade-right"
            data-aos-delay="0"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="100"
            class="img-fluid"
            alt="Insurance tool Hawksoft   "
            appCdnUrl="assets/Tools/hawksoft.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="200"
            class="img-fluid"
            alt="Insurance tool PL Rating "
            appCdnUrl="assets/Tools/pl rating.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool QQCatalyst "
            data-aos="fade-right"
            data-aos-delay="300"
            appCdnUrl="assets/Tools/catalyst.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool AMS 360 "
            appCdnUrl="assets/Tools/ams360.svg"
            data-aos="fade-right"
            data-aos-delay="400"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool EZLynx "
            appCdnUrl="assets/Tools/ezlynx.svg"
            data-aos="fade-right"
            data-aos-delay="500"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool CSR24  "
            appCdnUrl="assets/Tools/csr27.svg"
            data-aos="fade-right"
            data-aos-delay="600"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool NowCerts  "
            appCdnUrl="assets/Tools/nowcerts.svg"
            data-aos="fade-right"
            data-aos-delay="700"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            data-aos="fade-right"
            data-aos-delay="800"
            appCdnUrl="assets/Tools/powerbroker.svg"
            alt="Insurance tool Power Broker "
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="900"
            class="img-fluid"
            alt="Insurance tool AgencyMatrix "
            appCdnUrl="assets/Tools/agencymatrix.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="1000"
            class="img-fluid"
            alt="Insurance tool employeeNavigator "
            appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool indio "
            data-aos="fade-right"
            data-aos-delay="1100"
            appCdnUrl="assets/Tools/indio.svg"
            alt="Indio"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<ThinkingSection category="insurance"></ThinkingSection>

<app-know-best
  [cards]="[
    {
      description:
        'I must say that our partnership with Fusion Business Solutions (FBSPL) has been truly exceptional. What sets FBSPL apart is their remarkable ability to not only grasp our ever-evolving business needs but also their genuine curiosity to understand our culture. Their flexibility and agility have been invaluable. FBSPL’s proactive approach, coupled with their rigorous processes and scalability, has significantly enhanced our customer experiences and AI operations.',
      name: 'Chin Ma',
      designation: 'Founder & President'
    },
    {
      description:
        'We reached out to Fusion in the Fall of 2022 as we were having issues recruiting a skilled and motivated workforce locally to fill back-office roles in our organization. What started as a simple documentation validation exercise quickly grew into the team supporting more strategic review and reconciliation activities. The team has shown repeatedly that they are willing to take on more and more complicated tasks, and the leadership team is quick to rally and support their employees when things fall off track.',
      name: 'James Oickle',
      designation: 'President & CEO'
    },
    {
      description:
        'Fusion Business Solutions has been a game-changer for our company. Their responsive team, seamless onboarding, and commitment to excellence have significantly reduced our stress levels, allowing us to focus on what matters most – our clients. We\'ve experienced measurable improvements in client retention and employee satisfaction since partnering with Fusion, making them the top choice for any business seeking efficient virtual assistant services.',
      name: 'Isom Rigell M. Ed.',
      designation: 'Chief People Officer'
    }
  ]"
  [videos]="[
    {
      videoUrl: 'https://player.vimeo.com/video/969702390',
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 01 Chin Ma.jpg',
      alt: 'Listen to our client- new insurance business servicing'
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10  James Oickle.jpg',
      alt: 'Our Success Stories- new insurance business servicing '
    },
    {
      videoUrl: 'https://player.vimeo.com/video/962238538',
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 07 Stacey DiSpigno copy.jpg',
      alt: 'FBSPL Client Testimonial- new insurance business servicing '
    }
  ]"
>
</app-know-best>

<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>

    <div class="mt-3 mt-md-0">
      <div data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                What is the insurance new business setup process you follow?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                As a leading
                <a
                  class="anchor"
                  target="_blank"
                  href="/services/insurance-bpo-outsourcing"
                  >insurance outsourcing services provider</a
                >, we provide the best solutions to new businesses with a quick
                turnaround time. Here is a streamlined process that we follow.
                <li>Identifying the objects to be insured</li>
                <li>Preparing the new insurance policies</li>
                <li>Reviewing the policies for accuracy</li>
                <li>Creating policies with the terms and conditions</li>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                Why choose us for insurance new business servicing?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                If you are wondering why our new business service solutions are
                the best, here is what makes us stand out from the rest.
                <ul>
                  <li>All-in-one expertise</li>
                  <li>Availability as per your time zone</li>
                  <li>Quick turnaround time</li>
                  <li>Tailor-made solutions</li>
                  <li>99% accuracy</li>
                  <li>Scalable solutions</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                How will you ensure the accuracy of the Proposal or Quote?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our insurance veterans cross-check the quote against the quote
                request to ensure the coverage, demographic, and premium
                information, and then prepare a proposal to ensure accuracy.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                How will you collect the information for quoting?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our team exchanges the intake or questionnaire form with the
                prospect or account manager to collect the information to
                initiate the quoting.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Preferred"
                aria-expanded="false"
                aria-controls="Resources"
              >
                What is the preferred method to get the document signed for
                binding?
              </button>
            </h2>
            <div
              id="Preferred"
              class="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Upon the Account Manager's approval, we create a packet of
                proposal + Acord forms/quote application or supplemental apps +
                TRIA or surplus lines forms (depending on the product and
                carrier) and send the documents to the customer for signature
                through DocuSign.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
