<ngx-slick-carousel
  class="service_slider carousel d-none d-lg-block container-fluid"
  #slickModal="slick-carousel"
  [config]="slideConfig"
>
  @for (slides of slides; track slides) {
  <div class="container-fluid text-dark" ngxSlickItem>
    <div class="container gradientBanner">
      <img
        loading="lazy"
        src="{{ slides.img }}"
        alt="{{ slides.alt }}"
        class="desktop-banner lazyload"
      />
      <div class="card-img-overlay overlay-text bannerText">
        <div class="main_bannar my-4 ms-3" data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
              <li class="breadcrumb-item">
                <a routerLink="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item active">
                <a aria-current="page">Accounting & Bookkeeping</a>
              </li>
            </ol>
          </nav>
          <a href="{{ slides.Link }}" class="CTA-Zindex anchorCTA">
            @if (!slides.banner) {
            <h3 class="text-primary font-34-19 my-auto clickable-heading">
              {{ slides.Title }}
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
            } @if (slides.banner) {
            <h1 class="text-primary font-34-19 my-auto">
              {{ slides.Title }}
            </h1>
            }
          </a>
          <p class="card-text font-20-16 mt-4">{{ slides.Description }}</p>
          @if (slides.banner) {
          <a [routerLink]="slides.Link" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              {{ slides.accounting }}
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
          }
        </div>
      </div>
    </div>
  </div>
  }
</ngx-slick-carousel>
<!-- Mobile View -->
<ngx-slick-carousel
  class="container carousel d-block d-lg-none pb-5 mb-5 blog_slider"
  #slickModal="slick-carousel"
  [config]="slideConfig"
>
  @for (slidesmob of slides; track slidesmob) {
  <div class="text-dark slide" ngxSlickItem>
    <div class="gradientBannerMob d-flex justify-content-center">
      <img
        loading="lazy"
        src="{{ slidesmob.img_mob }}"
        alt="{{ slidesmob.alt }}"
        class="card-img-mob lazyload"
      />
      <div class="overlay-text-Mob bannerTextMob">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
            <li class="breadcrumb-item">
              <a routerLink="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item active">
              <a aria-current="page">Accounting & Bookkeeping</a>
            </li>
          </ol>
        </nav>
        <a href="{{ slidesmob.Link }}" class="CTA-Zindex anchorCTA">
          @if (!slidesmob.banner) {
          <h3 class="text-primary font-34-19 my-auto clickable-heading">
            {{ slidesmob.Title }}
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
          } @if (slidesmob.banner) {
          <h1 class="text-primary font-34-19 my-auto">
            {{ slidesmob.Title }}
          </h1>
          }
        </a>
        <p class="card-text font-20-16">{{ slidesmob.Description }}</p>
        @if (slidesmob.banner) {
        <a [routerLink]="slidesmob.Link" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            {{ slidesmob.accounting }}
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
        }
      </div>
    </div>
  </div>
  }
</ngx-slick-carousel>

<section class="bg-primary text-white py-6">
  <div class="container px-4">
    <h4 class="font-48-30 mb-3 text-white" data-aos="fade-up">
      Current accounting & bookkeeping challenges
    </h4>
    <div class="row">
      <div class="row text-center d-none d-md-flex">
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="0">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">20%</p>
            <p class="">
              CFOs consider budgeting and forecasting among their top challenges
              in financial management.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="200">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">61%</p>
            <p class="">
              Small businesses struggle with cash flow management, which leads
              to challenges in paying bills.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="400">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">54%</p>
            <p class="">
              Survey respondents say the reporting involves huge amounts of
              manual checking on every change.
            </p>
          </div>
        </div>
        <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="600">
          <div class="text-white text-start">
            <p class="font-48-30 fw-bolder mb-1">60%</p>
            <p class="">
              Respondents say they spend too much time cleaning and manipulating
              accounting and bookkeeping data.
            </p>
          </div>
        </div>
      </div>
      <div class="d-md-none">
        <div class="text-white text-start" data-aos="fade-right">
          <p class="font-48-30 fw-bolder mb-1">20%</p>
          <p class="">
            CFOs consider budgeting and forecasting among their top challenges
            in financial management.
          </p>
        </div>
        <div class="text-white text-start" data-aos="fade-right">
          <p class="font-48-30 fw-bolder mb-1">61%</p>
          <p class="">
            Small businesses struggle with cash flow management, which leads to
            challenges in paying bills.
          </p>
        </div>
        <div class="text-white text-start" data-aos="fade-right">
          <p class="font-48-30 fw-bolder mb-1">54%</p>
          <p class="">
            Survey respondents say the reporting involves huge amounts of manual
            checking on every change.
          </p>
        </div>
        <div class="text-white text-start" data-aos="fade-right">
          <p class="font-48-30 fw-bolder mb-1">60%</p>
          <p class="">
            Respondents say they spend too much time cleaning and manipulating
            accounting and bookkeeping data.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="py-5 container">
  <h2 class="text-primary font-48-30 text-center">
    Stop worrying about your books and focus on growth
  </h2>
  <div class="row mt-5">
    <div class="col-12 col-lg-3 my-3 my-lg-0">
      <div class="challengediv">
        <img
          src="https://d1585q1wairh36.cloudfront.net/assets/newImages/Guaranteed_data.png"
          alt=""
        />
        <h4 class="text-primary font-28-20">Data security</h4>
        <p class="fs-18">
          SOC-2 and ISO-certified, we prioritize your data’s safety with robust
          security and compliance measures that keep your information protected.
        </p>
      </div>
    </div>

    <div class="col-12 col-lg-3 my-3 my-lg-0">
      <div class="challengediv">
        <img
          src="https://d1585q1wairh36.cloudfront.net/assets/newImages/hidden_training.png"
          alt=""
        />
        <h4 class="text-primary font-28-20">Time savings</h4>
        <p class="fs-18">
          Free up your team’s time with professional support that needs minimal
          oversight and consistently delivers high-quality results.
        </p>
      </div>
    </div>

    <div class="col-12 col-lg-3 my-3 my-lg-0">
      <div class="challengediv">
        <img
          src="https://d1585q1wairh36.cloudfront.net/assets/newImages/Pre-deployment.png"
          alt=""
        />
        <h4 class="text-primary font-28-20">Uninterrupted workflow</h4>
        <p class="fs-18">
          Backup teams, trained professionals, and consistent processes ensure
          your operations run smoothly, with zero disruptions.
        </p>
      </div>
    </div>

    <div class="col-12 col-lg-3 my-3 my-lg-0">
      <div class="challengediv">
        <img
          src="https://d1585q1wairh36.cloudfront.net/assets/newImages/Customized_workflow.png"
          alt=""
        />
        <h4 class="text-primary font-28-20">Cost efficiency</h4>
        <p class="fs-18">
          Avoid hidden fees and training costs. With FBSPL, what you see is what
          you get—a reliable partner who’s got your back.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="container py-2 pt-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Experience precision with accounting & bookkeeping services
  </h2>
  <div class="d-none d-lg-block">
    <div class="row">
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <a routerLink="/services/accounts-payable-management">
          <div class="card bordered serviceCards">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Account Payable
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Simplify every payment—vendor bill processing, reconciliations,
              and accurate record-keeping that saves time...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/Accounting Main Page/Div Section/Account_Payable.png"
              class="serviceImg"
            />
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <a routerLink="/services/accounts-receivable-management">
          <div class="card bordered serviceCards">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Account Receivable
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Stay on top of receivables with services that streamline
              invoicing, cash flow tracking, and monthly reconciliations...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/Accounting Main Page/Div Section/Account_Receivable.png"
              class="serviceImg"
            />
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <a routerLink="/services/payroll-processing-services">
          <div class="card bordered serviceCards">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Payroll Processing
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              On-time, accurate payrolls processed through systems like ADP and
              Gusto, customized to your requirements... <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/Accounting Main Page/Div Section/Payroll_Processing.png"
              class="serviceImg"
            />
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <a routerLink="/services/accounting-and-financial-reporting-services">
          <div class="card bordered serviceCards">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Financial Reporting
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Dive into meaningful reports that provide more than numbers—they
              give you insights for your next move... <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/Accounting Main Page/Div Section/Financial_Reporting.png"
              class="serviceImg"
            />
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <a routerLink="/services/accounts-reconciliation-services">
          <div class="card bordered serviceCards">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                Account Reconciliation
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Precision in every reconciliation—whether it’s credit card, bank
              statement, or petty cash, we ensure accurate...
              <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/Accounting Main Page/Div Section/Account_Reconciliation.png"
              class="serviceImg"
            />
          </div>
        </a>
      </div>
      <div class="col-sm-12 col-lg-4 col-md-6 py-3" data-aos="zoom-in">
        <a routerLink="/services/general-ledger-accounting-services">
          <div class="card bordered serviceCards">
            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
              <h3 class="text-white serviceHeading my-auto clickable-heading">
                General Ledger Accounting
                <img
                  appCdnUrl="assets/Icon/white-vector.svg"
                  class="whiteCTABtn"
                />
              </h3>
            </span>
            <p class="text-black font-20-16 ms-2">
              Take strategic decisions, meet compliance and generate accurate
              reports with our outsourced general ledger... <br />
              <span class="text-primary cursor-pointer">Read more</span>
            </p>
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/Accounting Main Page/Div Section/General_Ledger_Accounting.png"
              class="serviceImg"
            />
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<!-- Mobile View -->
<div class="container d-block d-lg-none mb-4">
  <div class="row">
    <div class="col-12 mt-0 mt-sm-4">
      <div class="accordion" id="AccountPage">
        <div class="policy-processing" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Account_Payable"
            aria-expanded="true"
            aria-controls="Account_Payable"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24">Account Payable</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/accounts-payable-management"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Account_Payable"
            class="accordion-collapse collapse"
            aria-labelledby="headingOne"
            data-bs-parent="#AccountPage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black fs-16">
                Simplify every payment—vendor bill processing, reconciliations,
                and accurate record-keeping that saves time and reduces
                errors...
                <br />
                <a
                  routerLink="/services/accounts-payable-management"
                  class="text-primary"
                  >Read more
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Account_Receivable"
            aria-expanded="true"
            aria-controls="Account_Receivable"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24">Account Receivable</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/accounts-receivable-management"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Account_Receivable"
            class="accordion-collapse collapse"
            aria-labelledby="headingtwo"
            data-bs-parent="#AccountPage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black fs-16">
                Stay on top of receivables with services that streamline
                invoicing, cash flow tracking, and monthly reconciliations...
                <br />
                <a
                  routerLink="/services/accounts-receivable-management"
                  class="text-primary"
                  >Read more
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Financial"
            aria-expanded="true"
            aria-controls="Financial"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24">Financial Reporting</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/accounting-and-financial-reporting-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Financial"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#AccountPage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black fs-16">
                Dive into meaningful reports that provide more than numbers—they
                give you insights for your next move...
                <br />
                <a
                  routerLink="/services/accounting-and-financial-reporting-services"
                  class="text-primary"
                  >Read more
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Payroll"
            aria-expanded="true"
            aria-controls="Payroll"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24">Payroll Processing</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/payroll-processing-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Payroll"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#AccountPage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black fs-16">
                On-time, accurate payrolls processed through systems like ADP
                and Gusto, customized to your requirements...
                <br />
                <a
                  routerLink="/services/payroll-processing-services"
                  class="text-primary"
                  >Read more
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Account"
            aria-expanded="true"
            aria-controls="Account"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24">Account Reconciliation</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/accounts-reconciliation-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Account"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#AccountPage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black fs-16">
                Precision in every reconciliation—whether it’s credit card, bank
                statement, or petty cash, we ensure accurate, up-to-date
                records...
                <br />
                <a
                  routerLink="/services/accounts-reconciliation-services"
                  class="text-primary"
                  >Read more
                </a>
              </p>
            </div>
          </div>
        </div>
        <div class="policy-processing mt-4" data-aos="zoom-in">
          <div
            data-bs-toggle="collapse"
            data-bs-target="#Customer"
            aria-expanded="true"
            aria-controls="Customer"
            id="headingOne"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24">General Ledger Accounting</h3>
            <button class="blueCTA service_btn">
              <a
                routerLink="/services/general-ledger-accounting-services"
                class="CTA-Zindex anchorCTA mb-3 p-3"
              >
              </a>
            </button>
          </div>
          <div
            id="Customer"
            class="accordion-collapse collapse"
            aria-labelledby="headingfour"
            data-bs-parent="#AccountPage"
          >
            <div class="accordion-body pt-0">
              <p class="text-black fs-16">
                Take strategic decisions, meet compliance and generate accurate
                reports with our exceptional outsourced general ledger
                accounting services...
                <br />
                <a
                  routerLink="/services/general-ledger-accounting-services"
                  class="text-primary"
                  >Read more
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-ourAssets></app-ourAssets>

<!-- Know best -->
<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
      alt: 'Listen to our client '
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt: 'Our Success Stories '
    }
  ]"
>
</app-know-best>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4 text-center" data-aos="fade-up">
    Tech-driven accounting & bookkeeping solutions
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img
          loading="lazy"
          class="img-fluid"
          alt="Accounting & Bookkeeping tool Zoho  "
          appCdnUrl="assets/Tools/zoho.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img
          loading="lazy"
          class="img-fluid"
          alt="Accounting & Bookkeeping tool QuickBooks "
          appCdnUrl="assets/Tools/quickbooks.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img
          loading="lazy"
          class="img-fluid"
          alt="Accounting & Bookkeeping tool NetSuite "
          appCdnUrl="assets/Tools/netsuite.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img
          loading="lazy"
          class="img-fluid"
          alt="Accounting & Bookkeeping tool odoo "
          appCdnUrl="assets/Tools/odoo.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img
          loading="lazy"
          class="img-fluid"
          alt="Accounting & Bookkeeping tool xero "
          appCdnUrl="assets/Tools/xero.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            loading="lazy"
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            loading="lazy"
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            loading="lazy"
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            loading="lazy"
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            loading="lazy"
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            loading="lazy"
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            loading="lazy"
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            loading="lazy"
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            loading="lazy"
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            loading="lazy"
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<ThinkingSection category="accountingandbookkeeping"></ThinkingSection>
<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>
    <div class="mt-3 mt-md-0" data-aos="fade-left">
      <div class="accordion" id="accordionFAQ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button font-28-20"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Industries"
              aria-expanded="true"
              aria-controls="Industries"
            >
              What are the benefits of our accounting & bookkeeping outsourcing
              services?
            </button>
          </h2>
          <div
            id="Industries"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              If you are wondering whether we will be the best fit for your
              accounting and bookkeeping needs, here’s what you should know.
              While choosing us, you can get ample benefits, including -
              <ul class="pt-3">
                <li>Multi-currency accounting</li>
                <li>Hourly services</li>
                <li>Centralized accounting</li>
                <li>Intercompany reconciliations</li>
                <li>Tailor-made solutions</li>
                <li>All-in-one expertise</li>
                <li>99% accuracy </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Services"
              aria-expanded="false"
              aria-controls="Services"
            >
              What are the major tools or software you have worked on?
            </button>
          </h2>
          <div
            id="Services"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Although our accounting and bookkeeping experts are well-versed in
              working on any tool or software, here is a set of tools we have
              worked on so far -
              <ul class="pt-3">
                <li>
                  <a
                    class="anchor"
                    target="_blank"
                    href="/blogs/how-to-use-quickbooks-for-your-business-and-how-is-it-different-from-other-accounting-software"
                    >QuickBooks</a
                  >
                </li>
                <li>Zoho</li>
                <li>Oddo</li>
                <li>Sage</li>
                <li>NetSuite</li>
                <li>Xero</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#About"
              aria-expanded="false"
              aria-controls="About"
            >
              What are the industries where you can avail our outsourcing
              accounting and bookkeeping services?
            </button>
          </h2>
          <div
            id="About"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Here are the major industries to whom we cater accounting and
              bookkeeping services.
              <ul class="pt-3">
                <li>Consulting</li>
                <li>Healthcare</li>
                <li>Hospitality</li>
                <li>
                  <a
                    class="anchor"
                    target="_blank"
                    href="/services/insurance-bpo-outsourcing"
                    >Insurance</a
                  >
                </li>
                <li>
                  <a
                    class="anchor"
                    target="_blank"
                    href="/services/e-commerce-outsourcing-services"
                    >E-commerce
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Resources"
              aria-expanded="false"
              aria-controls="Resources"
            >
              Will outsourcing accounting and bookkeeping help cut operational
              costs?
            </button>
          </h2>
          <div
            id="Resources"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Yes, you can save huge costs with our outsourced bookkeeping and
              accounting solutions. We hold years of expertise, and our
              professionals can ensure 99% accuracy with tailor-made solutions.
              Discuss your project requirements with our experts and let us
              assist you with the best and most reasonable outsourcing services.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#differentiators"
              aria-expanded="false"
              aria-controls="differentiators"
            >
              What are your key differentiators?
            </button>
          </h2>
          <div
            id="differentiators"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              <ul class="pt-3">
                <li>Availability As Per Your Time Zone</li>
                <li>All-in-One Expertise</li>
                <li>Dedicated Staff</li>
                <li>99% Accuracy</li>
                <li>Tailor-Made Solutions</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
