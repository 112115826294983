import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../../../components/ThinkingSection/ThinkingSection.component';

@Component({
  selector: 'app-image-annotation',
  standalone: true,
  templateUrl: './image-annotation.component.html',
  styleUrl: './image-annotation.component.css',
  imports: [
    FaqComponent,
    KnowBestComponent,
    CdnUrlDirective,
    ourAssetsComponent,
    ThinkingSection,
  ],
})
export class ImageAnnotation implements OnInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Image Annotation Outsourcing for AI & ML Accuracy | FBSPL',
      description:
        'Outsource image annotation services to convert raw images into AI-ready datasets with professional services, ensuring accuracy for AI and ML models.',
      keywords: 'image annotation outsourcing',
      ogImage: '/Sub-service-Pages/policy 1 1.png',
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "Do you offer data annotation services for video as well?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes, we also offer video annotation services, including frame-by-frame video labeling and object tracking for dynamic events. Our data annotation solutions are especially useful for autonomous driving, surveillance, and sports analysis applications."
      }
    },
    {
      "@type": "Question",
      "name": "How do you ensure consistency across large datasets?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We employ stringent quality assurance procedures to ensure accuracy and consistency throughout all annotations. Our comprehensive image annotation process includes detailed guidelines, multi-level reviews, advanced annotation tools, and expert validation to deliver precise results."
      }
    },
    {
      "@type": "Question",
      "name": "What is the turnaround time for a typical image annotation project?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The size and complexity of the project determine the turnaround time. After examining your specifications, we will provide an approximate time frame and strive to meet your deadlines. Still, with FBSPL, you can expect 30% faster turnaround time."
      }
    },
    {
      "@type": "Question",
      "name": "What are the different types of image annotation services FBSPL offers?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our services aren’t restricted to a specific type of image annotation. From 2D bounding boxes and 3D cuboids to semantic segmentation, key point annotation, and skeletal annotation, we can annotate all types of images. Feel free to share your project requirements for image annotation and leave the rest to our experts."
      }
    },
    {
      "@type": "Question",
      "name": "How can I get started with image annotation services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Getting started with our image annotation services are just a few steps process! Visit our contact us page, fill in the form, and one of our data annotation experts will contact you. They will gather your project requirements and offer customized solutions accordingly."
      }
    }
  ]
}

`;
        this.renderer.appendChild(this.document.head, script);
      }
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `{
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [
    {
      "@type": "ListItem",
      "position": 1,
      "name": "Home",
      "item": "https://www.fbspl.com/"
    },
    {
      "@type": "ListItem",
      "position": 2,
      "name": "Services",
      "item": "https://www.fbspl.com/bpm-and-bpo-services"
    },
    {
      "@type": "ListItem",
      "position": 3,
      "name": "Data Annotation",
      "item": "https://www.fbspl.com/services/data-annotation-services"
    },
    {
      "@type": "ListItem",
      "position": 4,
      "name": "Image Annotation",
      "item": "https://www.fbspl.com/services/image-annotation-outsourcing-services"
    }
  ]
}`;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }
}
