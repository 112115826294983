<!-- <div class="bg-white bordered py-3 my-4">
  <div class="row text-center">
    @for (label of labels; track label; let i = $index) {
    <div class="col-6 col-md-3">
      <span class="fw-500 text-primary font-34-19"
        >{{ counters[i] }}</span
      >
      <div class="fw-bold font-24-16">{{ label }}</div>
    </div>
    }
  </div>
</div> -->
<div class="bg-white bordered py-3 my-4">
  <div class="row text-center">
    @for (counter of counters; track counter; let i = $index) {
    <div class="col-6 col-md-3">
      <span class="fw-500 text-primary font-34-19">
        {{ counter.current }}{{ counter.target > 100 ? '+' : '' }}
      </span>
      <div class="fw-bold font-24-16">{{ counter.label }}</div>
    </div>
  }
  </div>
</div>

