import { Component ,OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../../../components/ThinkingSection/ThinkingSection.component';

@Component({
    selector: 'app-reconciliation',
    standalone: true,
    templateUrl: './reconciliation.component.html',
    styleUrl: './reconciliation.component.css',
    imports: [
    FaqComponent, KnowBestComponent,
    ThinkingSection, CdnUrlDirective,
    ourAssetsComponent
]
})
export class ReconciliationComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsourced Account Reconciliation Solutions | FBSPL',
      description:
        'FBSPL provides accounts reconciliation services: bank statement, credit card reconciliation, ledger management, and more. Simplify your financial processes today.',
      keywords: 'accounts reconciliation services, financial processes ',
      ogImage: '/accoutning_Account_Reconciliation/main-banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "What are the different types of reconciliation services you offer?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Every business requires different types of reconciliation services at one point or the other. However, the major reconciliation services we offer are - 

<ul> 

<li>Credit card reconciliation</li> 

<li>Petty cash reconciliation</li> 

<li>Account payable reconciliation</li> 

<li>Bank account reconciliation</li> 

<li>Customer statement reconciliation</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the major purpose behind account reconciliation services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "While outsourcing account reconciliation, businesses can avoid errors, fraud, and discrepancies that might cause trouble during the taxation period. The experts ensure that your financial information stays accurate and thus help you make the right strategic decisions." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Do account reconciliation services save time for my businesses?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You can save a lot of time by outsourcing account reconciliation services to our professionals. You can focus on your core business activities while our experts handle account reconciliation and ensure 100% accuracy and 0% discrepancy." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are the different types of accounts that can be reconciled?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "With our account reconciliation services, you can get your accounts reconciled for different accounts, including vendors, credit cards, bank statements, customer accounts, and more. To know more about account reconciliation solutions, contact our experts right away!" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I avail your account reconciliation services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": " We always put in our best efforts to deliver the industry-best outsourcing services to all-scale businesses. With us, businesses can experience amazing perks, including - <ul> 

<li>Availability As Per Your Time Zone</li> 

<li>All-in One Expertise</li> 

<li>Dedicated Staff</li> 

<li>99% Accuracy</li> 

<li>Tailor-Made Solutions</li> 

<li>Quick turnaround time</li></ul>" 

      } 

    } 

  ] 

} `;
      this.renderer.appendChild(this.document.head, script);
    }
    // Check and add BreadcrumbList script
  if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
    const breadcrumbScript = this.renderer.createElement('script');
    breadcrumbScript.type = 'application/ld+json';
    breadcrumbScript.setAttribute('data-script', 'breadcrumb');
    breadcrumbScript.text = `
    { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Accounting & Bookkeeping", 

      "item": "https://www.fbspl.com/services/accounting-bookkeeping-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Account Reconciliation Services", 

      "item": "https://www.fbspl.com/services/accounts-reconciliation-services" 

    } 

  ] 

} `;
    this.renderer.appendChild(this.document.head, breadcrumbScript);
  }
  }
}
}
