@if (link) {
<a [routerLink]="link">
  <button
    class="submitBtn font-20 px-5 py-2"
    (mouseenter)="changeImageOnHover()"
    (mouseleave)="resetImage()"
  >
    {{ text }}
    <span class="ms-3">
      <img [src]="imageSrc" style="width: 17px; transform-origin: top" />
    </span>
  </button>
</a>
} @if (!link) {
<button
  class="submitBtn font-20 px-5 py-2"
  (mouseenter)="changeImageOnHover()"
  (mouseleave)="resetImage()"
>
  {{ text }}
  <span class="ms-3">
    <img [src]="imageSrc" style="width: 17px; transform-origin: top" />
  </span>
</button>
}
<!-- <img
  src="https://d1585q1wairh36.cloudfront.net/assets/Icon/white-vector.svg"
  style="width: 17px; transform-origin: top"
/> -->
