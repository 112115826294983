<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/accoutning Account Reconciliation/main-banner (1).jpg"
      class="card-img-desktop desktop-banner"
      alt="Account Reconciliation Services "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="/services/accounting-bookkeeping-services"
                  >Accounting & Bookkeeping</a
                >
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                <a>Account Reconciliation Services </a>
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Outsource Accounts Reconciliation Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Make your financial statements 100% accurate and maintain an
            error-free book of accounts. Outsource account reconciliation
            services and keep track of financial transactions and
            reconciliation. While you take care of the core business activities,
            let us do the reconciliation for you.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Try account reconciliation services
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/accoutning_Account_Reconciliation/Mobile-banner.jpg"
      class="card-img-mob"
      alt="Account Reconciliation Services "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item" aria-current="page">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item" aria-current="page">
              <a href="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping</a
              >
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              <a>Account Reconciliation Services </a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Outsource Accounts Reconciliation Services
        </h1>
        <p class="card-text font-20-16">
          Make your financial statements 100% accurate and maintain an
          error-free book of accounts. Outsource account reconciliation services
          and keep track of financial transactions and reconciliation. While you
          take care of the core business activities, let us do the
          reconciliation for you.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Try account reconciliation services
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid pt-5" id="account reconciliation service">
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Accounts reconciliation services we offer
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#CreditCardReconciliation">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Credit Card Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Is managing credit card transactions becoming a challenge?
                You’re at the right place. Our credit card reconciliation
                service ensures that every transaction...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#FullPartialAccountReconciliation"
          >
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Full and Partial Account Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Whether you want to conduct a thorough audit or want to focus on
                specific transactions, we’ve got your back. Our full and partial
                accounts reconciliation services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#VendorInvoiceReconciliation"
          >
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Vendor Invoice Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Are you struggling with vendor invoice reconciliation? Get
                expert assistance from us! Our team carefully compares invoices
                with corresponding payments....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#BankStatementReconciliation"
          >
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Bank Statement Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Achieve financial accuracy with advanced bank statement
                reconciliation services. We integrate cutting-edge tools to
                cross-check your internal records...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PettyCashReconciliation">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Petty Cash Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Ensure all your cash funds are spent according to internal
                guidelines and policies. Our petty cash reconciliation service
                ensures every expense...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <div
              class="card bordered serviceCards Policy_Endorsements"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Customer Statement Reconciliation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accuracy in customer statements is the key to building business
                trust and relationships. Our team carefully identifies and
                resolves discrepancies...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#CreditCardReconciliation"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Credit Card Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#FullPartialAccountReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Full and Partial Account Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#VendorInvoiceReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Vendor Invoice Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#BankStatementReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Bank Statement Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PettyCashReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Petty Cash Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Customer Statement Reconciliation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="CreditCardReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="CreditCardReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Credit Card Reconciliation</h3>
            <p class="text-dark font-20-16">
              Is managing credit card transactions becoming a challenge? You’re
              at the right place. Our credit card reconciliation service ensures
              that every transaction is tracked and reconciled seamlessly. Our
              team identifies discrepancies, resolves issues on time, and keeps
              all financial records spotless. With us handling the
              reconciliation, you can invest your resources in business growth.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Identifying the accounts for reconciliation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Gathering all the necessary documents
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Comparing credit card statements with corresponding entries
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Investigating discrepancies and finding solutions
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Reviewing reconciliation and documentation
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Account Reconciliation/Group 204.webp"
              alt="Credit Card Reconciliation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="FullPartialAccountReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="FullPartialAccountReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">
              Full and Partial Account Reconciliation
            </h3>
            <p class="text-dark font-20-16">
              Whether you want to conduct a thorough audit or want to focus on
              specific transactions, we’ve got your back. Our full and partial
              accounts reconciliation services are customized to fit your
              business requirements. To make sure every penny is tracked down
              and every inconsistency is resolved, our professional follows a
              methodical approach. With us, you can obtain well-maintained
              financial documents.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Identifying the accounts for reconciliation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Gathering documents for full or partial reconciliation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Comparing account statements with corresponding entries
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Investigating discrepancies and finding solutions
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Reviewing reconciliation and documentation
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Account Reconciliation/Group 205.webp"
              alt="Full and Partial Account Reconciliation Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="VendorInvoiceReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="VendorInvoiceReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Vendor Invoice Reconciliation</h3>
            <p class="text-dark font-20-16">
              Are you struggling with vendor invoice reconciliation? Get expert
              assistance from us! Our team carefully compares invoices with
              corresponding payments, detects inconsistency, and ensures the
              accuracy of financial records. With us, prevent overpayments,
              automate your accounting processes, and build stronger
              relationships with your vendors, resulting in precise accounts
              reconciliation services.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Setting an invoice reconciliation schedule
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting all the necessary documents
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Comparing vendor statements with company accounts
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Authorizing the purchase transactions
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Eliminating discrepancies and processing reconciliation
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Account Reconciliation/Group 206.webp"
              alt="Vendor Invoice Reconciliation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="BankStatementReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="BankStatementReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Bank Statement Reconciliation</h3>
            <p class="text-dark font-20-16">
              Achieve financial accuracy with advanced bank statement
              reconciliation services. We integrate cutting-edge tools to
              cross-check your internal records against your bank’s statements
              and identify discrepancies without a miss. By reconciling your
              accounts in real-time, we aim to resolve all the data
              inconsistencies, providing a clear and accurate financial picture.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Collecting all the bank statements
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Aggregating business records
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Matching deposits and withdrawals
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Verifying the income and expenses
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Adjusting balances and performing a final check
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Account Reconciliation/Group 207.webp"
              alt="Bank Statement Reconciliation Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="PettyCashReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PettyCashReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Petty Cash Reconciliation</h3>
            <p class="text-dark font-20-16">
              Ensure all your cash funds are spent according to internal
              guidelines and policies. Our petty cash reconciliation service
              ensures every expense is well-documented and aligns with your
              internal policies. Our experts carefully track and verify every
              transaction, ensuring your records stay compliant. With us on your
              side, rest assured that your petty cash management is under
              control.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Reviewing the company’s petty cash policy
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Obtaining the reconciliation form
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Calculating the amount of withdrawn cash
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Adding up each voucher in the petty cash fund
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Investigating the differences
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Account Reconciliation/Group 208.webp"
              alt="Petty Cash Reconciliation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="AccountingSystemIntegration"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="AccountingSystemIntegrationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">
              Customer Statement Reconciliation
            </h3>
            <p class="text-dark font-20-16">
              Accuracy in customer statements is the key to building business
              trust and relationships. Our team carefully identifies and
              resolves discrepancies between your records and customers’
              statements. We assist you in preventing disputes by making sure
              your records correspond with the customer statements. Feel free to
              discuss your account reconciliation requirements with us.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Obtaining customer statements
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Matching transactions with business records
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Maintaining accurate records for payroll compliance
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Identifying the discrepancies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Adjusting business records
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Verifying adjusted balances
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Account Reconciliation/Group 209.webp"
              alt="Customer Statement Reconciliation Service"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
      alt: 'Listen to our client '
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt: 'Our Success Stories '
    }
  ]"
>
</app-know-best>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven accounts reconciliation solutions
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool Zoho  "
          appCdnUrl="assets/Tools/zoho.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool QuickBooks "
          appCdnUrl="assets/Tools/quickbooks.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool NetSuite "
          appCdnUrl="assets/Tools/netsuite.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool odoo "
          appCdnUrl="assets/Tools/odoo.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool xero "
          appCdnUrl="assets/Tools/xero.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<ThinkingSection category="accountingandbookkeeping"></ThinkingSection>
<!-- Know best -->

<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>
    <div class="mt-3 mt-md-0" data-aos="fade-left">
      <div class="accordion" id="accordionFAQ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button font-28-20"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Industries"
              aria-expanded="true"
              aria-controls="Industries"
            >
              What are the different types of reconciliation services you offer?
            </button>
          </h2>
          <div
            id="Industries"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Every business requires different types of reconciliation services
              at one point or the other. However, the major reconciliation
              services we offer are -
              <ul class="pt-3">
                <li>Credit card reconciliation</li>
                <li>Petty cash reconciliation</li>
                <li>Account payable reconciliation</li>
                <li>Bank account reconciliation</li>
                <li>Customer statement reconciliation</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Services"
              aria-expanded="false"
              aria-controls="Services"
            >
              What is the major purpose behind account reconciliation services?
            </button>
          </h2>
          <div
            id="Services"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              While outsourcing account reconciliation, businesses can avoid
              errors, fraud, and discrepancies that might cause trouble during
              the taxation period. The experts ensure that your financial
              information stays accurate and thus help you make the right
              strategic decisions.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#About"
              aria-expanded="false"
              aria-controls="About"
            >
              Do account reconciliation services save time for my businesses?
            </button>
          </h2>
          <div
            id="About"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              You can save a lot of time by outsourcing account reconciliation
              services to our professionals. You can focus on your core business
              activities while our experts handle account reconciliation and
              ensure 100% accuracy and 0% discrepancy.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Resources"
              aria-expanded="false"
              aria-controls="Resources"
            >
              What are the different types of accounts that can be reconciled?
            </button>
          </h2>
          <div
            id="Resources"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              With our account reconciliation services, you can get your
              accounts reconciled for different accounts, including vendors,
              credit cards, bank statements, customer accounts, and more. To
              know more about account reconciliation solutions, contact our
              experts right away!
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#differentiators"
              aria-expanded="false"
              aria-controls="differentiators"
            >
              Why should I avail your account reconciliation services?
            </button>
          </h2>
          <div
            id="differentiators"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              We always put in our best efforts to deliver the industry-best
              outsourcing services to all-scale businesses. With us, businesses
              can experience amazing perks, including -
              <ul class="pt-3">
                <li>Availability As Per Client Time Zone</li>
                <li>All-in-one Expertise</li>
                <li>Dedicated Staff</li>
                <li>99% Accuracy</li>
                <li>Tailor-Made Solutions</li>
                <li>Quick turnaround time</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
