<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/accoutning Payroll Processing/payroll main-banner.jpg"
      class="card-img-desktop desktop-banner"
      alt="Financial Reporting Services "
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/accounting-bookkeeping-services"
                  >Accounting & Bookkeeping</a
                >
              </li>
              <li class="breadcrumb-item active">
                <a aria-current="page">Payroll Processing</a>
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Accurate Payroll Processing Outsourcing Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Leave payroll complexities behind with an outsourcing partner
            committed to your business growth. Our payroll processing services
            team seamlessly manages complex deductions and benefits
            administration, ensuring your team is paid accurately and on time.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Optimize your payroll
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      appCdnUrl="assets/accoutning Payroll Processing/payroll Mobile-banner.jpg"
      class="card-img-mob"
      alt="Financial Reporting Services "
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping</a
              >
            </li>
            <li class="breadcrumb-item active">
              <a aria-current="page">Payroll Processing</a>
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Accurate Payroll Processing Outsourcing Services
        </h1>
        <p class="card-text font-20-16">
          Leave payroll complexities behind with an outsourcing partner
          committed to your business growth. Our payroll processing services
          team seamlessly manages complex deductions and benefits
          administration, ensuring your team is paid accurately and on time.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Optimize your payroll
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid pt-5">
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Payroll processing services we offer
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PayrollCalculation">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Payroll Calculation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurate payroll calculation can be a challenge for many
                businesses. Our payroll calculation services ensure that
                employees' compensation...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#BenefitAdministration">
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Benefit Administration
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Do you find managing employees' benefits a daunting task? Not
                anymore! Our accounting and bookkeeping experts handle
                everything from health ...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PayrollRecordKeeping">
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Payroll Record Keeping
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Error-free record-keeping might feel overwhelming, but with
                experienced professional support, it doesn’t have to be. With
                our payroll processing services....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PayrollReporting">
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Payroll Reporting
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurate payroll reporting is crucial for maintaining business
                transparency and compliance. With our comprehensive payroll
                reporting services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#Compliance">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Compliance
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Ensuring compliance with all the latest rules and regulations
                feels like solving a puzzle without all the pieces. But, with
                our outsourcing payroll processing services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <div
              class="card bordered serviceCards Policy_Endorsements"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Accounting System Integration
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Is bulk data transfer or disrupted payroll efficiency slowing
                your business down? Our accounting system integration solutions
                can be your savior...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#PayrollCalculation"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Payroll Calculation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#BenefitAdministration"
          >
            <h3 class="CTAs text-black fs-24 me-3">Benefit Administration</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PayrollRecordKeeping"
          >
            <h3 class="CTAs text-black fs-24 me-3">Payroll Record Keeping</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PayrollReporting"
          >
            <h3 class="CTAs text-black fs-24 me-3">Payroll Reporting</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#Compliance"
          >
            <h3 class="CTAs text-black fs-24 me-3">Compliance</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              Accounting System Integration
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="PayrollCalculation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PayrollCalculationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Payroll Calculation</h3>
            <p class="text-dark font-20-16">
              Accurate payroll calculation can be a challenge for many
              businesses. Our payroll calculation services ensure that
              employees' compensation is calculated correctly. Be it hourly
              wages, salaries, commissions, or overtime, we take care of it all.
              Let us take the complexity out of payroll processing, delivering
              precise and timely results every time.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Determining company’s pay period
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Preparing payroll sheet for employees
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Calculating each employee’s working hours
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Recording payroll expenses
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Finalizing payroll calculation sheet
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 222.webp"
              alt="Payroll Calculation Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="BenefitAdministration"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="BenefitAdministrationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Benefit Administration</h3>
            <p class="text-dark font-20-16">
              Do you find managing employees' benefits a daunting task? Not
              anymore! Our accounting and bookkeeping experts handle everything
              from health insurance and retirement plans to paid time off. With
              our payroll processing outsourcing services, you can ensure smooth
              benefit administration. Let us handle the paperwork, compliance,
              and communication so you don't have to.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Evaluating all the benefits programs
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Creating employee benefits program
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Managing all the employee benefits
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Enrolling employees in the benefits program
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Ensuring compliance with benefits administration
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 223.webp"
              alt="Benefit Administration Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="PayrollRecordKeeping"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PayrollRecordKeepingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Payroll Record Keeping</h3>
            <p class="text-dark font-20-16">
              Error-free record-keeping might feel overwhelming, but with
              experienced professional support, it doesn’t have to be. With our
              payroll processing services, get detailed records of all payroll
              transactions in a go. Leave the payroll records management to us
              while you can work on your business growth, knowing your data is
              accurate and fully secure. From tracking employee activities to
              maintaining detailed records, we are pro at it.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Tracking employee activities for payroll
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Accurately calculating employee salaries using software
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Disbursing the salaries to each employee
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Keeping a detailed record of employee payrolls
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 224.webp"
              alt="Payroll Record Keeping   Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="PayrollReporting"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PayrollReportingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Payroll Reporting</h3>
            <p class="text-dark font-20-16">
              Accurate payroll reporting is crucial for maintaining business
              transparency and compliance. With our comprehensive payroll
              reporting services, you can gain complete control over your
              payroll. Our professionals provide detailed reports on wages,
              benefits, deductions, and hours worked, highlighting all payroll
              activities. We provide in-depth reports to streamline audits,
              internal reviews, and compliance with organizational policies.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Gathering all employees' inputs
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Calculating the payroll in different stages
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Managing all the payrolls within the system
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Preparing detailed payroll reports
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 225.webp"
              alt="Payroll Reporting"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="Compliance"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="ComplianceLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Compliance</h3>
            <p class="text-dark font-20-16">
              Ensuring compliance with all the latest rules and regulations
              feels like solving a puzzle without all the pieces. But, with our
              outsourcing payroll processing services, you can reduce the risk
              of penalties and fines with no miss and ensure smooth compliance
              during payroll processing. From accurate payroll calculations to
              legal compliance, our team handles every detail precisely. Let us
              take care of your payroll compliance.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Verifying employee classification
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Ensuring employee benefits administration
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Maintaining accurate records for payroll compliance
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Mitigating all the risks and penalties involved
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Generating policy proposal
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 226.webp"
              alt="Compliance  Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="AccountingSystemIntegration"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="AccountingSystemIntegrationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Accounting System Integration</h3>
            <p class="text-dark font-20-16">
              Is bulk data transfer or disrupted payroll efficiency slowing your
              business down? Our accounting system integration solutions can be
              your savior. We can integrate systems with existing accounting
              software to automate all the accounting processes. From reducing
              manual effort to improving your data accuracy, our payroll
              processing solutions ensure smoother workflows and better
              productivity.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Setting up the payroll accounting
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Integrating payroll software
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Conducting data synchronization
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Ensuring payroll processing and managing changes
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning Receivable Management/Group 227.webp"
              alt="Accounting System Integration Services "
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<app-know-best
  [cards]="[
    {
      description:
        'FBSPL transformed our operations quickly. Their exceptional administrative support, bookkeeping, and recruiting services significantly improved our processes and boosted lead conversion rates. Their integrity, professionalism, and tailored approach set them apart, making them valuable partners in our growth. ',
      name: 'Francesca Furlanetto',
      designation: 'Director Owner'
    },
    {
      description:
        'As the Director of Accounting for an E&S Insurance Company, I connected with FBSPL to support our monthly manual processes. Their team has been a game-changer, enabling us to meet deadlines and improve workflows. The team quickly adapted and delivered high-quality work with over 95% accuracy. Their dedication and service-oriented approach have been invaluable to our growing company. ',
      name: 'Monica Spivey',
      designation: 'Directory of Accounting'
    }
  ]"
  [videos]="[
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 10 Francesca Furlanetto.jpg',
      alt: 'Listen to our client '
    },
    {
      thumbnail:
        'assets/thumbnail-know-best/Client Testimonials YT 09 Monica Spivey.jpg',
      alt: 'Our Success Stories '
    }
  ]"
>
</app-know-best>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tools That Drive Excellence
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool Zoho  "
          appCdnUrl="assets/Tools/zoho.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool QuickBooks "
          appCdnUrl="assets/Tools/quickbooks.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool NetSuite "
          appCdnUrl="assets/Tools/netsuite.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool odoo "
          appCdnUrl="assets/Tools/odoo.svg"
        />
      </div>
      <div class="col-lg-2 col-md-3" data-aos="fade-right" data-aos-delay="400">
        <img
          class="img-fluid"
          alt="Accounting & Bookkeeping tool xero "
          appCdnUrl="assets/Tools/xero.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool Zoho  "
            appCdnUrl="assets/Tools/zoho.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool QuickBooks   "
            appCdnUrl="assets/Tools/quickbooks.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt=" Accounting & Bookkeeping tool NetSuite "
            appCdnUrl="assets/Tools/netsuite.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool odoo "
            appCdnUrl="assets/Tools/odoo.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img
            class="img-fluid"
            alt="Accounting & Bookkeeping tool xero "
            appCdnUrl="assets/Tools/xero.svg"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<ThinkingSection category="accountingandbookkeeping"></ThinkingSection>

<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>
    <div class="mt-3 mt-md-0" data-aos="fade-left">
      <div class="accordion" id="accordionFAQ">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button font-28-20"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Industries"
              aria-expanded="true"
              aria-controls="Industries"
            >
              What services do you offer in payroll processing?
            </button>
          </h2>
          <div
            id="Industries"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              We cover all types of payroll processing services. Here are the
              major services we deal in -
              <ul class="pt-3">
                <li>Payroll calculation </li>
                <li>Payroll record-keeping</li>
                <li>Accounting system administration </li>
                <li>Payroll reporting</li>
                <li>Compliance</li>

                <li>Benefit administration</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Services"
              aria-expanded="false"
              aria-controls="Services"
            >
              How do you ensure compliance?
            </button>
          </h2>
          <div
            id="Services"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              We understand the importance of accurate and compliant payroll
              processing. Hence, our team strictly adheres to the US Generally
              Accepted Accounting Principles (US GAAP), which are a set of
              accounting standards. These principles ensure consistency,
              transparency, and comparability across different businesses.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#About"
              aria-expanded="false"
              aria-controls="About"
            >
              How long does your outsourcing company take for payroll
              processing?
            </button>
          </h2>
          <div
            id="About"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              The time required for payroll processing completely depends on the
              client, whether they want payroll weekly, monthly, or bi-weekly.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFour">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#Resources"
              aria-expanded="false"
              aria-controls="Resources"
            >
              What technology do you use for payroll processing? 
            </button>
          </h2>
          <div
            id="Resources"
            class="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Generally, we use third-party payroll processing management
              companies' assistance for payroll processing, including ADP
              payroll and Paychex. But if a client wants a direct electronic
              cheque from the client's bank, we can do that as well.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingFive">
            <button
              class="accordion-button font-28-20 collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#differentiators"
              aria-expanded="false"
              aria-controls="differentiators"
            >
              Can you handle global payroll processing?
            </button>
          </h2>
          <div
            id="differentiators"
            class="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionFAQ"
          >
            <div class="accordion-body">
              Yes, we can help you with global payroll processing. Feel free to
              share your requirements with our professionals and get the best
              payroll processing outsourcing services.
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
