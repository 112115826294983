import { Component, OnInit, Inject,
  PLATFORM_ID,
  Renderer2 } from '@angular/core';

import { RouterLink } from '@angular/router';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SeoService } from '../../services/seo/seo.service';
import { SeoData } from '../../services/seo/seo-data.model';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-TermsCondition',
  standalone: true,
  imports: [RouterLink, CdnUrlDirective],
  templateUrl: './TermsCondition.component.html',
  styleUrl: './TermsCondition.component.css',
})
export class TermsConditionComponent implements OnInit {
  constructor(private meta: Meta, private seoService: SeoService, @Inject(DOCUMENT) private document: Document,
  @Inject(PLATFORM_ID) private platformId: Object,
  private renderer: Renderer2) {}
  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'Terms & Conditions | FBSPL',
      description:
        'Review the Terms & Conditions of FBSPL to understand the rules and guidelines for using our services.',
      keywords: 'Terms & Conditions, Terms of Service, User Agreement',
      ogImage: '/Sub-service-Pages/policy 1 1.png',
    };
    this.seoService.setMetaTags(seoData);
    // this.meta.addTag({ rel: 'canonical', href: 'https://www.fbspl.com/terms-&-condition' });
  }
}
