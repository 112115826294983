<ngx-slick-carousel
  class="service_slider carousel d-none d-lg-block container-fluid"
  #slickModal="slick-carousel"
  [config]="slideConfig"
  >
  @for (slides of slides; track slides) {
    <div
      class="container-fluid text-dark"
      ngxSlickItem
      >
      <div class="container gradientBanner">
        <img
          alt="{{ slides.alt }}"
          src="{{ slides.img }}"
          class="card-img-desktop desktop-banner"
          />
          <div class="card-img-overlay overlay-text bannerText">
            <div class="main_bannar my-4 ms-3" data-aos="fade-up">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="/">Home</a></li>
                  <li class="breadcrumb-item" aria-current="page">
                    <a href="/bpm-and-bpo-services">Services</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    <a href="services/data-annotation-services">Data Annotation</a>
                  </li>
                </ol>
              </nav>
              <a href="{{ slides.Link }}" class="CTA-Zindex anchorCTA">
                @if (!slides.banner) {
                  <h3
                    class="text-primary font-34-19 my-auto clickable-heading"
                    >
                    {{ slides.Title }}
                    <div class="headingCTA">
                      <button class="blueCTA"></button>
                    </div>
                  </h3>
                }
                @if (slides.banner) {
                  <h1
                    class="text-primary font-34-19 my-auto pt-1"
                    >
                    {{ slides.Title }}
                  </h1>
                }
              </a>
              <p class="card-text font-20-16 mt-3">{{ slides.Description }}</p>
              @if (slides.banner) {
                <a
                  [routerLink]="slides.Link"
                  class="CTA-Zindex anchorCTA"
                  >
                  <h3 class="text-primary font-24-16 clickable-heading mt-3">
                    {{ slides.Dataannotation }}
                    <div class="headingCTA">
                      <button class="blueCTA"></button>
                    </div>
                  </h3>
                </a>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </ngx-slick-carousel>
  <!-- Mobile View -->
  <ngx-slick-carousel
    class="container carousel d-block d-lg-none pb-5"
    #slickModal="slick-carousel"
    [config]="slideConfig"
    >
    @for (slidesmob of slides; track slidesmob) {
      <div class="text-dark slide" ngxSlickItem>
        <div class="gradientBannerMob d-flex justify-content-center">
          <img
            alt="{{ slidesmob.alt }}"
            src="{{ slidesmob.img_mob }}"
            class="card-img-mob"
            />
            <div class="overlay-text-Mob bannerTextMob">
              <div data-aos="fade-up">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="./">Home</a></li>
                    <li class="breadcrumb-item" aria-current="page">
                      <a href="/bpm-and-bpo-services">Services</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      <a href="services/data-annotation-services">Data Annotation</a>
                    </li>
                  </ol>
                </nav>
                <a href="{{ slidesmob.Link }}" class="CTA-Zindex anchorCTA">
                  @if (!slidesmob.banner) {
                    <h3
                      class="text-primary font-34-19 my-auto clickable-heading"
                      >
                      {{ slidesmob.Title }}
                      <div class="headingCTA">
                        <button class="blueCTA"></button>
                      </div>
                    </h3>
                  }
                  @if (slidesmob.banner) {
                    <h1
                      class="text-primary font-34-19 my-auto"
                      >
                      {{ slidesmob.Title }}
                    </h1>
                  }
                </a>
                <p class="card-text font-20-16 mt-3">{{ slidesmob.Description }}</p>
                @if (slidesmob.banner) {
                  <a
                    [routerLink]="slidesmob.Link"
                    class="CTA-Zindex anchorCTA"
                    >
                    <h3 class="text-primary font-24-16 clickable-heading">
                      {{ slidesmob.Dataannotation }}
                      <div class="headingCTA">
                        <button class="blueCTA"></button>
                      </div>
                    </h3>
                  </a>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </ngx-slick-carousel>

    <section class="bg-primary text-white py-6">
      <div class="container px-4">
        <h4 class="font-48-30 mb-3 text-white" data-aos="fade-up">
          Dealing with data
        </h4>
        <div class="row">
          <div class="row text-center d-none d-md-flex">
            <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="0">
              <div class="text-white text-start">
                <p class="font-48-30 fw-bolder mb-1">35%</p>
                <p class="">
                  Organizations see data quality as the biggest challenge
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="200">
              <div class="text-white text-start">
                <p class="font-48-30 fw-bolder mb-1">67%</p>
                <p class="">
                  Organizations consider data security as the topmost priority
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="400">
              <div class="text-white text-start">
                <p class="font-48-30 fw-bolder mb-1">18%</p>
                <p class="">
                  Decision makers consider the outsourcing cost to be a critical
                  deciding factor
                </p>
              </div>
            </div>
            <div class="col-lg-3 col-6" data-aos="fade-right" data-aos-delay="400">
              <div class="text-white text-start">
                <p class="font-48-30 fw-bolder mb-1">24%</p>
                <p class="">
                  Data centered companies struggle with maintaining consistency in
                  annotation
                </p>
              </div>
            </div>
          </div>
          <div class="d-md-none">
            <div
              class="text-white text-start"
              data-aos="fade-right"
              data-aos-delay="0"
              >
              <p class="font-48-30 fw-bolder mb-1">35%</p>
              <p class="">
                Organizations see data quality as the biggest challenge
              </p>
            </div>
            <div
              class="text-white text-start"
              data-aos="fade-right"
              data-aos-delay="200"
              >
              <p class="font-48-30 fw-bolder mb-1">67%</p>
              <p class="">
                Organizations consider data security as the topmost priority
              </p>
            </div>
            <div
              class="text-white text-start"
              data-aos="fade-right"
              data-aos-delay="400"
              >
              <p class="font-48-30 fw-bolder mb-1">18%</p>
              <p class="">
                Decision makers consider the outsourcing cost to be a critical
                deciding factor
              </p>
            </div>
            <div
              class="text-white text-start"
              data-aos="fade-right"
              data-aos-delay="400"
              >
              <p class="font-48-30 fw-bolder mb-1">24%</p>
              <p class="">
                Data centered companies struggle with maintaining consistency in
                annotation
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="py-5 container">
      <h2 class="text-primary font-48-30 text-center">
        Leading companies rely on FBSPL for data annotation excellence
      </h2>
      <div class="row mt-5">
        <div class="col-12 col-lg-3 my-3 my-lg-0">
          <div class="challengediv">
            <img
              src="https://d1585q1wairh36.cloudfront.net/assets/newImages/Guaranteed_data.png"
              alt=""
              />
              <h4 class="text-primary font-28-20">Unmatched accuracy</h4>
              <p class="fs-18">
                We prioritize precision with rigorous guidelines and quality checks,
                ensuring your AI models are trained on flawless data.
              </p>
            </div>
          </div>

          <div class="col-12 col-lg-3 my-3 my-lg-0">
            <div class="challengediv">
              <img
                src="https://d1585q1wairh36.cloudfront.net/assets/newImages/hidden_training.png"
                alt=""
                />
                <h4 class="text-primary font-28-20">Data security you can trust</h4>
                <p class="fs-18">
                  Our strict data privacy protocols make us a trusted partner for
                  industries requiring secure, reliable data handling.
                </p>
              </div>
            </div>

            <div class="col-12 col-lg-3 my-3 my-lg-0">
              <div class="challengediv">
                <img
                  src="https://d1585q1wairh36.cloudfront.net/assets/newImages/Pre-deployment.png"
                  alt=""
                  />
                  <h4 class="text-primary font-28-20">
                    Scalable solutions across industries
                  </h4>
                  <p class="fs-18">
                    From text annotation to audio annotation, our services adapt to your
                    project’s evolving needs without compromising quality.
                  </p>
                </div>
              </div>

              <div class="col-12 col-lg-3 my-3 my-lg-0">
                <div class="challengediv">
                  <img
                    src="https://d1585q1wairh36.cloudfront.net/assets/newImages/Customized_workflow.png"
                    alt=""
                    />
                    <h4 class="text-primary font-28-20">
                      Innovative synthetic data generation
                    </h4>
                    <p class="fs-18">
                      From rule-based generation to data augmentation, our synthetic data
                      capabilities enhance data diversity, enabling robust AI training even
                      with limited real-world data.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section class="container-fluid pt-5">
              <div class="container">
                <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
                  End-to-end data annotation and labeling services
                </h2>
                <div class="d-none d-lg-block">
                  <div class="row">
                    <div class="col-md-6 col-lg-4 py-3">
                      <a routerLink="/services/text-annotation-services">
                        <div
                          class="card bordered serviceCards Policy_Issuance"
                          data-aos="zoom-in"
                          >
                          <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                            <h3 class="text-white serviceHeading my-auto clickable-heading">
                              Text Annotation
                              <img
                                appCdnUrl="assets/Icon/white-vector.svg"
                                class="whiteCTABtn"
                                />
                              </h3>
                            </span>
                            <p class="text-black font-20-16 ms-2">
                              Precise text annotation services designed to support complex NLP
                              tasks, from sentiment analysis to entity recognition...
                              <br />
                              <span class="text-primary cursor-pointer">Read more</span>
                            </p>
                          </div>
                        </a>
                      </div>
                      <div class="col-md-6 col-lg-4 py-3">
                        <a routerLink="/services/video-annotation-outsourcing-services">
                          <div
                            class="card bordered serviceCards Policy-Checking"
                            data-aos="zoom-in"
                            >
                            <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                              <h3 class="text-white serviceHeading my-auto clickable-heading">
                                Video Annotation
                                <img
                                  appCdnUrl="assets/Icon/white-vector.svg"
                                  class="whiteCTABtn"
                                  />
                                </h3>
                              </span>
                              <p class="text-black font-20-16 ms-2">
                                Detailed frame-by-frame video annotation services for
                                comprehensive activity recognition, object tracking, and scene
                                analysis... <br />
                                <span class="text-primary cursor-pointer">Read more</span>
                              </p>
                            </div>
                          </a>
                        </div>
                        <div class="col-md-6 col-lg-4 py-3">
                          <a routerLink="/services/image-annotation-outsourcing-services">
                            <div
                              class="card bordered serviceCards Loss_Runs_Processing"
                              data-aos="zoom-in"
                              >
                              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                                <h3 class="text-white serviceHeading my-auto clickable-heading">
                                  Image Annotation
                                  <img
                                    appCdnUrl="assets/Icon/white-vector.svg"
                                    class="whiteCTABtn"
                                    />
                                  </h3>
                                </span>
                                <p class="text-black font-20-16 ms-2">
                                  Highly accurate image annotation services tailored for advanced
                                  computer vision models, supporting applications in object
                                  detection...
                                  <br />
                                  <span class="text-primary cursor-pointer">Read more</span>
                                </p>
                              </div>
                            </a>
                          </div>
                          <div class="col-md-6 col-lg-4 py-3">
                            <a routerLink="/services/data-annotation-services">
                              <div
                                class="card bordered serviceCards New_Business"
                                data-aos="zoom-in"
                                >
                                <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                                  <h3 class="text-white serviceHeading my-auto clickable-heading">
                                    Audio Annotation
                                    <img
                                      appCdnUrl="assets/Icon/white-vector.svg"
                                      class="whiteCTABtn"
                                      />
                                    </h3>
                                  </span>
                                  <p class="text-black font-20-16 ms-2">
                                    Customizable audio annotation solutions designed to support
                                    advanced speech recognition, sound classification...
                                    <br />
                                    <span class="text-primary cursor-pointer">Read more</span>
                                  </p>
                                </div>
                              </a>
                            </div>
                            <div class="col-md-6 col-lg-4 py-3">
                              <a 
                              routerLink="/services/data-annotation-services">
                                <div
                                  class="card bordered serviceCards Agency_Management"
                                  data-aos="zoom-in"
                                  >
                                  <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                                    <h3 class="text-white serviceHeading my-auto clickable-heading">
                                      Synthetic Data Generation
                                      <img
                                        appCdnUrl="assets/Icon/white-vector.svg"
                                        class="whiteCTABtn"
                                        />
                                      </h3>
                                    </span>
                                    <p class="text-black font-20-16 ms-2">
                                      Realistic, high-quality synthetic data created with DALL-E and
                                      Stable Diffusion to fill data gaps, enhancing the depth and
                                      diversity...
                                      <br />
                                      <span class="text-primary cursor-pointer">Read more</span>
                                    </p>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Mobile View -->
                        <div class="container p-3 d-block d-lg-none">
                          <div class="row">
                            <div class="col-12 mt-0 mt-sm-4">
                              <div class="accordion" id="ServicePage">
                                <div class="mobileService" data-aos="zoom-in">
                                  <div
                                    routerLink="/services/text-annotation-services"
                                    class="headingCTA CTA-Zindex d-flex p-3"
                                    >
                                    <h3 class="CTAs text-black fs-24 me-3">Text Annotation</h3>
                                    <img
                                      class="service_btn"
                                      appCdnUrl="assets/Icon/Accord-close.svg"
                                      />
                                    </div>
                                  </div>
                                  <div class="mobileService mt-4" data-aos="zoom-in">
                                    <div
                                      class="headingCTA CTA-Zindex d-flex p-3"
                                      routerLink="/services/video-annotation-outsourcing-services"
                                      >
                                      <h3 class="CTAs text-black fs-24 me-3">Video Annotation</h3>
                                      <img
                                        class="service_btn"
                                        appCdnUrl="assets/Icon/Accord-close.svg"
                                        />
                                      </div>
                                    </div>
                                    <div class="mobileService mt-4" data-aos="zoom-in">
                                      <div class="d-flex p-3" routerLink="/services/image-annotation-outsourcing-services">
                                        <h3 class="CTAs text-black fs-24 me-3">Image Annotation</h3>
                                        <img
                                          class="service_btn"
                                          appCdnUrl="assets/Icon/Accord-close.svg"
                                          />
                                        </div>
                                      </div>
                                      <div class="mobileService mt-4" data-aos="zoom-in">
                                        <div class="d-flex p-3" routerLink="/services/data-annotation-services">
                                          <h3 class="CTAs text-black fs-24 me-3">Audio Annotation</h3>
                                          <img
                                            class="service_btn"
                                            appCdnUrl="assets/Icon/Accord-close.svg"
                                            />
                                          </div>
                                        </div>
                                        <div class="mobileService mt-4" data-aos="zoom-in">
                                          <div
                                            class="d-flex p-3"
                                            routerLink="/services/data-annotation-services"
                                            >
                                            <h3 class="CTAs text-black fs-24 me-3">Synthetic Annotation</h3>
                                            <img
                                              class="service_btn"
                                              appCdnUrl="assets/Icon/Accord-close.svg"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>

                                <div
                                  class="modal fade service-modal"
                                  id="textAnnotation"
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  tabindex="-1"
                                  aria-labelledby="textAnnotationLabel"
                                  aria-hidden="true"
                                  >
                                  <div class="modal-dialog service-dialog">
                                    <div class="modal-content">
                                      <div class="modal-header policy-header">
                                        <img
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                          appCdnUrl="assets/Icon/Accord-open.svg"
                                          class="close_btn"
                                          />
                                        </div>
                                        <div class="modal-body">
                                          <div class="row">
                                            <div class="col-lg-6">
                                              <h3 class="font-48-30 text-dark">Text Annotation</h3>
                                              <p class="text-dark font-20-16">
                                                Precise text annotation services designed to support complex NLP
                                                tasks, from sentiment analysis to entity recognition, enabling AI
                                                to understand, interpret, and derive insights from textual data
                                                accurately and efficiently. You can now identify and label key
                                                elements such as entities, sentiments, and intent. Our team
                                                handles diverse formats. Ensuring contextually relevant
                                                annotations. Scale your AI project with our comprehensive support.
                                              </p>

                                              <ul class="ul-policy">
                                                <li class="list-item pt-2 text-dark font-20-16">
                                                  <img
                                                    class="arrow-img"
                                                    appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                    />Entity recognition
                                                  </li>
                                                  <li class="list-item pt-2 text-dark font-20-16">
                                                    <img
                                                      class="arrow-img"
                                                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                      />Keywords
                                                    </li>
                                                    <li class="list-item pt-2 text-dark font-20-16">
                                                      <img
                                                        class="arrow-img"
                                                        appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                        />Relations
                                                      </li>
                                                      <li class="list-item pt-2 text-dark font-20-16">
                                                        <img
                                                          class="arrow-img"
                                                          appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                          />Categorization
                                                        </li>
                                                        <li class="list-item pt-2 text-dark font-20-16">
                                                          <img
                                                            class="arrow-img"
                                                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                            />Sentiment for text analysis
                                                          </li>
                                                        </ul>
                                                      </div>
                                                      <div class="d-none d-lg-flex col-lg-6 justify-content-end">
                                                        <img
                                                          appCdnUrl="assets/Data Annotation/Text_Anotation.webp"
                                                          alt="Text Annotation Services "
                                                          class="img-fluid bordered"
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                class="modal fade service-modal"
                                                id="VideoAnnotation"
                                                data-bs-backdrop="static"
                                                data-bs-keyboard="false"
                                                tabindex="-1"
                                                aria-labelledby="VideoAnnotationLabel"
                                                aria-hidden="true"
                                                >
                                                <div class="modal-dialog service-dialog">
                                                  <div class="modal-content">
                                                    <div class="modal-header policy-header">
                                                      <img
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                        appCdnUrl="assets/Icon/Accord-open.svg"
                                                        class="close_btn"
                                                        />
                                                      </div>
                                                      <div class="modal-body">
                                                        <div class="row">
                                                          <div class="col-lg-6">
                                                            <h3 class="font-48-30 text-dark">Video Annotation</h3>
                                                            <p class="text-dark font-20-16">
                                                              Detailed frame-by-frame video annotation services for
                                                              comprehensive activity recognition, object tracking, and scene
                                                              analysis, ensuring reliable data for high-stakes visual AI
                                                              applications through advanced tagging and classification
                                                              techniques. You can now accurately interpret and analyze motion,
                                                              objects, and events in dynamic visual data. We transform raw video
                                                              content into actionable insights, training your systems to
                                                              understand and predict complex visual patterns.
                                                            </p>

                                                            <ul class="ul-policy">
                                                              <li class="list-item pt-2 text-dark font-20-16">
                                                                <img
                                                                  class="arrow-img"
                                                                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                  />3D/2D boxes
                                                                </li>
                                                                <li class="list-item pt-2 text-dark font-20-16">
                                                                  <img
                                                                    class="arrow-img"
                                                                    appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                    />Polygons
                                                                  </li>
                                                                  <li class="list-item pt-2 text-dark font-20-16">
                                                                    <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                                                                    Tagging
                                                                  </li>
                                                                  <li class="list-item pt-2 text-dark font-20-16">
                                                                    <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                                                                    Classification
                                                                  </li>
                                                                  <li class="list-item pt-2 text-dark font-20-16">
                                                                    <img
                                                                      class="arrow-img"
                                                                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                      />Tracking for motion and context.
                                                                    </li>
                                                                  </ul>
                                                                </div>
                                                                <div class="d-none d-lg-flex col-lg-6 justify-content-end">
                                                                  <img
                                                                    appCdnUrl="assets/Data Annotation/Group 149.webp"
                                                                    alt="Video Annotation Services "
                                                                    class="img-fluid bordered"
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div
                                                          class="modal fade service-modal"
                                                          id="ImageAnnotation"
                                                          data-bs-backdrop="static"
                                                          data-bs-keyboard="false"
                                                          tabindex="-1"
                                                          aria-labelledby="ImageAnnotationLabel"
                                                          aria-hidden="true"
                                                          >
                                                          <div class="modal-dialog service-dialog">
                                                            <div class="modal-content">
                                                              <div class="modal-header policy-header">
                                                                <img
                                                                  data-bs-dismiss="modal"
                                                                  aria-label="Close"
                                                                  appCdnUrl="assets/Icon/Accord-open.svg"
                                                                  class="close_btn"
                                                                  />
                                                                </div>
                                                                <div class="modal-body">
                                                                  <div class="row">
                                                                    <div class="col-lg-6">
                                                                      <h3 class="font-48-30 text-dark">Image Annotation</h3>
                                                                      <p class="text-dark font-20-16">
                                                                        Highly accurate image annotation services tailored for advanced
                                                                        computer vision models, supporting applications in object
                                                                        detection, segmentation, and classification with meticulous
                                                                        attention to quality, accuracy, and security in every dataset. We
                                                                        specialize in tagging objects, defining boundaries, and
                                                                        categorizing elements. Our use of advanced tools and techniques
                                                                        ensure consistency and accuracy. Time to empower your AI models
                                                                        with labeled image datasets.
                                                                      </p>

                                                                      <ul class="ul-policy">
                                                                        <li class="list-item pt-2 text-dark font-20-16">
                                                                          <img
                                                                            class="arrow-img"
                                                                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                            />Semantic
                                                                          </li>
                                                                          <li class="list-item pt-2 text-dark font-20-16">
                                                                            <img
                                                                              class="arrow-img"
                                                                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                              />2D boxes
                                                                            </li>
                                                                            <li class="list-item pt-2 text-dark font-20-16">
                                                                              <img
                                                                                class="arrow-img"
                                                                                appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                />Labels
                                                                              </li>
                                                                              <li class="list-item pt-2 text-dark font-20-16">
                                                                                <img
                                                                                  class="arrow-img"
                                                                                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                  />Points/landmarks for detailed object identification.
                                                                                </li>
                                                                              </ul>
                                                                            </div>
                                                                            <div class="d-none d-lg-flex col-lg-6 justify-content-end">
                                                                              <img
                                                                                appCdnUrl="assets/Data Annotation/Group 147.webp"
                                                                                alt="Image Annotation Services "
                                                                                class="img-fluid bordered"
                                                                                />
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                    <div
                                                                      class="modal fade service-modal"
                                                                      id="AudioAnnotation"
                                                                      data-bs-backdrop="static"
                                                                      data-bs-keyboard="false"
                                                                      tabindex="-1"
                                                                      aria-labelledby="AudioAnnotationLabel"
                                                                      aria-hidden="true"
                                                                      >
                                                                      <div class="modal-dialog service-dialog">
                                                                        <div class="modal-content">
                                                                          <div class="modal-header policy-header">
                                                                            <img
                                                                              data-bs-dismiss="modal"
                                                                              aria-label="Close"
                                                                              appCdnUrl="assets/Icon/Accord-open.svg"
                                                                              class="close_btn"
                                                                              />
                                                                            </div>
                                                                            <div class="modal-body">
                                                                              <div class="row">
                                                                                <div class="col-lg-6">
                                                                                  <h3 class="font-48-30 text-dark">Audio Annotation</h3>
                                                                                  <p class="text-dark font-20-16">
                                                                                    Customizable audio annotation solutions designed to support
                                                                                    advanced speech recognition, sound classification, and voice-based
                                                                                    analytics for diverse AI applications. From accurate speech to
                                                                                    text transcription to sound event detection, we ensure precision
                                                                                    throughout. Make the most of your audio data with our audio
                                                                                    annotation services. Whether for voice assistants or acoustic
                                                                                    analysis, our annotations ensure your model captures every nuance.
                                                                                  </p>

                                                                                  <ul class="ul-policy">
                                                                                    <li class="list-item pt-2 text-dark font-20-16">
                                                                                      <img
                                                                                        class="arrow-img"
                                                                                        appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                        />Speech Transcription
                                                                                      </li>
                                                                                      <li class="list-item pt-2 text-dark font-20-16">
                                                                                        <img
                                                                                          class="arrow-img"
                                                                                          appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                          />Speaker Identification
                                                                                        </li>
                                                                                        <li class="list-item pt-2 text-dark font-20-16">
                                                                                          <img
                                                                                            class="arrow-img"
                                                                                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                            />Emotion/Sentiment Analysis
                                                                                          </li>
                                                                                          <li class="list-item pt-2 text-dark font-20-16">
                                                                                            <img
                                                                                              class="arrow-img"
                                                                                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                              />Sound Event Detection
                                                                                            </li>
                                                                                            <li class="list-item pt-2 text-dark font-20-16">
                                                                                              <img
                                                                                                class="arrow-img"
                                                                                                appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                                />Timestamping Events
                                                                                              </li>
                                                                                            </ul>
                                                                                          </div>
                                                                                          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
                                                                                            <img
                                                                                              appCdnUrl="assets/Data Annotation/Group 147.webp"
                                                                                              alt="Image Annotation Services "
                                                                                              class="img-fluid bordered"
                                                                                              />
                                                                                            </div>
                                                                                          </div>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <div
                                                                                    class="modal fade service-modal"
                                                                                    id="SyntheticAnnotation"
                                                                                    data-bs-backdrop="static"
                                                                                    data-bs-keyboard="false"
                                                                                    tabindex="-1"
                                                                                    aria-labelledby="SyntheticAnnotationLabel"
                                                                                    aria-hidden="true"
                                                                                    >
                                                                                    <div class="modal-dialog service-dialog">
                                                                                      <div class="modal-content">
                                                                                        <div class="modal-header policy-header">
                                                                                          <img
                                                                                            data-bs-dismiss="modal"
                                                                                            aria-label="Close"
                                                                                            appCdnUrl="assets/Icon/Accord-open.svg"
                                                                                            class="close_btn"
                                                                                            />
                                                                                          </div>
                                                                                          <div class="modal-body">
                                                                                            <div class="row">
                                                                                              <div class="col-lg-6">
                                                                                                <h3 class="font-48-30 text-dark">Synthetic Annotation</h3>
                                                                                                <p class="text-dark font-20-16">
                                                                                                  Realistic, high-quality synthetic data created with DALL-E and
                                                                                                  Stable Diffusion to fill data gaps, enhancing the depth and
                                                                                                  diversity of training datasets. Using advanced algorithms and
                                                                                                  domain specific modeling, we produce relevant and scalable data
                                                                                                  that can meet your unique needs. Whether for NLP, computer vision,
                                                                                                  or predictive analytics, synthetic data can get rid of biases,
                                                                                                  accelerate model training, and bridge data gaps without relying on
                                                                                                  sensitive real-world data.
                                                                                                </p>
                                                                                                <ul class="ul-policy">
                                                                                                  <li class="list-item pt-2 text-dark font-20-16">
                                                                                                    <img
                                                                                                      class="arrow-img"
                                                                                                      appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                                      />Image Data Generation
                                                                                                    </li>
                                                                                                    <li class="list-item pt-2 text-dark font-20-16">
                                                                                                      <img
                                                                                                        class="arrow-img"
                                                                                                        appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                                        />Text Data Generation
                                                                                                      </li>
                                                                                                      <li class="list-item pt-2 text-dark font-20-16">
                                                                                                        <img
                                                                                                          class="arrow-img"
                                                                                                          appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                                          />Audio Data Generation
                                                                                                        </li>
                                                                                                        <li class="list-item pt-2 text-dark font-20-16">
                                                                                                          <img
                                                                                                            class="arrow-img"
                                                                                                            appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                                            />Tabular Data Generation
                                                                                                          </li>
                                                                                                          <li class="list-item pt-2 text-dark font-20-16">
                                                                                                            <img
                                                                                                              class="arrow-img"
                                                                                                              appCdnUrl="assets/Icon/Arrow-blue.svg"
                                                                                                              />Video Data Generation
                                                                                                            </li>
                                                                                                          </ul>
                                                                                                        </div>
                                                                                                        <div class="d-none d-lg-flex col-lg-6 justify-content-end">
                                                                                                          <img
                                                                                                            appCdnUrl="assets/Data Annotation/Group 147.webp"
                                                                                                            alt="Image Annotation Services "
                                                                                                            class="img-fluid bordered"
                                                                                                            />
                                                                                                          </div>
                                                                                                        </div>
                                                                                                      </div>
                                                                                                    </div>
                                                                                                  </div>
                                                                                                </div>

                                                                                                <app-ourAssets></app-ourAssets>

                                                                                                <section class="container py-5">
                                                                                                  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
                                                                                                    Tools of the future
                                                                                                  </h2>
                                                                                                  <div class="d-none d-xl-flex">
                                                                                                    <div class="row">
                                                                                                      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
                                                                                                        <img
                                                                                                          class="img-fluid"
                                                                                                          alt="SuperAnnotate "
                                                                                                          appCdnUrl="assets/Tools/superAnnotate.svg"
                                                                                                          />
                                                                                                        </div>
                                                                                                        <div class="col-2" data-aos="fade-right" data-aos-delay="100">
                                                                                                          <img
                                                                                                            class="img-fluid"
                                                                                                            alt="Adobe Acrobate  "
                                                                                                            appCdnUrl="assets/Tools/adobeacrobat.svg"
                                                                                                            />
                                                                                                          </div>
                                                                                                          <div class="col-2" data-aos="fade-right" data-aos-delay="200">
                                                                                                            <img class="img-fluid" alt="nitro" appCdnUrl="assets/Tools/Nitro.svg" />
                                                                                                          </div>
                                                                                                          <div class="col-2" data-aos="fade-right" data-aos-delay="300">
                                                                                                            <img
                                                                                                              class="img-fluid"
                                                                                                              alt="labelme"
                                                                                                              appCdnUrl="assets/Tools/Labelme.svg"
                                                                                                              />
                                                                                                            </div>
                                                                                                            <div class="col-2" data-aos="fade-right" data-aos-delay="400">
                                                                                                              <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
                                                                                                            </div>
                                                                                                          </div>
                                                                                                        </div>
                                                                                                        <div class="d-flex d-xl-none">
                                                                                                          <div class="trusted-slider-logo">
                                                                                                            <div class="trusted-slide-track">
                                                                                                              <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
                                                                                                                <img
                                                                                                                  class="img-fluid"
                                                                                                                  alt="SuperAnnotate  "
                                                                                                                  appCdnUrl="assets/Tools/superAnnotate.svg"
                                                                                                                  />
                                                                                                                </div>
                                                                                                                <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
                                                                                                                  <img
                                                                                                                    class="img-fluid"
                                                                                                                    alt="Adobe Acrobate   "
                                                                                                                    appCdnUrl="assets/Tools/adobeacrobat.svg"
                                                                                                                    />
                                                                                                                  </div>
                                                                                                                  <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
                                                                                                                    <img
                                                                                                                      class="img-fluid"
                                                                                                                      alt="nitro"
                                                                                                                      appCdnUrl="assets/Tools/Nitro.svg"
                                                                                                                      />
                                                                                                                    </div>
                                                                                                                    <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
                                                                                                                      <img
                                                                                                                        class="img-fluid"
                                                                                                                        alt="labelme"
                                                                                                                        appCdnUrl="assets/Tools/Labelme.svg"
                                                                                                                        />
                                                                                                                      </div>
                                                                                                                      <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
                                                                                                                        <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
                                                                                                                      </div>
                                                                                                                      <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
                                                                                                                        <img
                                                                                                                          class="img-fluid"
                                                                                                                          alt="SuperAnnotate  "
                                                                                                                          appCdnUrl="assets/Tools/superAnnotate.svg"
                                                                                                                          />
                                                                                                                        </div>
                                                                                                                        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
                                                                                                                          <img
                                                                                                                            class="img-fluid"
                                                                                                                            alt="Adobe Acrobate   "
                                                                                                                            appCdnUrl="assets/Tools/adobeacrobat.svg"
                                                                                                                            />
                                                                                                                          </div>
                                                                                                                          <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
                                                                                                                            <img
                                                                                                                              class="img-fluid"
                                                                                                                              alt="nitro"
                                                                                                                              appCdnUrl="assets/Tools/Nitro.svg"
                                                                                                                              />
                                                                                                                            </div>
                                                                                                                            <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
                                                                                                                              <img
                                                                                                                                class="img-fluid"
                                                                                                                                alt="labelme"
                                                                                                                                appCdnUrl="assets/Tools/Labelme.svg"
                                                                                                                                />
                                                                                                                              </div>
                                                                                                                              <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
                                                                                                                                <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
                                                                                                                              </div>
                                                                                                                            </div>
                                                                                                                          </div>
                                                                                                                        </div>
                                                                                                                      </section>

                                                                                                                      <ThinkingSection category="dataannotation"></ThinkingSection>
                                                                                                                      <section class="py-6" data-aos="fade-up">
                                                                                                                        <div class="container">
                                                                                                                          <h3 class="text-primary font-48-30">Frequently asked questions</h3>
                                                                                                                          <div class="mt-3 mt-md-0" data-aos="fade-left">
                                                                                                                            <div class="accordion" id="accordionFAQ">
                                                                                                                              <div class="accordion-item">
                                                                                                                                <h2 class="accordion-header" id="headingOne">
                                                                                                                                  <button
                                                                                                                                    class="accordion-button font-28-20"
                                                                                                                                    type="button"
                                                                                                                                    data-bs-toggle="collapse"
                                                                                                                                    data-bs-target="#Industries"
                                                                                                                                    aria-expanded="true"
                                                                                                                                    aria-controls="Industries"
                                                                                                                                    >
                                                                                                                                    What is your turnaround time?
                                                                                                                                  </button>
                                                                                                                                </h2>
                                                                                                                                <div
                                                                                                                                  id="Industries"
                                                                                                                                  class="accordion-collapse collapse show"
                                                                                                                                  aria-labelledby="headingOne"
                                                                                                                                  data-bs-parent="#accordionFAQ"
                                                                                                                                  >
                                                                                                                                  <div class="accordion-body">
                                                                                                                                    The approximate timing varies from 30 seconds to 15 minutes, and
                                                                                                                                    heavily depends on:
                                                                                                                                    <ul class="pt-3">
                                                                                                                                      <li>Project size</li>
                                                                                                                                      <li>Complexity of the project</li>
                                                                                                                                      <li>Annotation type</li>
                                                                                                                                    </ul>
                                                                                                                                    However, we are dedicated to providing high-quality annotated data
                                                                                                                                    within a reasonable timeframe.
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div>
                                                                                                                              <div class="accordion-item">
                                                                                                                                <h2 class="accordion-header" id="headingTwo">
                                                                                                                                  <button
                                                                                                                                    class="accordion-button font-28-20 collapsed"
                                                                                                                                    type="button"
                                                                                                                                    data-bs-toggle="collapse"
                                                                                                                                    data-bs-target="#Services"
                                                                                                                                    aria-expanded="false"
                                                                                                                                    aria-controls="Services"
                                                                                                                                    >
                                                                                                                                    Do I get customer support that addresses my concerns throughout
                                                                                                                                    the data annotation outsourcing partnership?
                                                                                                                                  </button>
                                                                                                                                </h2>
                                                                                                                                <div
                                                                                                                                  id="Services"
                                                                                                                                  class="accordion-collapse collapse"
                                                                                                                                  aria-labelledby="headingTwo"
                                                                                                                                  data-bs-parent="#accordionFAQ"
                                                                                                                                  >
                                                                                                                                  <div class="accordion-body">
                                                                                                                                    Certainly, with us, you have:
                                                                                                                                    <ul class="pt-3">
                                                                                                                                      <li>
                                                                                                                                        A dedicated person who acts as your main point of contact and
                                                                                                                                        ensures your expectations are met throughout the partnership.
                                                                                                                                      </li>
                                                                                                                                      <li>
                                                                                                                                        Various communication channels, including e-mail, phone, and
                                                                                                                                        Teams chat. You can contact us through any of these channels.
                                                                                                                                      </li>
                                                                                                                                      <li>Regular updates on your project's progress and more.</li>
                                                                                                                                    </ul>
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div>
                                                                                                                              <div class="accordion-item">
                                                                                                                                <h2 class="accordion-header" id="headingThree">
                                                                                                                                  <button
                                                                                                                                    class="accordion-button font-28-20 collapsed"
                                                                                                                                    type="button"
                                                                                                                                    data-bs-toggle="collapse"
                                                                                                                                    data-bs-target="#About"
                                                                                                                                    aria-expanded="false"
                                                                                                                                    aria-controls="About"
                                                                                                                                    >
                                                                                                                                    How does your team ensure data diversity in data annotation
                                                                                                                                    services?
                                                                                                                                  </button>
                                                                                                                                </h2>
                                                                                                                                <div
                                                                                                                                  id="About"
                                                                                                                                  class="accordion-collapse collapse"
                                                                                                                                  aria-labelledby="headingThree"
                                                                                                                                  data-bs-parent="#accordionFAQ"
                                                                                                                                  >
                                                                                                                                  <div class="accordion-body">
                                                                                                                                    To ensure data diversity, we have a set of guidelines in place,
                                                                                                                                    which include but are not limited to the following:
                                                                                                                                    <ul class="pt-3">
                                                                                                                                      <li>
                                                                                                                                        Identifying and annotating a wide range of features that
                                                                                                                                        capture different aspects of the data
                                                                                                                                      </li>
                                                                                                                                      <li>
                                                                                                                                        Applying changes like rotation, cropping, and noise to enhance
                                                                                                                                        the existing dataset
                                                                                                                                      </li>
                                                                                                                                      <li>
                                                                                                                                        Engaging annotators from diverse backgrounds and demographics
                                                                                                                                        to bring a range of perspectives to the dataset
                                                                                                                                      </li>
                                                                                                                                      <li>Eliminating human biases or discriminatory outcomes</li>
                                                                                                                                    </ul>
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div>
                                                                                                                              <div class="accordion-item">
                                                                                                                                <h2 class="accordion-header" id="headingFour">
                                                                                                                                  <button
                                                                                                                                    class="accordion-button font-28-20 collapsed"
                                                                                                                                    type="button"
                                                                                                                                    data-bs-toggle="collapse"
                                                                                                                                    data-bs-target="#Resources"
                                                                                                                                    aria-expanded="false"
                                                                                                                                    aria-controls="Resources"
                                                                                                                                    >
                                                                                                                                    Can you explain your strategy for eliminating human biases from
                                                                                                                                    data annotation?
                                                                                                                                  </button>
                                                                                                                                </h2>
                                                                                                                                <div
                                                                                                                                  id="Resources"
                                                                                                                                  class="accordion-collapse collapse"
                                                                                                                                  aria-labelledby="headingFour"
                                                                                                                                  data-bs-parent="#accordionFAQ"
                                                                                                                                  >
                                                                                                                                  <div class="accordion-body">
                                                                                                                                    To eliminate human biases, we have:
                                                                                                                                    <ul class="pt-3">
                                                                                                                                      <li>
                                                                                                                                        Well-defined, clear, and comprehensive guidelines and Standard
                                                                                                                                        Operating Procedures (SOPs)
                                                                                                                                      </li>
                                                                                                                                      <li>
                                                                                                                                        Ongoing knowledge transfer training to maintain team alignment
                                                                                                                                        with the importance of impartiality and consistency in data
                                                                                                                                        labeling services.
                                                                                                                                      </li>
                                                                                                                                      <li>
                                                                                                                                        Frequent quality check sessions to verify compliance with
                                                                                                                                        established SOPs and guidelines.
                                                                                                                                      </li>
                                                                                                                                      <li>
                                                                                                                                        Recurring discussions on critical aspects such as ethical
                                                                                                                                        reviews and blind annotations.
                                                                                                                                      </li>
                                                                                                                                    </ul>
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div>
                                                                                                                              <div class="accordion-item">
                                                                                                                                <h2 class="accordion-header" id="headingFive">
                                                                                                                                  <button
                                                                                                                                    class="accordion-button font-28-20 collapsed"
                                                                                                                                    type="button"
                                                                                                                                    data-bs-toggle="collapse"
                                                                                                                                    data-bs-target="#differentiators"
                                                                                                                                    aria-expanded="false"
                                                                                                                                    aria-controls="differentiators"
                                                                                                                                    >
                                                                                                                                    Why should I choose you as my right ecommerce outsourcing service
                                                                                                                                    provider?
                                                                                                                                  </button>
                                                                                                                                </h2>
                                                                                                                                <div
                                                                                                                                  id="differentiators"
                                                                                                                                  class="accordion-collapse collapse"
                                                                                                                                  aria-labelledby="headingFive"
                                                                                                                                  data-bs-parent="#accordionFAQ"
                                                                                                                                  >
                                                                                                                                  <div class="accordion-body">
                                                                                                                                    You should partner with us if you are looking for:
                                                                                                                                    <ul class="pt-3">
                                                                                                                                      <li>
                                                                                                                                        360-degree assistance that meets your dynamic business needs
                                                                                                                                        round the clock
                                                                                                                                      </li>
                                                                                                                                      <li>Professionally trained employees</li>
                                                                                                                                      <li>Buck-up services at zero additional cost</li>
                                                                                                                                      <li>
                                                                                                                                        Dedicated customer support, vendor management, and much more
                                                                                                                                      </li>
                                                                                                                                    </ul>
                                                                                                                                    Partnering with us means aligning with the industry excellence.
                                                                                                                                  </div>
                                                                                                                                </div>
                                                                                                                              </div>
                                                                                                                            </div>
                                                                                                                          </div>
                                                                                                                          <app-faq></app-faq>
                                                                                                                        </div>
                                                                                                                      </section>
