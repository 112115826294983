import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../../../components/ThinkingSection/ThinkingSection.component';

@Component({
  selector: 'app-policy-processing',
  standalone: true,
  templateUrl: './policy-processing.component.html',
  styleUrl: './policy-processing.component.css',
  imports: [
    FaqComponent,
    KnowBestComponent,
    ThinkingSection,
    CdnUrlDirective,
    ourAssetsComponent,
  ],
})
export class PolicyProcessingComponent implements OnInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: 'Outsource Insurance Policy Processing & Management | FBSPL',
      description:
        'At FBSPL, we customize your policy processing and management services to meet industry standards. Our services include policy checking, loss run processing, policy renewal, and overall management.',
      keywords:
        'insurance policy processing,  outsourcing insurance policy management',
      ogImage:
        '/insurane policy processing/Policy Processing Main Banner 1.jpg',
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "What are the benefits of insurance policy processing outsourcing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": " Outsourcing your insurance back-office operations including policy processing brings in a wide range of benefits. Here are some of them. 

<ul> 

<li>Reduce policy admin costs</li> 

<li>Minimize E&O</li> 

<li>Access to specialized expertise</li> 

<li>Risk mitigation and compliance</li> 

<li>Focus on strategic growth</li> 

<li>99% accuracy and 0% attrition</li> 

</ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What domains do you cover in policy processing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We specialize in processing a wide range of insurance policies, including P&C, employee benefits, commercial, and more. To know more about the policy processing solutions for a specific department, you can connect with our professionals." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you ensure accuracy in insurance policy processing?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Accuracy is the key factor when it comes to policy processing and management. Therefore, we employ stringent quality control measures, including manual process review and data validation to minimize errors and discrepancies." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is the insurance policy process you follow?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We follow a very comprehensive and transparent insurance policy processing process. Our team ensures accuracy and efficiency in every step of the policy processing cycle, from initial data entry to policy issuance and beyond." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What are your key differentiators?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "<ul> 

<li>Availability As Per Your Time Zone</li> 

<li>All-in One Expertise</li> 

<li>Dedicated Staff</li> 

<li>99% Accuracy</li> 

<li>Tailor-Made Solutions</li></ul>" 

      } 

    } 

  ] 

} 
  `;
        this.renderer.appendChild(this.document.head, script);
      }
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "BreadcrumbList", 

  "itemListElement": [ 

    { 

      "@type": "ListItem", 

      "position": 1, 

      "name": "Home", 

      "item": "https://www.fbspl.com/" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 2, 

      "name": "Services", 

      "item": "https://www.fbspl.com/bpm-and-bpo-services" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 3, 

      "name": "Insurance", 

      "item": "https://www.fbspl.com/services/insurance-bpo-outsourcing" 

    }, 

    { 

      "@type": "ListItem", 

      "position": 4, 

      "name": "Policy Processing", 

      "item": "https://www.fbspl.com/services/insurance-policy-processing" 

    } 

  ] 

} `;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }
}
