import { Component , OnInit, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from "../../../../../components/HomeComponents/service-case-study/service-case-study.component";
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from "../../../../../components/HomeComponents/ourAssets/ourAssets.component";
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-order-management',
    standalone: true,
    templateUrl: './order-management.component.html',
    styleUrl: './order-management.component.css',
    imports: [
    FaqComponent,
    ServiceCaseStudyComponent, KnowBestComponent, RouterLink, RouterOutlet, CdnUrlDirective,
    ourAssetsComponent
]
})
export class OrderManagement implements OnInit{
  constructor(private meta: Meta,private seoService: SeoService, private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) { }
  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'eCommerce Order Processing & Management Services | FBSPL',
      description:
        "FBSPL order management solution provides end-to-end support on processing, tracking, shipping inquiries, and more enhancing your complete e-commerce operations.",
      keywords: 'ecommerce order processing, ecommerce order management',
      ogImage: '/ogImage/Order management-banner.jpg'
    };
    this.seoService.setMetaTags(seoData);
    this.addJsonLdScript();

  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
    if (!this.document.head.querySelector('script[type="application/ld+json"]')) {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "How do you ensure last-mile deliveries in order processing services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Through our advanced tracking technologies and API integrations, we closely monitor the status of each shipment. This real-time tracking information also enables us to analyze delivery times, potential delays, and traffic conditions and dynamically adjust routes to confirm prompt and efficient deliveries." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What is your take on forecasting future product demands in ecommerce order fulfillment?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our approach involves utilizing advanced statistical analysis techniques to identify patterns and their correlations, enabling accurate prediction of shifts in consumer behavior and market trends. We use these insights to stock the right products in the right quantity at the right time." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How can I retain full control of my order management despite outsourcing the service?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our order management services employ real-time synchronization and provide you with instant updates on your consumers' order status, inventory levels, and shipping details. Moreover, our system's advanced encryption and authentication safeguard any sensitive order information, ensuring data security and compliance with industry regulations." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you claim to reduce errors in order processing services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Combining the capabilities of built-in error detection and exception handling protocols, our ecommerce order management solutions are founded on the principles of high accuracy and customer satisfaction. Placing customer satisfaction at the core of our operations, we continuously identify and update areas for optimization and refinement." 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for:  
<ul><li> 360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> " 

      } 

    } 

  ] 

}  `;
      this.renderer.appendChild(this.document.head, script);
    }
    if (!this.document.head.querySelector('script[type="application/ld+json"][data-script="breadcrumb"]')) {
      const breadcrumbScript = this.renderer.createElement('script');
      breadcrumbScript.type = 'application/ld+json';
      breadcrumbScript.setAttribute('data-script', 'breadcrumb');
      breadcrumbScript.text = `
      {  

  

  "@context": "https://schema.org",  

  

  "@type": "BreadcrumbList",  

  

  "itemListElement": [  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 1,  

  

      "name": "Home",  

  

      "item": "https://www.fbspl.com/"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 2,  

  

      "name": "Services",  

  

      "item": "https://www.fbspl.com/bpm-and-bpo-services"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 3,  

  

      "name": "eCommerce",  

  

      "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services"  

  

    },  

  

    {  

  

      "@type": "ListItem",  

  

      "position": 4,  

  

      "name": "Order Management Services",  

  

      "item": "https://www.fbspl.com/services/e-commerce-order-management"  

  

    }  

  

  ]  

  

}  `;
      this.renderer.appendChild(this.document.head, breadcrumbScript);
    }
  }
}
}
