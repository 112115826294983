import { Component } from '@angular/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';

@Component({
  selector: 'app-dataVisualSteps',
  standalone: true,
  imports: [SlickCarouselModule],
  templateUrl: './dataVisualSteps.component.html',
  styleUrl: './dataVisualSteps.component.css',
})
export class dataVisualStepsComponent {
  slides = [
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/bookACall.svg',
      headingContent: 'Discovery call',
      step: 1,
    },
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/fillTheForm.svg',
      headingContent: 'Reviewing tech infrastructure',
      step: 2,
    },
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/Domain.svg',
      headingContent: 'Extending a proposal',
      step: 3,
    },
    {
      img: 'https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/scopeOfWork.svg',
      headingContent: 'Implementation',
      step: 4,
    },
  ];

  slickConfig = {
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1, // Adjust for smaller screens
          dots: true,
          prevArrow: false,
          nextArrow: false,
        },
      },
    ],
  };

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3000,
    arrow: false,
  };
  constructor() {}

  ngOnInit(): void {}
}
