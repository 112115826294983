<!-- <div class="container d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Security-Measures/SecurityMeasuresBanner.jpg"
      class="desktop-banner"
    />
    <div class="bannerText" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Sitemap</li>
        </ol>
      </nav>
      <h3 class="font-48-30 text-primary fw-500 pt-3">Sitemap</h3>
    </div>
  </div>
</div>
<div class="container text-dark slide d-block d-lg-none pt-5">
  <div class="gradientBannerMob">
    <img
      appCdnUrl="assets/Security-Measures/SecurityMeasuresMobileBanner.jpg"
      class="card-img-mob"
    />
    <div class="bannerTextMob" data-aos="fade-up">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="./">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Sitemap</li>
        </ol>
      </nav>
      <h3 class="font-48-30 text-primary fw-500 pt-3">Sitemap</h3>
    </div>
  </div>
</div> -->
<section class="container py-6">
  <h1 class="text-primary font-48-30 mb-3 fw-bolder">Sitemap</h1>
  <div class="row">
    <div class="col-12 col-md-6">
      <ul class="my-0">
        <li class="fw-bolder"><a routerLink="/">Home</a></li>
        <li class="fw-bolder">
          <a routerLink="/about-who-we-are">About FBSPL</a>
          <ul>
            <li class="fw-500">
              <a routerLink="/about-who-we-are">Who we are</a>
            </li>
            <li class="fw-500">
              <a routerLink="/our-leadership">Leadership</a>
            </li>
            <li class="fw-500">
              <a routerLink="/csr-social-responsibilities"
                >Social Responsibility</a
              >
            </li>
            <li class="fw-500">
              <a routerLink="/life-at-fbspl">Life at FBSPL</a>
            </li>
          </ul>
        </li>
        <li class="fw-bolder"><a routerLink="/contact-us">Contact Us</a></li>
        <li class="fw-bolder"><a routerLink="/blogs">Blogs</a></li>
        <li class="fw-bolder">
          <a routerLink="/client-testimonial-reviews">Client Testimonials</a>
        </li>
        <li class="fw-bolder">
          <a routerLink="/case-studies">Client Success Stories-Case Studies</a>
        </li>
        <li class="fw-bolder"><a routerLink="/guides">Guides</a></li>
        <li class="fw-500"><a routerLink="/news">Newsroom</a></li>
        <li class="fw-bolder"><a routerLink="/podcast">Podcast</a></li>
        <li class="fw-bolder">
          <a routerLink="/careers">Career</a>
          <ul>
            <li class="fw-500">
              <a routerLink="/careers/current-openings">Current Openings</a>
            </li>
          </ul>
        </li>
        <li class="fw-bolder">
          <a routerLink="/book-free-consultation-call"
            >Book Free Consultation</a
          >
        </li>
        <li class="fw-bolder">
          <a
            routerLink="/news/Fusion%20Business%20Solutions-Private-Limited-FBSPL-Is-Certified-Great-Place-to-Work"
            >Great Place to Work Certification</a
          >
        </li>
        <li class="fw-bolder">
          <a routerLink="/terms-&-condition">Terms & Condition</a>
        </li>
        <li class="fw-bolder">
          <a routerLink="/privacy-policy">Privacy Policy</a>
        </li>
        <li class="fw-bolder">
          <a routerLink="/security-measures">Security Measures</a>
        </li>
        <li class="fw-bolder">
          <a [href]="ISO2700" target="_blank">ISO 27001 Certification</a>
        </li>
        <li class="fw-bolder">
          <a [href]="ISO9001" target="_blank">ISO 9001 Certification</a>
        </li>
      </ul>
    </div>
    <div class="col-12 col-md-6">
      <ul class="my-0">
        <li class="fw-bolder">
          <a routerLink="/bpm-and-bpo-services">Services</a>
          <ul>
            <li class="fw-bolder">
              <a routerLink="/services/insurance-bpo-outsourcing"
                >Insurance Outsourcing Services</a
              >
              <ul>
                <li class="fw-500">
                  <a routerLink="/services/insurance-policy-processing"
                    >Policy Processing</a
                  >
                </li>
                <li class="fw-500">
                  <a routerLink="/services/insurance-claim-management"
                    >Claims Management</a
                  >
                </li>
                <li class="fw-500">
                  <a
                    routerLink="/services/insurance-agency-optimization-services"
                    >Agency Optimization</a
                  >
                </li>
                <li class="fw-500">
                  <a routerLink="/services/insurance-new-business-servicing"
                    >New Business</a
                  >
                </li>
              </ul>
            </li>
            <li class="fw-bolder">
              <a routerLink="/services/data-annotation-services"
                >Data Annotation
              </a>
              <ul>
                <li class="fw-500">
                  <a routerLink="/services/text-annotation-services"
                    >Text Annotation</a
                  >
                </li>
                <li class="fw-500">
                  <a
                    routerLink="/services/video-annotation-outsourcing-services"
                    >Video Annotation</a
                  >
                </li>
                <li class="fw-500">
                  <a
                    routerLink="/services/image-annotation-outsourcing-services"
                    >Image Annotation</a
                  >
                </li>
              </ul>
            </li>
            <li class="fw-bolder">
              <a routerLink="/services/accounting-bookkeeping-services"
                >Accounting & Bookkeeping Services</a
              >
              <ul>
                <li class="fw-500">
                  <a routerLink="/services/accounts-payable-management"
                    >Payable Management</a
                  >
                </li>
                <li class="fw-500">
                  <a routerLink="/services/accounts-receivable-management"
                    >Receivable Management</a
                  >
                </li>
                <li class="fw-500">
                  <a
                    routerLink="/services/accounting-and-financial-reporting-services"
                    >Financial Reporting</a
                  >
                </li>
                <li class="fw-500">
                  <a routerLink="/services/payroll-processing-services"
                    >Payroll Processing</a
                  >
                </li>
                <li class="fw-500">
                  <a routerLink="/services/accounts-reconciliation-services"
                    >Reconciliation</a
                  >
                </li>
                <li class="fw-500">
                  <a routerLink="/services/general-ledger-accounting-services"
                    >General Ledger Accounting</a
                  >
                </li>
              </ul>
            </li>
            <li class="fw-500">
              <a routerLink="/services/data-visualization-services"
                >Data Visualization</a
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</section>
