import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { ServiceCaseStudyComponent } from '../../../../../components/HomeComponents/service-case-study/service-case-study.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { RouterLink, RouterOutlet } from '@angular/router';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-pre-sales-support',
  standalone: true,
  templateUrl: './logistic-management.component.html',
  styleUrl: './logistic-management.component.css',
  imports: [
    FaqComponent,
    ServiceCaseStudyComponent,
    KnowBestComponent,
    RouterLink,
    RouterOutlet,
    CdnUrlDirective,
    ourAssetsComponent,
  ],
})
export class LogisticManagement implements OnInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'Logistics Management Services (LMS) |  Ecommerce BPO | FBSPL ',
      description:
        'Our logistics management team ensure efficient logistics, supply chain management and timely deliveries for your e-commerce business.',
      keywords: 'ecommerce logistics management, ecommerce bpo',
      ogImage: '/ogImage/Logistic Management - E-com Main Banner 03.jpg',
    };
    this.addJsonLdScript();
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `
      { 

  "@context": "https://schema.org", 

  "@type": "FAQPage", 

  "mainEntity": [ 

    { 

      "@type": "Question", 

      "name": "How do you ensure logistics accuracy?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "We take a multifaceted approach when it comes to establishing logistics accuracy. Some of the strategies include: 

<ul><li>Leveraging route optimization software to reduce cost and fuel consumption</li> 

<li>Promptly addressing any unforeseen events causing delivery delays</li> 

<li>Implementing quality checks to ensure products meet set quality standards before leaving the warehouse</li> 

<li>Tracking the movement and availability of stocks in real-time</li> 

<li>Automating procedures and reducing errors</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do your logistics management services help me ensure the safe delivery of fragile items?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "To ensure the safe delivery of fragile items, our team: 

<ul><li>Undergoes regular training to stay updated with the latest fragile item handling strategies</li> 

<li>Familiarizes you with advanced handling equipment that reduce jerking and other impacts during loading, unloading, and transportation</li> 

<li>Provides detailed handling instructions to personnel involved in last-mile delivery services</li> 

<li>Closely monitors the entire shipping process</li> 

<li>Conducts quality checks of delivered products to ensure customers receive them in sound condition</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "How do you address sudden disruptions in the supply chain?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Addressing sudden disruptions in the supply chain requires expertise in collaborative technologies and strategic planning. Our capabilities include: 

<ul><li>Identifying risk patterns to mitigate their impact on supply chains</li> 

<li>Maintaining diversified routes, suppliers, and transportation options to ensure continuous and punctual deliveries</li> 

<lli>Utilizing an effective communication system for real-time information sharing</li> 

<li>Implementing well-defined emergency response protocols for a proactive response to unexpected events</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "What sets you apart in return management services?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "Our client-first approach sets us apart in all our outsourcing services, including return management. Here's how: 

<ul><li>Our customer support representatives ensure a positive return experience for your customers by assisting them on the journey</li> 

<li>We provide you with detailed analytics on returned items to help you identify trends and areas of improvement.</li> 

<li>By tracking the entire reverse logistics process, our experts give you real-time visibility of the returned items.</li> 

<li>Our logistics management services prioritize sustainable practices, ensuring that returned items are disposed of responsibly or donated when appropriate.</li></ul>" 

      } 

    }, 

    { 

      "@type": "Question", 

      "name": "Why should I choose you as my right ecommerce outsourcing service provider?", 

      "acceptedAnswer": { 

        "@type": "Answer", 

        "text": "You should partner with us if you are looking for: 

<ul><li>360-degree assistance that meets your dynamic business needs round the clock</li> 

<li>Professionally trained employees</li> 

<li>Buck-up services at zero additional cost</li> 

<li>Dedicated customer support, vendor management, and much more</li></ul> 

Partnering with us means aligning with the industry excellence. " 

      } 

    } 

  ] 

}  `;
        this.renderer.appendChild(this.document.head, script);
      }
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `
      {  

  

  "@context": "https://schema.org",  

   "@type": "BreadcrumbList",  

   "itemListElement": [  

     {  

       "@type": "ListItem",  

       "position": 1,  

       "name": "Home",  

       "item": "https://www.fbspl.com/"  

     },  

     {  

       "@type": "ListItem",  

       "position": 2,  

       "name": "Services",  

       "item": "https://www.fbspl.com/bpm-and-bpo-services"  

     },  

     {  

  

      "@type": "ListItem",  

       "position": 3,  

       "name": "eCommerce",  

       "item": "https://www.fbspl.com/services/e-commerce-outsourcing-services"  

     },  

     {  

  

      "@type": "ListItem",  

  

      "position": 4,  

       "name": "Logistics Management Services",  

       "item": "https://www.fbspl.com/services/e-commerce-logistic-management"  

     }  

  

  ]  

  

}  `;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }
}
