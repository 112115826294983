import {
  Component,
  OnInit,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import {
  Router,
  NavigationEnd,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { CdnUrlDirective } from '../../directives/cdn-url.directive';
import { SeoData } from '../../services/seo/seo-data.model';
import { SeoService } from '../../services/seo/seo.service';
import { ThinkingSection } from '../../components/ThinkingSection/ThinkingSection.component';
import { SliderComponent } from '../../components/Slider/Slider.component';
import { InfographicComponent } from '../../components/Infographic/Infographic.component';
import { KnowBestComponent } from '../../components/HomeComponents/know-best/know-best.component';
import { VimeoPlayerComponent } from '../../components/vimeo-player/vimeo-player.component';
import { SubmitBtn } from '../../components/SubmitBtn/SubmitBtn.component';
import { ourAssetsComponent } from '../../components/HomeComponents/ourAssets/ourAssets.component';
import BannerAnimation from '../../../assets/Animations/New Hologram Json 1.json';
import bodymovin from 'lottie-web';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  standalone: true,
  imports: [
    RouterLink,
    RouterOutlet,
    CdnUrlDirective,
    ThinkingSection,
    SliderComponent,
    InfographicComponent,
    ourAssetsComponent,
    KnowBestComponent,
    VimeoPlayerComponent,
    SubmitBtn
],
})
export class HomeComponent implements OnInit, AfterViewInit {
  counters: number[] = [0, 0, 0, 0];
  targetValues: number[] = [60, 25, 50, 80];

  constructor(
    private seoService: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    const seoData: SeoData = {
      title: 'Leading Global Consulting and BPM Firm | FBSPL',
      description:
        'Simplify your operations with FBSPL, a global leader in BPM and outsourcing. Experience growth-driven solutions with a blend of human intelligence + AI',
      keywords: 'bpm, business process management solutions',
      ogImage: 'https://d1585q1wairh36.cloudfront.net/assets/Logo/Logo.png',
    };
    this.seoService.setMetaTags(seoData);
  }
  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.incrementCounters();
      const animationInline = bodymovin.loadAnimation({
        container: document.getElementById('animationInsurance') as HTMLElement,
        autoplay: true,
        renderer: 'svg',
        loop: true,
        animationData: BannerAnimation,
      });
    }
  }

  incrementCounters() {
    // Iterate through each counter and target value, and increment each one
    for (let i = 0; i < this.counters.length; i++) {
      this.animateCounter(i, this.targetValues[i]);
    }
  }

  animateCounter(index: number, targetValue: number) {
    let value = 0;
    const interval = setInterval(() => {
      if (value < targetValue) {
        value++;
        this.counters[index] = value; // Update the correct counter
      } else {
        clearInterval(interval);
      }
    }, 70);
  }
}
