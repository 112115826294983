import { Component, Input } from '@angular/core';

import { environment } from '../../../environments/environment';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'SubmitBtn',
  standalone: true,
  templateUrl: './SubmitBtn.component.html',
  imports: [RouterLink],
})
export class SubmitBtn {
  @Input() text: string = '';
  @Input() link: string = '';
  cdnUrl = environment.cdnUrl;
  imageSrc = `${this.cdnUrl}assets/Icon/white-vector.svg`;

  // Change image source on hover
  changeImageOnHover(): void {
    this.imageSrc = `${this.cdnUrl}assets/Icon/blue-vector.svg`;
  }

  // Reset image source when not hovering
  resetImage(): void {
    this.imageSrc = `${this.cdnUrl}assets/Icon/white-vector.svg`;
  }
}
