import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class HomeApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getOurThinkingData(category: string): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/api/home/data?category=${category}`);
  }
}
