import { Routes } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { OurLeaderComponent } from './pages/our-leader/our-leader.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { LifeFbsplComponent } from './pages/life-fbspl/life-fbspl.component';
import { CSRComponent } from './pages/csr/csr.component';
import { BlogComponent } from './pages/blog/blog.component';
import { BlogPageComponent } from './pages/blog/blog-page/blog-page.component';
import {GuideComponent} from './pages/guide/guide.component';
import {GuidePageComponent} from './pages/guide/guide-page/guide-page.component';
import { PodcastComponent } from './pages/podcast/podcast.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { PodcastPageComponent } from './pages/podcast/podcast-page/podcast-page.component';
import { BookAConsultationComponent } from './pages/bookAConsultation/bookAConsultation.component';
import { CurrentOpeningComponent } from './pages/currentOpening/currentOpening.component';
import { JdComponent } from './pages/jd/jd.component';
import { ErrorComponent } from './pages/error/error.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { ContactUsComponent } from './pages/contactUs/contactUs.component';
import { CareerFormComponent } from './pages/career-form/career-form.component';
import { PrivacyPolicyComponent } from './pages/PrivacyPolicy/PrivacyPolicy.component';
import { TermsConditionComponent } from './pages/TermsCondition/TermsCondition.component';
import { GDPRComponent } from './pages/GDPR/GDPR.component';
import { securityMeasureComponent } from './pages/securityMeasure/securityMeasure.component';
import { NewsroomComponent } from './pages/newsroom/newsroom.component';
import { NewsPageComponent } from './pages/newsroom/news-page/news-page.component';
import { MainServiceComponent } from './pages/services/main-service.component';
import { CareerComponent } from './pages/career/career.component';
import { CaseStudyComponent } from './pages/case-study/case-study.component';
import { CaseStudyPageComponent } from './pages/case-study/caseStudy-page/cspage.component';
import { CareerThankyouComponent } from './pages/careerThankYou/careerThankYou.component';
import { SiteMapComponent } from './pages/sitemap/sitemap.component';
import { LPThankyouComponent } from './pages/LpThankyou/thankyou.component';
import { AccountingBookkeepingComponent } from '../app/pages/services/accounting-bookkeeping/accounting-bookkeeping.component';
import { PayableManagementComponent } from '../app/pages/services/accounting-bookkeeping/Sub-Pages/payable-management/payable-management.component';
import { RecievableManagementComponent } from '../app/pages/services/accounting-bookkeeping/Sub-Pages/recievable-management/recievable-management.component';
import { FinancialReportingComponent } from '../app/pages/services/accounting-bookkeeping/Sub-Pages/financial-reporting/financial-reporting.component';
import { PayrollProcessingComponent } from '../app/pages/services/accounting-bookkeeping/Sub-Pages/payroll-processing/payroll-processing.component';
import { ReconciliationComponent } from '../app/pages/services/accounting-bookkeeping/Sub-Pages/reconciliation/reconciliation.component';
import { GeneralLedgerAccountingComponent } from '../app/pages/services/accounting-bookkeeping/Sub-Pages/general-ledger-accounting/general-ledger-accounting.component';
import { CustomerSupportComponent } from '../app/pages/services/customer-support/customer-support.component';
import { DigitalMarketingComponent } from '../app/pages/services/digital-marketing/digital-marketing.component';
import { ServicesComponent } from '../app/pages/services/Ecommerce/services.component';
import { PreSalesSupport } from '../app/pages/services/Ecommerce/Sub-service/pre-sales-support/pre-sales-support.component';
import { OrderManagement } from '../app/pages/services/Ecommerce/Sub-service/Order Management/order-management.component';
import { CatalogManagement } from '../app/pages/services/Ecommerce/Sub-service/Catalog Management/catalog-management.component';
import { InventoryManagement } from '../app/pages/services/Ecommerce/Sub-service/Inventory Management/inventory-management.component';
import { DataManagement } from '../app/pages/services/Ecommerce/Sub-service/Data Management/data-management.component';
import { InsuranceAgencyOptimizationComponent } from '../app/pages/services/insurance-agency-optimization/insurance-agency-optimization.component';
import { ServiceRPOComponent } from '../app/pages/services/service-rpo/service-rpo.component';
import { PolicyProcessingComponent } from '../app/pages/services/Insurance/sub-service-page/policy-processing/policy-processing.component';
import { newBusinessComponent } from '../app/pages/services/Insurance/sub-service-page/new-business/new-business.component';
import { claimManagement } from '../app/pages/services/Insurance/sub-service-page/claim-management/claim-management.component';
import { LogisticManagement } from '../app/pages/services/Ecommerce/Sub-service/Logistic Management/logistic-management.component';
import { Insurance } from '../app/pages/services/Insurance/Insurance.component';
import { DataAnnotationComponent } from '../app/pages/services/data-annotation/data-annotation.component';
import { TextAnnotation } from './pages/services/data-annotation/subServices/text-annotation/text-annotation.component';
import { VideoAnnotation } from './pages/services/data-annotation/subServices/video-annotation/video-annotation.component';
import { ImageAnnotation } from './pages/services/data-annotation/subServices/image-annotation/image-annotation.component';
import { DataVisualizationComponent } from './pages/services/Data-visualization/data-visualization.component';
export const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'about-who-we-are',
    component: AboutUsComponent,
  },
  {
    path: 'our-leadership',
    component: OurLeaderComponent,
  },
  {
    path: 'csr-social-responsibilities',
    component: CSRComponent,
  },
  {
    path: 'life-at-fbspl',
    component: LifeFbsplComponent,
  },
  {
    path: 'services/accounting-bookkeeping-services',
    component: AccountingBookkeepingComponent,
  },
  {
    path: 'services/accounts-payable-management',
    component: PayableManagementComponent,
  },
  {
    path: 'services/accounts-receivable-management',
    component: RecievableManagementComponent,
  },
  {
    path: 'services/accounting-and-financial-reporting-services',
    component: FinancialReportingComponent,
  },
  {
    path: 'services/payroll-processing-services',
    component: PayrollProcessingComponent,
  },
  {
    path: 'services/accounts-reconciliation-services',
    component: ReconciliationComponent,
  },
  {
    path: 'services/general-ledger-accounting-services',
    component: GeneralLedgerAccountingComponent,
  },
  { path: 'services/customer-support-outsourcing', component: CustomerSupportComponent },
  { path: 'services/digital-marketing', component: DigitalMarketingComponent },
  { path: 'services/e-commerce-outsourcing-services', component: ServicesComponent },
  { path: 'services/e-commerce-pre-sales-support', component: PreSalesSupport },
  { path: 'services/e-commerce-order-management', component: OrderManagement },
  { path: 'services/e-commerce-catalog-management', component: CatalogManagement },
  { path: 'services/e-commerce-inventory-management', component: InventoryManagement },
  { path: 'services/e-commerce-data-management', component: DataManagement },
  { path: 'services/e-commerce-logistic-management', component: LogisticManagement },
  {
    path: 'services/insurance-agency-optimization-services',
    component: InsuranceAgencyOptimizationComponent,
  },
  {
    path: 'services/recruitment-process-outsourcing-services',
    component: ServiceRPOComponent,
  },
  { path: 'services/insurance-bpo-outsourcing', component: Insurance },
  { path: 'services/insurance-policy-processing', component: PolicyProcessingComponent },
  { path: 'services/insurance-new-business-servicing', component: newBusinessComponent },
  { path: 'services/insurance-claim-management', component: claimManagement },
  { path: 'services/data-annotation-services', component: DataAnnotationComponent },
  {
    path: 'bpm-and-bpo-services',
    component: MainServiceComponent,
  },
  { path: 'services/text-annotation-services', component: TextAnnotation },
  { path: 'services/video-annotation-outsourcing-services', component: VideoAnnotation },
  { path: 'services/image-annotation-outsourcing-services', component: ImageAnnotation },
  { path: 'services/audio-annotation', component: TextAnnotation },
  { path: 'services/synthetic-data-annotation', component: TextAnnotation },
  { path: 'services/data-visualization-services', component: DataVisualizationComponent },
  {
    path: 'blogs',
    loadChildren: () =>
      import('./pages/blog/blog.module').then((m) => m.BlogModule),
    
  },
  {
    path: 'guides',
    component: GuideComponent,
  },
  {
    path: 'guides/:slug',
    component: GuidePageComponent,
  },
  {
    path: 'podcast',
    component: PodcastComponent,
  },
  {
    path: 'podcast/:slug',
    component: PodcastPageComponent,
  },
  {
    path: 'news',
    component: NewsroomComponent,
  },
  {
    path: 'news/:slug',
    component: NewsPageComponent,
  },
  {
    path: 'client-testimonial-reviews',
    component: TestimonialsComponent,
  },
  {
    path: 'book-free-consultation-call',
    component: BookAConsultationComponent,
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
  },
  {
    path: 'careers/current-openings',
    component: CurrentOpeningComponent,
  },
  {
    path: 'jd',
    component: JdComponent,
  },
  {
    path: 'thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'book-free-consultation-call/thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'services/thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'careers/thankyou',
    component: CareerThankyouComponent,
  },
  {
    path: 'career-apply-thankyou',
    component: CareerThankyouComponent,
  },
  {
    path: 'alliances-and-partnership-with-fbspl/thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'media-and-general-query/thankyou',
    component: ThankyouComponent,
  },
  {
    path: 'website-feedback/thankyou',
    component: ThankyouComponent,
  },
  // {
  //   path: 'event-applied-net-2024/thank-you',
  //   component: LPThankyouComponent,
  // },
  {
    path: 'terms-&-condition',
    component: TermsConditionComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  // {
  //   path: 'GDPR',
  //   component: GDPRComponent,
  // },
  {
    path: 'sitemap',
    component: SiteMapComponent,
  },
  {
    path: 'security-measures',
    component: securityMeasureComponent,
  },
  {
    path: 'apply-now',
    component: CareerFormComponent,
  },
  {
    path: 'careers',
    component: CareerComponent,
  },
  {
    path: 'case-studies',
    component: CaseStudyComponent,
  },
  {
    path: 'case-studies/:slug',
    component: CaseStudyPageComponent,
  },

  // {
  //   path: 'new',
  //   loadChildren: () => import('./pages/new/new.routes').then(routes => routes.routes)
  // },
  { path: '404', component: ErrorComponent },
  { path: 'blog/:slug', redirectTo: '/blogs/:slug' },
  { path: 'blog', redirectTo: '/blogs' },
  { path: 'career', redirectTo: '/careers' },
  { path: 'cases', redirectTo: '/case-studies' },
  { path: '**', redirectTo: '/404' },
];
