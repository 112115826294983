<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Sub-service-Pages/policy 1 1.png"
      class="card-img-desktop desktop-banner"
      alt="FBSPL Claim Management Services"
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/data-annotation-services">Data Annotation</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Image Annotation
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Image Annotation Outsourcing Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Transform raw images into AI-driven datasets with expert-driven
            image annotation services. From 2D/3D bounding boxes to semantic
            segmentation and skeletal annotation, we label every object, action,
            and pattern, ensuring your AI models learn accurately.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Annotate image now
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      alt="FBSPL Claim Management Services"
      appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
      class="card-img-mob"
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/data-annotation-services">Data Annotation</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Image Annotation
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Image Annotation Outsourcing Services
        </h1>
        <p class="card-text font-20-16">
          Transform raw images into AI-driven datasets with expert-driven image
          annotation services. From 2D/3D bounding boxes to semantic
          segmentation and skeletal annotation, we label every object, action,
          and pattern, ensuring your AI models learn accurately.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Annotate image now
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section class="container-fluid pt-5">
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Accurately labeled image annotation services  
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#TextCategorization">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  2D Bounding Boxes & 3D Cuboid Annotation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Our bounding box annotation services are designed to improve
                your AI object’s detection capabilities. Whether it is
                identifying pedestrians for autonomous... <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#FullPartialAccountReconciliation"
          >
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Semantic Segmentation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                What if your AI could comprehend complex scenes with pixel-level
                precision? With our semantic segmentation services, we
                accurately label every pixel....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#VendorInvoiceReconciliation"
          >
            <div
              class="card bordered serviceCards Loss_Runs_Processing"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Polygon Annotation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                For irregular shaped objects, get accurate polygon annotation
                services where we do exact labeling down to every edge and
                curve....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#BankStatementReconciliation"
          >
            <div
              class="card bordered serviceCards Policy_Renewals"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Key Point Annotation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Have you ever wondered how AI tracks motion or understands human
                gestures? Professional key point annotation makes it
                possible!...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#PettyCashReconciliation">
            <div
              class="card bordered serviceCards Policy_Management"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Image Classification
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Can your AI quickly and accurately categorize massive visual
                datasets? With our image classification services, we do accurate
                image...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <div
              class="card bordered serviceCards Policy_Endorsements"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Skeletal Annotation
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                How truly does your AI understand movement? Our skeletal
                annotation services bring precision to motion tracking by
                mapping joints....
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#TextCategorization"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">
              2D Bounding Boxes & 3D Cuboid Annotation
            </h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#FullPartialAccountReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Semantic Segmentation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#VendorInvoiceReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Polygon Annotation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#BankStatementReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Key Point Annotation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#PettyCashReconciliation"
          >
            <h3 class="CTAs text-black fs-24 me-3">Image Classification</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#AccountingSystemIntegration"
          >
            <h3 class="CTAs text-black fs-24 me-3">Skeletal Annotation</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="TextCategorization"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="TextCategorizationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">
              2D Bounding Boxes & 3D Cuboid Annotation
            </h3>
            <p class="text-dark font-20-16">
              Our bounding box annotation services are designed to improve your
              AI object’s detection capabilities. Whether it is identifying
              pedestrians for autonomous vehicles or objects in a 3D space for
              AR/VR apps, we offer precise image annotation services. Our data
              annotators carefully label objects across frames, ensuring your AI
              models can accurately interpret spatial relationships and depth.
              With our data annotation solutions, make your AI smarter than
              ever!
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />2D and 3D object localization
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Accurate object classification
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Depth and spatial relationship analysis
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Applications for robotics and AI vision
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 198.webp"
              alt="2D Bounding Boxes & 3D Cuboid Annotation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="FullPartialAccountReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="FullPartialAccountReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Semantic Segmentation</h3>
            <p class="text-dark font-20-16">
              What if your AI could comprehend complex scenes with pixel-level
              precision? With our semantic segmentation services, we accurately
              label every pixel in an image so that your AI models can interpret
              intricate environments. Our experienced data annotators assist AI
              models in creating a comprehensive contextual understanding of
              their surroundings. Hence, it empowers AI models to make smarter
              decisions identifying anomalies in medical imaging to detecting
              road signs in vehicles.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Pixel-level annotation for scene understanding
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Classification of objects and backgrounds
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Enhanced training for robotics and AR/VR applications
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Multi-class labeling for complex environments
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 199.webp"
              alt="Budgeting & Forecasting Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="VendorInvoiceReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="VendorInvoiceReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Polygon Annotation</h3>
            <p class="text-dark font-20-16">
              For irregular shaped objects, get accurate polygon annotation
              services where we do exact labeling down to every edge and curve.
              Ideal for advanced image recognition, aerial mapping, and
              environmental monitoring, we ensure no detail is missed while
              annotating images. Our image data annotation team specializes in
              creating pixel-perfect annotations to train your AI model to
              expertly interpret irregular shapes and objects. With us, get
              precision you require to build robust AI models.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Annotation of irregular object shapes
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Accurate boundary mapping for detailed recognition
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Applications in satellite imagery and environmental studies
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />High precision for AI training datasets
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 200.webp"
              alt="Semantic Segmentation Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="BankStatementReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="BankStatementReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Key Point Annotation</h3>
            <p class="text-dark font-20-16">
              Have you ever wondered how AI tracks motion or understands human
              gestures? Professional key point annotation makes it possible! We
              assist AI models in accurately interpreting movement by marking
              important points, such as skeleton joints, object markers, or
              facial landmarks. Our data annotation team provides the
              information required to advance your initiatives, whether it is
              improving facial recognition software or evaluating sports
              performance.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Annotation for skeletal landmarks and facial joints
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Real-time object identification
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Action and behavior recognition
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Ensuring accurate data for AI training
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 201.webp"
              alt="Key Point Annotation  Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  class="modal fade"
  id="PettyCashReconciliation"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="PettyCashReconciliationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Image Classification</h3>
            <p class="text-dark font-20-16">
              Can your AI quickly and accurately categorize massive visual
              datasets? With our image classification services, we do accurate
              image labeling and classification. Whether you want to tag
              products for personalized e-commerce experiences or sorting
              medical images for precise diagnostics, our image annotation
              solutions can help. Our team combines advanced annotation
              techniques with a detailed understanding to ensure your AI model
              learns faster and smarter.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Tagging dynamic actions and events
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Sequence classification for activity recognition
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Image categorization for AI models
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Facial recognition and security applications
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 202.webp"
              alt="Image Classification"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="AccountingSystemIntegration"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="AccountingSystemIntegrationLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Skeletal Annotation</h3>
            <p class="text-dark font-20-16">
              How truly does your AI understand movement? Our skeletal
              annotation services bring precision to motion tracking by mapping
              joints, postures, and movements. Whether you want to enhance
              sports performance or develop wearable fitness technology, our
              annotation solutions provide the foundation for real-time motion
              tracking. With a focus on precision and real-time application, we
              ensure your AI models excel in dynamic and ever-evolving
              environments.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Precise skeletal structure labeling
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Motion analysis in sports and healthcare
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />AR/VR and gaming development
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Real-time movement tracking
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/accoutning genral ledger/Group 203.webp"
              alt="Skeletal Annotation  Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven text annotation solutions
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="SuperAnnotate "
          appCdnUrl="assets/Tools/superAnnotate.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Adobe Acrobate  "
          appCdnUrl="assets/Tools/adobeacrobat.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="200">
        <img class="img-fluid" alt="nitro" appCdnUrl="assets/Tools/Nitro.svg" />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="labelme"
          appCdnUrl="assets/Tools/Labelme.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="400">
        <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="SuperAnnotate  "
            appCdnUrl="assets/Tools/superAnnotate.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt="Adobe Acrobate   "
            appCdnUrl="assets/Tools/adobeacrobat.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt="nitro"
            appCdnUrl="assets/Tools/Nitro.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="labelme"
            appCdnUrl="assets/Tools/Labelme.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="0">
          <img
            class="img-fluid"
            alt="SuperAnnotate  "
            appCdnUrl="assets/Tools/superAnnotate.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="100">
          <img
            class="img-fluid"
            alt="Adobe Acrobate   "
            appCdnUrl="assets/Tools/adobeacrobat.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="200">
          <img
            class="img-fluid"
            alt="nitro"
            appCdnUrl="assets/Tools/Nitro.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="300">
          <img
            class="img-fluid"
            alt="labelme"
            appCdnUrl="assets/Tools/Labelme.svg"
          />
        </div>
        <div class="trust-slide" data-aos="fade-right" data-aos-delay="400">
          <img class="img-fluid" alt="cvat" appCdnUrl="assets/Tools/cvat.svg" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Thinking -->
<ThinkingSection category="dataannotation"></ThinkingSection>

<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>

    <div class="mt-3 mt-md-0">
      <div data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                Do you offer data annotation services for video as well?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Yes, we also offer video annotation services, including
                frame-by-frame video labeling and object tracking for dynamic
                events. Our data annotation solutions are especially useful for
                autonomous driving, surveillance, and sports analysis
                applications.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                How do you ensure consistency across large datasets?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                We employ stringent quality assurance procedures to ensure
                accuracy and consistency throughout all annotations. Our
                comprehensive image annotation process includes detailed
                guidelines, multi-level reviews, advanced annotation tools, and
                expert validation to deliver precise results.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                What is the turnaround time for a typical image annotation
                project?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                The size and complexity of the project determine the turnaround
                time. After examining your specifications, we will provide an
                approximate time frame and strive to meet your deadlines. Still,
                with FBSPL, you can expect 30% faster turnaround time.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                What are the different types of image annotation services FBSPL
                offers?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Our services aren’t restricted to a specific type of image
                annotation. From 2D bounding boxes and 3D cuboids to semantic
                segmentation, key point annotation, and skeletal annotation, we
                can annotate all types of images. Feel free to share your
                project requirements for image annotation and leave the rest to
                our experts.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingfive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Process"
                aria-expanded="false"
                aria-controls="Process"
              >
                How can I get started with image annotation services?
              </button>
            </h2>
            <div
              id="Process"
              class="accordion-collapse collapse"
              aria-labelledby="headingfive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Getting started with our image annotation services are just a
                few steps process! Visit our contact us page, fill in the form,
                and one of our data annotation experts will contact you. They
                will gather your project requirements and offer customized
                solutions accordingly.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
