import {
  Component,
  OnInit,
  Renderer2,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { SeoService } from '../../../../../services/seo/seo.service';
import { SeoData } from '../../../../../services/seo/seo-data.model';
import { FaqComponent } from '../../../../../components/HomeComponents/faq/faq.component';
import { KnowBestComponent } from '../../../../../components/HomeComponents/know-best/know-best.component';
import { CdnUrlDirective } from '../../../../../directives/cdn-url.directive';
import { ourAssetsComponent } from '../../../../../components/HomeComponents/ourAssets/ourAssets.component';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ThinkingSection } from '../../../../../components/ThinkingSection/ThinkingSection.component';

@Component({
  selector: 'app-text-annotation',
  standalone: true,
  templateUrl: './text-annotation.component.html',
  styleUrl: './text-annotation.component.css',
  imports: [
    FaqComponent,
    KnowBestComponent,
    CdnUrlDirective,
    ourAssetsComponent,
    ThinkingSection,
  ],
})
export class TextAnnotation implements OnInit {
  constructor(
    private meta: Meta,
    private seoService: SeoService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}
  ngOnInit(): void {
    this.addJsonLdScript();
    const seoData: SeoData = {
      title: ' Text Annotation Outsourcing Services | FBSPL',
      description:
        'Outsource text annotation services for NLP tasks like sentiment analysis and entity recognition. Get accurately labeled text datasets for your AI/ML models with FBSPL.',
      keywords: 'text annotation outsourcing',
      ogImage: '/Sub-service-Pages/policy 1 1.png',
    };
    this.seoService.setMetaTags(seoData);
  }
  private addJsonLdScript() {
    if (isPlatformBrowser(this.platformId)) {
      if (
        !this.document.head.querySelector('script[type="application/ld+json"]')
      ) {
        const script = this.renderer.createElement('script');
        script.type = 'application/ld+json';
        script.text = `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [
    {
      "@type": "Question",
      "name": "How can you improve your NLP tasks with our text annotation services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our services guarantee accurate data tagging, which enables NLP models to comprehend language structures, context, and relationships. It enhances grammatical checks, knowledge graph building, and sentiment analysis."
      }
    },
    {
      "@type": "Question",
      "name": "How does your team handle ambiguous text data during annotation?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our data annotation experts establish guidelines, expert judgment, and collaborative reviews to resolve ambiguities and ensure annotations align with your project objectives. You can get 100% accurate text annotation with our data annotation assistance."
      }
    },
    {
      "@type": "Question",
      "name": "How do you ensure confidentiality and security during text annotation services?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "When you choose FBSPL to outsource text annotation or any data annotation service, ensure security and confidentiality. We adhere to strict security protocols and comply with standard protocols to safeguard sensitive data."
      }
    },
    {
      "@type": "Question",
      "name": "What type of text annotation services do you offer?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Our services aren’t specific to a particular text annotation activity. From text classification, categorization, semantic annotation, entity linking, and phrase chunking to linguistic annotation and metadata labeling, we can offer all types of text annotation outsourcing solutions."
      }
    },
    {
      "@type": "Question",
      "name": "What makes FBSPL’s text annotation services different from the rest?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We combine expert-driven precision, advanced tools, and customized solutions to deliver accurate text annotation services. We focus on data security to offer unparalleled support for your AI and ML projects."
      }
    }
  ]
}`;
        this.renderer.appendChild(this.document.head, script);
      }
      // Check and add BreadcrumbList script
      if (
        !this.document.head.querySelector(
          'script[type="application/ld+json"][data-script="breadcrumb"]'
        )
      ) {
        const breadcrumbScript = this.renderer.createElement('script');
        breadcrumbScript.type = 'application/ld+json';
        breadcrumbScript.setAttribute('data-script', 'breadcrumb');
        breadcrumbScript.text = `
  { 
  "@context": "https://schema.org", 
  "@type": "BreadcrumbList", 
  "itemListElement": [ 
    { 
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home", 
      "item": "https://www.fbspl.com/" 
    }, 
    { 
      "@type": "ListItem", 
      "position": 2, 
      "name": "Services", 
      "item": "https://www.fbspl.com/bpm-and-bpo-services" 
    }, 
    { 
      "@type": "ListItem", 
      "position": 3, 
      "name": "Data Annotation", 
      "item": "https://www.fbspl.com/services/data-annotation-services" 
    }, 
    { 
      "@type": "ListItem", 
      "position": 4, 
      "name": "Text Annotation", 
      "item": "https://www.fbspl.com/services/text-annotation-services" 
    } 
  ] 
} `;
        this.renderer.appendChild(this.document.head, breadcrumbScript);
      }
    }
  }
}
