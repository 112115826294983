<section
  class="container-fluid pt-5 d-none d-lg-block"
  style="overflow: hidden"
>
  <div class="new_container text-center">
    <h4 class="text-primary font-48-30 text-center">Why choose us</h4>
  </div>

  <div class="container">
    <div class="row justify-content-center">
      <div
        class="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4 mb-lg-0 px-0"
      >
        <div class="circle_border">
          <div class="div_center circle_main0">
            <div style="text-align: -webkit-center">
              <span class="img_border">
                <img
                  src="https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/bookACall.svg"
                />
              </span>
            </div>
            <h5 class="text-primary font-28-20">AI + human = growth</h5>
            <p class="font-24-16">
              A powerful blend of AI and human expertise for real results.
            </p>
          </div>
          <!-- <div class="bottom_circle"><h5>1</h5></div> -->
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4 mb-lg-0 px-0"
      >
        <div class="circle_border">
          <!-- <div class="top_circle"><h5>2</h5></div> -->
          <div class="div_center circle_main">
            <div style="text-align: -webkit-center">
              <span class="img_border">
                <img
                  src="https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/fillTheForm.svg"
                />
              </span>
            </div>
            <h5 class="text-primary font-28-20">Your business, your way</h5>
            <p class="font-24-16">
              Services that fit your business, with teams aligning to your
              time-zone.
            </p>
          </div>
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4 mb-lg-0 px-0"
      >
        <div class="circle_border">
          <div class="div_center circle_main1">
            <div style="text-align: -webkit-center">
              <span class="img_border">
                <img
                  src="https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/Domain.svg"
                />
              </span>
            </div>
            <h5 class="text-primary font-28-20">All-in-one support</h5>
            <p class="font-24-16">
              Complete support with dedicated resources and back-up staff.
            </p>
          </div>
          <!-- <div class="bottom_circle"><h5>3</h5></div> -->
        </div>
      </div>
      <div
        class="col-lg-3 col-md-6 col-12 d-flex align-items-center justify-content-center mb-4 mb-lg-0 px-0"
      >
        <div class="circle_border">
          <!-- <div class="top_circle"><h5>4</h5></div> -->
          <div class="div_center">
            <div style="text-align: -webkit-center">
              <span class="img_border">
                <img
                  src="https://d2x053sc4cmboc.cloudfront.net/LP/Accounting&Bookkeeping/scopeOfWork.svg"
                />
              </span>
            </div>
            <h5 class="text-primary font-28-20">Client first, always</h5>
            <p class="font-24-16">
              We're not just a service provider; we're your partner in growth.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section
  class="container-fluid py-5 d-block d-lg-none"
  style="overflow: hidden"
>
  <div class="new_container text-center">
    <h3 class="text-primary font-36-24">Why choose us</h3>
  </div>

  <div class="row blog_slider justify-content-center">
    <div class="d-flex align-items-center justify-content-center mb-4 mb-lg-0">
      <ngx-slick-carousel
        class="slick-slider"
        #slickModal="slick-carousel"
        [config]="slickConfig"
      >
        @for (slide of slides; track slide) {
        <div ngxSlickItem class="slide">
          <div class="circle_border">
            <div class="div_center circle_main0">
              <div style="text-align: -webkit-center">
                <span class="img_border">
                  <img [src]="slide.img" />
                </span>
              </div>
              <h5 class="text-primary font-28-20">
                {{ slide.headingContent }}
              </h5>
              <p class="fs-18">{{ slide.parra }}</p>
            </div>
            <!-- <div class="bottom_circle">
              <h5>{{ slide.step }}</h5>
            </div> -->
          </div>
        </div>
        }
      </ngx-slick-carousel>
    </div>
  </div>
</section>
