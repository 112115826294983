<div class="container text-dark d-none d-lg-block">
  <div class="gradientBanner">
    <img
      appCdnUrl="assets/Sub-service-Pages/policy 1 1.png"
      class="card-img-desktop desktop-banner"
      alt="FBSPL Claim Management Services"
    />
    <div class="card-img-overlay overlay-text bannerText">
      <div class="main_bannar pt-3">
        <div data-aos="fade-up">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home</a></li>
              <li class="breadcrumb-item" aria-current="page">
                <a href="/bpm-and-bpo-services">Services</a>
              </li>
              <li class="breadcrumb-item">
                <a href="/services/insurance-bpo-outsourcing">Insurance</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Claim Management
              </li>
            </ol>
          </nav>
          <h1 class="card-text font-34-19 text-primary pt-3">
            Insurance Claims Management Services
          </h1>
          <p class="card-text font-20-16 pt-3">
            Ensure 100% customer retention and 0% attrition with our insurance
            claims management services. From initial reporting and documentation
            to final settlement, we expertly manage all stages of claims
            management. Let us take care of your administrative demands while
            you foster stronger relationships.
          </p>
          <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
            <h3 class="text-primary font-24-16 clickable-heading">
              Boost retention now
              <div class="headingCTA">
                <button class="blueCTA"></button>
              </div>
            </h3>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Mobile View -->
<div class="container text-dark slide d-block d-lg-none">
  <div class="gradientBannerMob d-flex justify-content-center">
    <img
      alt="FBSPL Claim Management Services"
      appCdnUrl="assets/Sub-service-Pages/mobi vew banner copy 1.png"
      class="card-img-mob"
    />
    <div class="card-img-overlay overlay-text-Mob bannerTextMob">
      <div data-aos="fade-up">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="/">Home</a></li>
            <li class="breadcrumb-item">
              <a href="/bpm-and-bpo-services">Services</a>
            </li>
            <li class="breadcrumb-item">
              <a href="/services/insurance-bpo-outsourcing">Insurance</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Claim Management
            </li>
          </ol>
        </nav>
        <h1 class="card-text font-34-19 text-primary">
          Insurance Claims Management Services
        </h1>
        <p class="card-text font-20-16">
          Ensure 100% customer retention and 0% attrition with our insurance
          claims management services. From initial reporting and documentation
          to final settlement, we expertly manage all stages of claims
          management. Let us take care of your administrative demands while you
          foster stronger relationships.
        </p>
        <a href="/book-free-consultation-call" class="CTA-Zindex anchorCTA">
          <h3 class="text-primary font-24-16 clickable-heading">
            Boost retention now
            <div class="headingCTA">
              <button class="blueCTA"></button>
            </div>
          </h3>
        </a>
      </div>
    </div>
  </div>
</div>

<section
  class="container-fluid pt-5"
  id="Empower Change with Claims Management Services"
>
  <div class="container">
    <h2 class="text-primary font-48-30 py-3" data-aos="fade-up">
      Our comprehensive claims management services
    </h2>
    <div class="d-none d-lg-block">
      <div class="row">
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#ClaimsProcessing">
            <div
              class="card bordered serviceCards Policy_Issuance"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Claims Processing
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Accurate policy checking is the key to maintaining trust and
                compliance. Considering this, our policy issuance services...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
        <div class="col-md-6 col-lg-4 py-3">
          <a data-bs-toggle="modal" data-bs-target="#ClaimsSettlement">
            <div
              class="card bordered serviceCards Policy-Checking"
              data-aos="zoom-in"
            >
              <span class="CTA-Zindex anchorCTA mb-3 py-2 px-3 bg-primary">
                <h3 class="text-white serviceHeading my-auto clickable-heading">
                  Claims Settlement
                  <img
                    appCdnUrl="assets/Icon/white-vector.svg"
                    class="whiteCTABtn"
                  />
                </h3>
              </span>
              <p class="text-black font-20-16 ms-2">
                Our policy checking services ensure that each policy meets
                compliance and accuracy standards before issuance...
                <br />
                <span class="text-primary cursor-pointer">Read more</span>
              </p>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Mobile View -->
  <div class="container p-3 d-block d-lg-none">
    <div class="mb-2">
      <div class="accordion" id="ServicePage">
        <div class="mobileService" data-aos="zoom-in">
          <div
            data-bs-toggle="modal"
            data-bs-target="#ClaimsProcessing"
            class="headingCTA CTA-Zindex d-flex p-3"
          >
            <h3 class="CTAs text-black fs-24 me-3">Claims Processing</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
        <div class="mobileService mt-4" data-aos="zoom-in">
          <div
            class="headingCTA CTA-Zindex d-flex p-3"
            data-bs-toggle="modal"
            data-bs-target="#ClaimsSettlement"
          >
            <h3 class="CTAs text-black fs-24 me-3">Claims Settlement</h3>
            <img class="service_btn" appCdnUrl="assets/Icon/Accord-close.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div
  class="modal fade"
  id="ClaimsProcessing"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="ClaimsProcessingLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Claims Processing</h3>
            <p class="text-dark font-20-16">
              Speed and accuracy make all the difference in claims processing.
              Our claims processing services are designed to simplify every
              step, ensuring quick turnaround times without compromising
              precision. From initial intake to final resolution, our expert
              insurance VA team is dedicated to reducing delays, improving
              transparency, and delivering a smooth experience. Outsource claims
              management and experience complete accuracy, faster processing
              times, and cost reduction.
            </p>
            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Entering the claims data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Processing claims initiation
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Determining claims eligibility
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Analyzing claims data
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Processing First notice of Loss (FNOL)
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/insurance-claim-management/Claims Processing_.png"
              alt="FBSPL Claims Processing Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="ClaimsSettlement"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="ClaimsSettlementLabel"
  aria-hidden="true"
>
  <div class="modal-dialog service-dialog">
    <div class="modal-content">
      <div class="modal-header policy-header">
        <img
          data-bs-dismiss="modal"
          aria-label="Close"
          appCdnUrl="assets/Icon/Accord-open.svg"
          class="close_btn"
        />
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="font-48-30 text-dark">Policy Checking</h3>
            <p class="text-dark font-20-16">
              The right business approach can turn a complex process into a
              seamless customer experience. Hence, our insurance claims
              settlement approach is designed to handle each claim with
              attention – from swift initial validation to efficient resolution.
              We use advanced insurance tools and proactive status updates to
              eliminate unnecessary delays while keeping your clients updated.
              Experience seamless claim settlement while choosing our insurance
              process outsourcing services. Our experts will help you strengthen
              client trust and boost your agency’s reputation.
            </p>

            <ul class="ul-policy">
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Reporting the claims
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img
                  class="arrow-img"
                  appCdnUrl="assets/Icon/Arrow-blue.svg"
                />Data collection and claims registration
              </li>
              <li class="list-item pt-2 text-dark font-20-16">
                <img class="arrow-img" appCdnUrl="assets/Icon/Arrow-blue.svg" />
                Investigating and validating claims - Follow up with carrier to
                check the status of claim
              </li>
            </ul>
          </div>
          <div class="d-none d-lg-flex col-lg-6 justify-content-end">
            <img
              class="img-fluid bordered"
              appCdnUrl="assets/insurance-claim-management/Claims Settlement_.png"
              alt="FBSPL Claims Settlement Services"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-ourAssets></app-ourAssets>

<section class="container py-5">
  <h2 class="text-primary font-48-30 mb-4" data-aos="fade-up">
    Tech-driven insurance claims management solutions
  </h2>
  <div class="d-none d-xl-flex">
    <div class="row g-5">
      <div class="col-2" data-aos="fade-right" data-aos-delay="0">
        <img
          class="img-fluid"
          alt="Insurance tool Applied System  "
          appCdnUrl="assets/Tools/applied.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="100">
        <img
          class="img-fluid"
          alt="Insurance tool Hawksoft   "
          appCdnUrl="assets/Tools/hawksoft.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="200">
        <img
          class="img-fluid"
          alt="Insurance tool PL Rating "
          appCdnUrl="assets/Tools/pl rating.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="300">
        <img
          class="img-fluid"
          alt="Insurance tool QQCatalyst "
          appCdnUrl="assets/Tools/catalyst.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="400">
        <img
          class="img-fluid"
          alt="Insurance tool AMS 360 "
          appCdnUrl="assets/Tools/ams360.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="500">
        <img
          class="img-fluid"
          alt="Insurance tool EZLynx "
          appCdnUrl="assets/Tools/ezlynx.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="600">
        <img
          class="img-fluid"
          alt="Insurance tool CSR24"
          appCdnUrl="assets/Tools/csr27.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="700">
        <img
          class="img-fluid"
          alt="Insurance tool NowCerts"
          appCdnUrl="assets/Tools/nowcerts.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img
          alt="Insurance tool Power Broker "
          class="img-fluid"
          appCdnUrl="assets/Tools/powerbroker.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="800">
        <img
          alt="Insurance tool AgencyMatrix "
          class="img-fluid"
          appCdnUrl="assets/Tools/agencymatrix.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="900">
        <img
          alt="Insurance tool employeeNavigator "
          class="img-fluid"
          appCdnUrl="assets/Tools/employeeNavigator.svg"
        />
      </div>
      <div class="col-2" data-aos="fade-right" data-aos-delay="1000">
        <img
          class="img-fluid"
          alt=" Insurance tool indio "
          appCdnUrl="assets/Tools/indio.svg"
        />
      </div>
    </div>
  </div>
  <div class="d-flex d-xl-none">
    <div class="trusted-slider-logo">
      <div class="trusted-slide-track">
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool Applied System  "
            appCdnUrl="assets/Tools/applied.svg"
            data-aos="fade-right"
            data-aos-delay="0"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="100"
            class="img-fluid"
            alt="Insurance tool Hawksoft  "
            appCdnUrl="assets/Tools/hawksoft.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="200"
            class="img-fluid"
            alt="Insurance tool PL Rating "
            appCdnUrl="assets/Tools/pl rating.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool QQCatalyst "
            data-aos="fade-right"
            data-aos-delay="300"
            appCdnUrl="assets/Tools/catalyst.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool AMS 360 "
            appCdnUrl="assets/Tools/ams360.svg"
            data-aos="fade-right"
            data-aos-delay="400"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool EZLynx "
            appCdnUrl="assets/Tools/ezlynx.svg"
            data-aos="fade-right"
            data-aos-delay="500"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool CSR24  "
            appCdnUrl="assets/Tools/csr27.svg"
            data-aos="fade-right"
            data-aos-delay="600"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool NowCerts "
            appCdnUrl="assets/Tools/nowcerts.svg"
            data-aos="fade-right"
            data-aos-delay="700"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool Power Broker"
            data-aos="fade-right"
            data-aos-delay="800"
            appCdnUrl="assets/Tools/powerbroker.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="900"
            class="img-fluid"
            alt="Insurance tool AgencyMatrix "
            appCdnUrl="assets/Tools/agencymatrix.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="1000"
            class="img-fluid"
            aria-multiline=" Insurance tool employeeNavigator "
            appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            aria-multiline=" Insurance tool indio "
            data-aos="fade-right"
            data-aos-delay="1100"
            appCdnUrl="assets/Tools/indio.svg"
            alt="Indio"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool Applied System"
            appCdnUrl="assets/Tools/applied.svg"
            data-aos="fade-right"
            data-aos-delay="0"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="100"
            class="img-fluid"
            alt="Insurance tool Hawksoft   "
            appCdnUrl="assets/Tools/hawksoft.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="200"
            class="img-fluid"
            alt="Insurance tool PL Rating "
            appCdnUrl="assets/Tools/pl rating.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool QQCatalyst "
            data-aos="fade-right"
            data-aos-delay="300"
            appCdnUrl="assets/Tools/catalyst.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool AMS 360 "
            appCdnUrl="assets/Tools/ams360.svg"
            data-aos="fade-right"
            data-aos-delay="400"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool EZLynx "
            appCdnUrl="assets/Tools/ezlynx.svg"
            data-aos="fade-right"
            data-aos-delay="500"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt="Insurance tool CSR24  "
            appCdnUrl="assets/Tools/csr27.svg"
            data-aos="fade-right"
            data-aos-delay="600"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool NowCerts  "
            appCdnUrl="assets/Tools/nowcerts.svg"
            data-aos="fade-right"
            data-aos-delay="700"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            data-aos="fade-right"
            data-aos-delay="800"
            appCdnUrl="assets/Tools/powerbroker.svg"
            alt="Insurance tool Power Broker "
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="900"
            class="img-fluid"
            alt="Insurance tool AgencyMatrix "
            appCdnUrl="assets/Tools/agencymatrix.svg"
          />
        </div>
        <div class="trust-slide">
          <img
            data-aos="fade-right"
            data-aos-delay="1000"
            class="img-fluid"
            alt="Insurance tool employeeNavigator "
            appCdnUrl="assets/Tools/employeeNavigator.svg"
            alt="Employee Navigator"
          />
        </div>
        <div class="trust-slide">
          <img
            class="img-fluid"
            alt=" Insurance tool indio "
            data-aos="fade-right"
            data-aos-delay="1100"
            appCdnUrl="assets/Tools/indio.svg"
            alt="Indio"
          />
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Thinking -->
<ThinkingSection category="insurance"></ThinkingSection>

<main>
  <app-know-best
    [cards]="[
      {
        description:
          'I must say that our partnership with Fusion Business Solutions (FBSPL) has been truly exceptional. What sets FBSPL apart is their remarkable ability to not only grasp our ever-evolving business needs but also their genuine curiosity to understand our culture. Their flexibility and agility have been invaluable. FBSPL’s proactive approach, coupled with their rigorous processes and scalability, has significantly enhanced our customer experiences and AI operations.',
        name: 'Chin Ma',
        designation: 'Founder & President'
      },
      {
        description:
          'We reached out to Fusion in the Fall of 2022 as we were having issues recruiting a skilled and motivated workforce locally to fill back-office roles in our organization. What started as a simple documentation validation exercise quickly grew into the team supporting more strategic review and reconciliation activities. The team has shown repeatedly that they are willing to take on more and more complicated tasks, and the leadership team is quick to rally and support their employees when things fall off track.',
        name: 'James Oickle',
        designation: 'President & CEO'
      },
      {
        description:
          'Fusion Business Solutions has been a game-changer for our company. Their responsive team, seamless onboarding, and commitment to excellence have significantly reduced our stress levels, allowing us to focus on what matters most – our clients. We\'ve experienced measurable improvements in client retention and employee satisfaction since partnering with Fusion, making them the top choice for any business seeking efficient virtual assistant services.',
        name: 'Isom Rigell M. Ed.',
        designation: 'Chief People Officer'
      }
    ]"
    [videos]="[
      {
        videoUrl: 'https://player.vimeo.com/video/969702390',
        thumbnail:
          'assets/thumbnail-know-best/Client Testimonials YT 01 Chin Ma.jpg',
        alt: 'Listen to our client-claim management services '
      },
      {
        thumbnail:
          'assets/thumbnail-know-best/Client Testimonials YT 10  James Oickle.jpg',
        alt: 'Our Success Stories- claim management services'
      },
      {
        videoUrl: 'https://player.vimeo.com/video/962238538',
        thumbnail:
          'assets/thumbnail-know-best/Client Testimonials YT 07 Stacey DiSpigno copy.jpg',
        alt: 'FBSPL Client Testimonial- claim management services '
      }
    ]"
  >
  </app-know-best>
</main>

<section class="py-6">
  <div class="container">
    <h3 class="text-primary font-48-30">Frequently asked questions</h3>

    <div class="mt-3 mt-md-0">
      <div data-aos="fade-left">
        <div class="accordion" id="accordionFAQ">
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
              <button
                class="accordion-button font-28-20"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Industries"
                aria-expanded="true"
                aria-controls="Industries"
              >
                What are the benefits of insurance claims management
                outsourcing?
              </button>
            </h2>
            <div
              id="Industries"
              class="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                While availing
                <a
                  class="anchor"
                  href="/services/insurance-claim-management#Empower Change with Claims Management Services"
                  >insurance claims management services</a
                >
                from industry experts, businesses can ensure that the claims are
                processed efficiently and zero errors. Besides this, there are
                other benefits too -
                <ul>
                  <li>Reduce non-compliance risk</li>
                  <li>Insurance claims automation</li>
                  <li>Access to dedicated experts</li>
                  <li>Reduced operational cost</li>
                  <li>Focus on strategic growth</li>
                  <li>99% accuracy and 0% attrition</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Services"
                aria-expanded="false"
                aria-controls="Services"
              >
                Why outsource claims management services to FBSPL?
              </button>
            </h2>
            <div
              id="Services"
              class="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                Being a renowned claims management company, we offer a wide
                range of services ranging from claims processing to claims
                investigation. Our services are excellence are defined by our
                key differentiators mentioned below -
                <ul>
                  <li>All-in expertise</li>
                  <li>Certified company</li>
                  <li>99% work accuracy</li>
                  <li>Quick turnaround time</li>
                  <li>Tailor-made solutions</li>
                  <li>Client time-zone availability</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingThree">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#About"
                aria-expanded="false"
                aria-controls="About"
              >
                What Is the turnaround time for claims processing and
                management?
              </button>
            </h2>
            <div
              id="About"
              class="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                It takes 15 to 25 minutes to collect the information for loss
                from policyholder. Turn-around time for follow up on claim
                status vary carrier to carrier depending on their hold time.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingFour">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Resources"
                aria-expanded="false"
                aria-controls="Resources"
              >
                How do you ensure quality in claims handling?
              </button>
            </h2>
            <div
              id="Resources"
              class="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                We check the documentation and details such as Date of Loss,
                Proof of Losses and Description of Loss, and check the Adjustor
                Details and Claim# while sending out information to
                policyholder.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="headingfive">
              <button
                class="accordion-button font-28-20 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#Process"
                aria-expanded="false"
                aria-controls="Process"
              >
                What is the process of outsourcing claims management?
              </button>
            </h2>
            <div
              id="Process"
              class="accordion-collapse collapse"
              aria-labelledby="headingfive"
              data-bs-parent="#accordionFAQ"
            >
              <div class="accordion-body">
                As <a class="anchor" href="./">an outsourcing vendor</a>, we can
                assist Agencies, MGA with following activities:
                <br />
                <ul>
                  <li>
                    <b>Collect Loss Info from Policyholder:</b> Filing claims to
                    insurance carrier by collecting the loss info such as Date
                    of Loss, How Loss had occurred, and Proof of the Losses.
                  </li>

                  <li>
                    <b>Register a claim to Insurance Company:</b> We prepare the
                    Acord Loss Letter for E&S Market and for Admitted Market we
                    can directly file a claim using carrier portal.
                  </li>

                  <li>
                    <b>Process FNOL (First Notice of Loss):</b>Carrier assigns
                    Claim# along with Adjustor's information. We create a log-in
                    system and share the information with policyholders.
                  </li>

                  <li>
                    <b>Claim Support:</b> We call carriers to check the status
                    on filed claim and share the status with policyholders.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-faq></app-faq>
  </div>
</section>
